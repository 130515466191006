<template>
  <div class="admin-dashboard">
    <h1>Admin Dashboard</h1>
    <AdminDashboardContent />
  </div>
</template>

<script>
import AdminDashboardContent from '@/components/AdminDashboardContent.vue';

export default {
  name: 'AdminDashboardView',
  components: {
    AdminDashboardContent
  }
}
</script>

<style scoped>
.admin-dashboard {
  padding: 20px;
}
</style>
