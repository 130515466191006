<template>
  <div class="business-dashboard-content">
    <h1>Success Roadmap</h1>
    <div class="dashboard-layout">
      <div class="progress-section">
        <div class="progress-container">
          <div class="progress-bar">
            <div class="progress" :style="{ width: progressWidth }"></div>
          </div>
          <div class="step-indicators">
            <div v-for="(step, index) in steps" :key="index" 
                 :class="['step-indicator', { 'active': currentStep >= index, 'completed': currentStep > index }]"
                 @click="setCurrentStep(index)">
              <div class="step-number">{{ index + 1 }}</div>
              <div class="step-label">{{ step.shortTitle }}</div>
            </div>
          </div>
        </div>
        <transition name="fade" mode="out-in">
          <div class="step-content" :key="displayStep">
            <h2>{{ steps[displayStep].title }}</h2>
            <p>{{ steps[displayStep].description }}</p>
            <p class="expected-time">Expected time: {{ steps[displayStep].expectedTime }}</p>
          </div>
        </transition>
      </div>
      <transition name="fade" mode="out-in">
        <div class="dynamic-content" :key="currentStep">
          <h3>{{ dynamicContent.title }}</h3>
          <h4>{{ dynamicContent.subtitle }}</h4>
          <div class="dynamic-content-body">
            <p>{{ dynamicContent.description }}</p>
          </div>
          <div class="action-button-container" v-if="dynamicContent.action">
            <button @click="dynamicContent.action.handler" class="action-button">
              {{ dynamicContent.action.text }}
            </button>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BusinessDashboardContent',
  props: {
    assessmentData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      displayStep: this.assessmentData ? this.assessmentData.step : 0,
      steps: [
        {
          shortTitle: "Take Assessment",
          title: "Take Initial Assessment",
          description: "Complete your free initial AI readiness assessment to help us understand your current capabilities and needs. Afterwards you'll receive a customized report with basic recommendations.",
          expectedTime: "5-10 minutes"
        },
        {
          shortTitle: "Assessment Taken",
          title: "Initial Assessment Taken",
          description: "Your initial AI readiness assessment has been completed. Click 'Intial Assessment' on the left to view your results.",
          expectedTime: "N/a"
        },
        {
          shortTitle: "Discovery",
          title: "Discovery Call and Proposal Completed",
          description: "We've had our discovery call and prepared a custom proposal based on your assessment and our discussion.",
          expectedTime: "3-5 business days"
        },
        {
          shortTitle: "Plan",
          title: "Plan Chosen",
          description: "You've selected a plan that best fits your needs. We're preparing to move forward with the implementation.",
          expectedTime: "1 business day"
        },
        {
          shortTitle: "Introduction",
          title: "Introduction Call Completed",
          description: "We've had our introductory call to discuss the next steps and set expectations for the project.",
          expectedTime: "1-2 business days"
        },
        {
          shortTitle: "Stakeholders",
          title: "Stakeholders Finalized",
          description: "We've identified and confirmed all key stakeholders who will be involved in the AI implementation process.",
          expectedTime: "2-3 business days"
        },
        {
          shortTitle: "Interviews",
          title: "Stakeholder Interviews Completed",
          description: "We've conducted in-depth interviews with all stakeholders to gather detailed insights about your business processes and AI integration points.",
          expectedTime: "5-7 business days"
        },
        {
          shortTitle: "Reports",
          title: "Reports and Plan Created",
          description: "Based on all the information gathered, we've created comprehensive reports and a detailed implementation plan for your AI solution.",
          expectedTime: "7-10 business days"
        }
      ]
    }
  },
  computed: {
    currentStep() {
      return this.assessmentData && this.assessmentData.step !== undefined ? this.assessmentData.step : 0;
    },
    progressWidth() {
      const totalSteps = this.steps.length - 1;
      const progress = (this.currentStep / totalSteps) * 100;
      return `${Math.min(progress + 7.15, 100)}%`;
    },
    dynamicContent() {
      if (!this.assessmentData || this.displayStep === 0) {
        return {
          title: "Next Steps",
          subtitle: "Start Your AI Journey",
          description: "Begin by taking our comprehensive AI Readiness Assessment to help us understand your business needs and capabilities.",
          action: {
            text: "Take Assessment",
            handler: () => this.$emit('navigate', 'AssessmentContent')
          }
        };
      }

      switch(this.displayStep) {
        case 1:
          return {
            title: "Next Steps",
            subtitle: "Schedule Your Discovery Call",
            description: "Schedule a discovery call to discuss your assessment results and explore how we can help implement AI in your business.",
            action: {
              text: "Schedule Discovery Call",
              handler: () => this.$emit('navigate', 'DiscoveryCallContent')
            }
          };
        case 2:
          return {
            title: "Next Steps",
            subtitle: "Select Your Plan",
            description: "View your proposal and select a plan to continue future proofing your company. We can work with you to dive deep into a single project, department or multiple departments.",
            action: {
              text: "View Proposal",
              handler: () => this.$emit('navigate', 'ProposalContent')
            }
          };
        case 3:
          return {
            title: "Next Steps",
            subtitle: "Schedule Introduction Call",
            description: "Schedule an introduction call. On this call we'll identify stakeholders and align on goals and timelines.",
            action: {
              text: "Schedule Call",
              handler: this.scheduleDiscoveryCall
            }
          };
        case 4:
          return {
            title: "Next Steps",
            subtitle: "Add Stakeholders",
            description: "Add any stakeholders who should be included in discovery.",
            action: {
              text: "Manage Stakeholders",
              handler: () => this.$emit('navigate', 'StakeholdersContent')
            }
          };
        case 5:
          return {
            title: "Next Steps",
            subtitle: "Stakeholder Management",
            description: "Continue to add any stakeholders who should be included in discovery. We'll let you know when we're done with our interviews.",
            action: {
              text: "Manage Stakeholders",
              handler: () => this.$emit('navigate', 'StakeholdersContent')
            }
          };
        case 6:
          return {
            title: "Next Steps",
            subtitle: "Interviews in Progress",
            description: "We'll reach out when your final report is ready!",
          };
        case 7:
          return {
            title: "Next Steps",
            subtitle: "Review Reports",
            description: "Review your reports. We can work together to execute single projects or we can perform all selected projects on a retainer basis.",
            action: {
              text: "View Reports",
              handler: () => this.$emit('navigate', 'ReportsContent')
            }
          };
        default:
          return {
            title: "Next Steps",
            subtitle: "Continue Your Progress",
            description: "You're making great progress on your AI implementation journey. Keep up the momentum!"
          };
      }
    }
  },
  methods: {
    setCurrentStep(index) {
      // Only update the display step, not the database
      this.displayStep = index;
    },
    scheduleDiscoveryCall() {
      // Implement scheduling logic
      // console.log("Scheduling discovery call...");
    },
    viewProposal() {
      // Implement proposal viewing logic
      // console.log("Viewing proposal...");
    },
    getStepDescription(step) {
      const stepDescriptions = {
        0: "Take Initial Assessment",
        1: "Schedule Discovery Call",
        2: "Choose Plan",
        3: "Complete Introduction Call",
        4: "Finalize Stakeholders",
        5: "Complete Stakeholder Interviews",
        6: "Review Reports and Plan"
      };
      return stepDescriptions[step] || "Unknown step";
    }
    // Add other methods as needed
  },
  watch: {
    // Add watcher for assessmentData to update displayStep when data changes
    'assessmentData.step'(newStep) {
      if (newStep !== undefined) {
        this.displayStep = newStep;
      }
    }
  },
  created() {
    this.displayStep = (this.assessmentData && this.assessmentData.step !== undefined) 
      ? this.assessmentData.step 
      : 0;
  }
}
</script>

<style scoped>
.business-dashboard-content {
  padding: 50px;
  max-width: 1500px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 80vh; /* Use minimum height of viewport */
}

h1 {
  color: var(--accent-victory-green);
  margin-bottom: 37.5px;
  font-size: 3.125em;
}

.dashboard-layout {
  display: flex;
  gap: 50px;
}

.progress-section {
  flex: 2;
}

.progress-container {
  margin-bottom: 50px;
}

.progress-bar {
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 25px;
  position: relative;
}

.progress {
  height: 100%;
  background-color: var(--accent-victory-green);
  transition: width 0.3s ease;
  position: absolute;
  top: 0;
  left: 0;
}

.step-indicators {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.step-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
}

.step-number {
  width: 37.5px;
  height: 37.5px;
  border-radius: 50%;
  background-color: #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-bottom: 6.25px;
  font-size: 1.25em;
}

.step-label {
  font-size: 1em;
  text-align: center;
  max-width: 100px;
}

.step-indicator.active .step-number {
  background-color: var(--accent-sunny-yellow);
}

.step-indicator.completed .step-number {
  background-color: var(--accent-victory-green);
  color: white;
}

.step-content, .dynamic-content {
  background-color: white;
  padding: 37.5px;
  border-radius: 10px;
  box-shadow: 0 5px 7.5px rgba(0,0,0,0.1);
  height: 185px; /* Fixed height as per your request */
}

.dynamic-content {
  flex: 1;
  background-color: white;
  padding: 37.5px;
  border-radius: 10px;
  box-shadow: 0 5px 7.5px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  height: 365px; /* Fixed height */
}

.dynamic-content h3 {
  color: var(--accent-victory-green);
  font-size: 2.25em;
  text-align: left;
  margin: 0 0 10px 0; /* Reduced bottom margin */
}

.dynamic-content h4 {
  color: var(--main-text-color);
  font-size: 1.75em;
  text-align: left;
  margin: 0 0 25px 0;
  font-style: italic;
}

.dynamic-content-body {
  flex: 1; /* This will take up remaining space */
  display: flex;
  flex-direction: column;
}

.dynamic-content p {
  font-size: 1.25em;
  line-height: 1.6;
  margin: 0; /* Remove default margins */
}

.dynamic-content ul {
  list-style-type: disc;
  margin-left: 25px;
  margin-bottom: 18.75px;
}

.dynamic-content li {
  font-size: 1.25em;
  margin-bottom: 6.25px;
}

.action-button-container {
  margin-top: 30px; /* Fixed space above button */
  text-align: left;
}

.action-button {
  background-color: var(--accent-victory-green);
  color: white;
  border: none;
  padding: 12.5px 25px;
  border-radius: 6.25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1.25em;
}

.action-button:hover {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

/* Add these new styles for the fade transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
