<template>
  <section class="how">
    <div class="left-column">
      <div class="content">
        <h2>How It Works</h2>
        <ol>
          <li @mouseover="activeStep = 1" @mouseout="activeStep = 0" @click="navigateToAssessment">Take the Assessment</li>
          <li @mouseover="activeStep = 2" @mouseout="activeStep = 0" @click="navigateToSchedule">Schedule a Discovery Call</li>
          <li @mouseover="activeStep = 3" @mouseout="activeStep = 0" @click="navigateToPricing">Choose a Scope</li>
        </ol>
        <div class="button-container">
          <button 
            class="action-button"
            @click="navigateToAssessment"
          >
            Take assessment
          </button>
          <button class="action-button" @click="navigateToSchedule">Schedule call</button>
        </div>
      </div>
    </div>
    <div class="right-column">
      <div class="top-row">
        <div class="step-box" :class="{ active: activeStep === 1 }" @click="navigateToAssessment">
          <div class="step-number">1</div>
          <h3>Take Assessment</h3>
          <p>Complete our comprehensive AI readiness assessment to understand your business's current position and potential for AI integration.</p>
        </div>
        <div class="vertical-divider"></div>
        <div class="step-box" :class="{ active: activeStep === 2 }" @click="navigateToSchedule">
          <div class="step-number">2</div>
          <h3>Book a Call</h3>
          <p>Schedule a discovery call with our experts to discuss your assessment results and explore tailored AI solutions for your business.</p>
        </div>
      </div>
      <div class="bottom-row">
        <div class="step-box" :class="{ active: activeStep === 3 }" @click="navigateToPricing">
          <div class="step-number">3</div>
          <h3>Choose a Scope</h3>
          <p>Decide on the scope of our collaboration. We can work with you on a single project, provide recommendations for an entire department, or develop a comprehensive AI strategy for your full organization.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HowSection',
  data() {
    return {
      activeStep: 0
    }
  },
  methods: {
    navigateToAssessment() {
      this.$router.push('/assessment')
    },
    navigateToSchedule() {
      this.$router.push('/schedule')
    },
    navigateToPricing() {
      this.$router.push('/pricing')
    }
  }
}
</script>

<style scoped>
.how {
  scroll-snap-align: start;
  height: 100vh;
  display: flex;
  background-color: var(--primary-background-cream);
  padding: 2rem;
}

.left-column {
  height: 100%;
}

.right-column {
  height: 80%;
  padding-top: 8%;
}

.left-column {
  flex: 0 0 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
}

.content {
  max-width: 100%;
}

.right-column {
  flex: 0 0 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.top-row, .bottom-row {
  display: flex;
}

.top-row {
  flex: 3;
  border-bottom: 1px solid var(--accent-victory-green);
}

.bottom-row {
  flex: 2;
}

.step-box {
  flex: 1;
  padding: 2rem 1.5rem;
  position: relative;
  transition: background-color 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: pointer;
}

.step-box.active,
.step-box:hover {
  background-color: rgba(255, 199, 44, 0.1);
}

.step-number {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  font-size: 1rem;
  color: var(--primary-background-cream);
  font-weight: bold;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--accent-victory-green);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

h2 {
  font-size: 2.5rem;
  color: var(--accent-victory-green);
  margin-bottom: 2rem;
}

h3 {
  font-size: 1.3rem;
  color: var(--accent-victory-green);
  margin-bottom: 0.5rem;
  margin-top: 0;
}

p {
  font-size: 1rem;
  line-height: 1.4;
  color: var(--main-text-color);
  margin: 0;
}

ol {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 2rem;
  counter-reset: step-counter;
}

li {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  position: relative;
  padding-left: 2.5rem;
  cursor: pointer;
  transition: color 0.3s ease;
}

li::before {
  content: counter(step-counter);
  counter-increment: step-counter;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1.8rem;
  height: 1.8rem;
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1rem;
}

li:hover {
  color: var(--accent-victory-green);
}

.button-container {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}

.action-button {
  display: inline-block;
  text-decoration: none;
  text-align: center;
  border: none;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  text-transform: none;
  letter-spacing: normal;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: inherit;
}

.action-button:first-child {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

.action-button:first-child:hover {
  background-color: var(--accent-victory-green);
  color: var(--primary-background-cream);
}

.action-button:last-child {
  background-color: var(--accent-victory-green);
  color: var(--primary-background-cream);
}

.action-button:last-child:hover {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

.action-button:hover {
  transform: scale(1.05);
}

.vertical-divider {
  width: 1px;
  background-color: var(--accent-victory-green);
  margin: 0 1rem;
}

@media (max-width: 768px) {
  .how {
    padding: 2rem 1.5rem;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    justify-content: center;
  }

  .left-column {
    flex: none;
    width: 100%;
    padding: 0;
    height: auto;
  }

  .right-column {
    display: none;
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h2 {
    font-size: 1.8rem;
    margin-bottom: 2rem;
    text-align: center;
  }

  ol {
    margin-bottom: 2rem;
    width: 100%;
    max-width: 280px;
  }

  li {
    font-size: 1rem;
    margin-bottom: 1.2rem;
    padding-left: 2.5rem;
    color: var(--main-text-color);
  }

  li::before {
    width: 1.6rem;
    height: 1.6rem;
    font-size: 0.9rem;
    background-color: var(--accent-sunny-yellow);
  }

  .button-container {
    flex-direction: column;
    gap: 0.8rem;
    margin-top: 1rem;
    width: 100%;
    max-width: 280px;
  }

  .action-button {
    width: 100%;
    padding: 0.8rem;
    font-size: 1rem;
    border-radius: 2rem;
  }

  .action-button:first-child {
    background-color: var(--accent-sunny-yellow);
  }

  .action-button:last-child {
    background-color: var(--accent-victory-green);
  }
}
</style>
