<template>
  <div class="admin-dashboard-content">
    <div class="dashboard-header">
      <h2>Leads</h2>
      <button class="logout-button" @click="handleLogout">
        <i class="fas fa-sign-out-alt"></i> Logout
      </button>
    </div>
    <div v-if="loading">Loading...</div>
    <div v-else-if="error">Error: {{ error }}</div>
    <table v-else>
      <thead>
        <tr>
          <th>Company Name</th>
          <th>Email</th>
          <th>Step</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="lead in leads" :key="lead.id" @click="showLeadDetails(lead)">
          <td>{{ lead.company_name }}</td>
          <td>{{ lead.email }}</td>
          <td>{{ getStepDescription(lead.step) }}</td>
          <td>
            <span 
              class="status-badge"
              :class="{ 'status-active': lead.active }"
            >
              {{ lead.active ? 'Active' : 'Inactive' }}
            </span>
          </td>
          <td>
            <button @click.stop="showLeadDetails(lead)">View Details</button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Modal -->
    <div v-if="selectedLead" class="modal" @click="closeModal">
      <div class="modal-content" @click.stop>
        <span class="close" @click="closeModal">&times;</span>
        <div class="modal-main">
          <!-- Company Header -->
          <div class="company-header">
            <h3>{{ selectedLead.company_name }}</h3>
            <button 
              @click="toggleActive" 
              class="active-status" 
              :class="{ active: selectedLead.active }"
            >
              {{ selectedLead.active ? 'Active' : 'Inactive' }}
            </button>
          </div>

          <!-- Next Steps Section -->
          <div class="next-steps-section">
            <h4>Next Steps</h4>
            <p>{{ getNextSteps(selectedLead.step) }}</p>
          </div>

          <!-- Replace the collapsible sections with simple navigation buttons -->
          <div class="nav-sections">
            <div class="nav-row">
              <button @click="setActiveContent('basic')" :class="{ active: activeContent === 'basic' }">Basic Info</button>
              <button @click="setActiveContent('contacts')" :class="{ active: activeContent === 'contacts' }">Contacts</button>
            </div>
            <div class="nav-row">
              <button @click="setActiveContent('email')" :class="{ active: activeContent === 'email' }">Email</button>
              <button @click="setActiveContent('discovery')" :class="{ active: activeContent === 'discovery' }">Discovery</button>
            </div>
            <div class="nav-row">
              <button @click="setActiveContent('proposal')" :class="{ active: activeContent === 'proposal' }">Proposal</button>
              <button @click="setActiveContent('transcript')" :class="{ active: activeContent === 'transcript' }">Transcript</button>
            </div>
            <div class="nav-row">
              <button @click="handleAssessment" :class="{ active: activeContent === 'assessment' }">Assessment</button>
              <button class="invisible"></button> <!-- Placeholder for grid alignment -->
            </div>
          </div>

          <!-- Progress Section -->
          <div class="step-progress">
            <div class="progress-header">
              <h4>Progress</h4>
              <div class="step-description">
                <p>{{ getStepDescription(selectedLead.step) }}</p>
              </div>
            </div>
            <div class="progress-container">
              <div class="progress-bar">
                <div 
                  class="progress-fill" 
                  :style="{ width: `${(selectedLead.step / 6) * 100}%` }"
                ></div>
                <div class="step-markers">
                  <div 
                    v-for="step in 7" 
                    :key="step-1"
                    class="step-marker"
                    :class="{ active: selectedLead.step >= step-1 }"
                  ></div>
                </div>
              </div>
              <div class="step-controls">
                <button 
                  @click="decrementStep" 
                  :disabled="selectedLead.step === 0"
                  class="step-button"
                >
                  ←
                </button>
                <button 
                  @click="incrementStep"
                  :disabled="selectedLead.step === 6"
                  class="step-button"
                >
                  →
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-expanded-content">
          <div v-if="activeContent === 'discovery'" class="discovery-content">
            <h4>Discovery Answers</h4>
            <div v-for="(value, key) in selectedLead.discovery_answers" :key="key" class="discovery-item">
              <h5>{{ formatDiscoveryKey(key) }}</h5>
              <div v-if="editingField === `discovery.${key}`">
                <textarea
                  v-model="editingValue"
                  @blur="saveEdit('discovery', key)"
                  @keydown.esc="cancelEdit"
                  ref="editTextarea"
                  class="edit-textarea"
                ></textarea>
              </div>
              <p v-else @click="startEdit('discovery', key, value)">{{ value }}</p>
            </div>
          </div>
          <div v-if="activeContent === 'proposal'" class="proposal-content">
            <div class="proposal-header">
              <h4>Proposal Answers</h4>
              <div class="proposal-buttons">
                <button 
                  v-if="selectedLead.proposal_url" 
                  class="view-proposal-button" 
                  @click="viewProposal"
                >
                  View Proposal
                </button>
                <button 
                  v-if="selectedLead.proposal_url && !selectedLead.proposal_email_sent" 
                  class="send-proposal-button" 
                  @click="sendProposal"
                >
                  Send Proposal Email
                </button>
                <button class="generate-proposal-button" @click="generateProposal">
                  Generate Proposal
                </button>
              </div>
            </div>
            <div v-for="(value, key) in selectedLead.proposal_answers" :key="key" class="proposal-item">
              <h5>{{ formatProposalKey(key) }}</h5>
              <div v-if="editingField === `proposal.${key}`">
                <textarea
                  v-model="editingValue"
                  @blur="saveEdit('proposal', key)"
                  @keydown.esc="cancelEdit"
                  ref="editTextarea"
                  class="edit-textarea"
                ></textarea>
              </div>
              <p v-else @click="startEdit('proposal', key, value)">{{ value }}</p>
            </div>
          </div>
          <div v-if="activeContent === 'transcript'" class="transcript-content">
            <h4>Transcript</h4>
            <!-- Show transcript if it exists -->
            <div v-if="selectedLead.transcript" class="transcript-body">
              <div v-for="(line, index) in formattedTranscript" :key="index" class="transcript-line">
                <span class="transcript-timestamp">{{ line.timestamp }}</span>
                <div class="transcript-message">
                  <span class="transcript-speaker">{{ line.speaker }}</span>
                  <span class="transcript-text">{{ line.text }}</span>
                </div>
              </div>
            </div>
            <!-- Show upload option if no transcript exists -->
            <div v-else class="transcript-upload">
              <p>No transcript available. Drop or paste a transcript below:</p>
              <textarea
                v-model="transcriptText"
                class="transcript-input"
                placeholder="Paste your transcript here..."
                @paste="handleTranscriptPaste"
              ></textarea>
              <div class="transcript-actions">
                <button 
                  class="upload-button" 
                  @click="uploadTranscript"
                  :disabled="!transcriptText"
                >
                  Upload Transcript
                </button>
              </div>
            </div>
          </div>
          <div v-if="activeContent === 'assessment'" class="assessment-content">
            <h4>Assessment Results</h4>
            <div v-if="assessmentLoading">Loading assessment data...</div>
            <div v-else-if="assessmentError">{{ assessmentError }}</div>
            <div v-else-if="assessmentData" class="assessment-details">
              <div class="assessment-header">
                <div class="assessment-score">
                  <h2>{{ selectedLead.grade }}</h2>
                  <p>Grade</p>
                </div>
                <div class="assessment-score">
                  <h2>{{ selectedLead.score }}%</h2>
                  <p>Score</p>
                </div>
              </div>
              <div class="assessment-dates">
                <div class="date-item">
                  <p><strong>Created At:</strong></p>
                  <p>{{ formatDate(assessmentData.created_at) }}</p>
                </div>
                <div class="date-item">
                  <p><strong>Completed At:</strong></p>
                  <p>{{ formatDate(selectedLead.completed_at) }}</p>
                </div>
              </div>
              <div class="assessment-results">
                <h5>Current Results:</h5>
                <ul>
                  <li v-for="(value, key) in assessmentData.current_results" :key="key">
                    <span class="question-number">Q{{ key }}:</span>
                    <span class="question-answer">{{ value }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <!-- New content sections -->
          <div v-if="activeContent === 'basic'" class="basic-content">
            <h4>Basic Information</h4>
            <div class="info-card">
              <div class="info-row">
                <label>Email:</label>
                <span>{{ selectedLead.email }}</span>
              </div>
              <div class="info-row">
                <label>Assessment Completed:</label>
                <span>{{ formatDate(selectedLead.completed_at) }}</span>
              </div>
              <div class="info-row">
                <label>Status:</label>
                <span :class="{ 'status-active': selectedLead.active }">
                  {{ selectedLead.active ? 'Active' : 'Inactive' }}
                </span>
              </div>
            </div>
          </div>

          <div v-if="activeContent === 'contacts'" class="contacts-content">
            <h4>Contacts</h4>
            <div class="info-card">
              <div class="contacts-header">
                <div class="add-contact">
                  <input 
                    v-model="newContactEmail"
                    type="email"
                    placeholder="Enter email address"
                    @keyup.enter="addNewContact"
                  />
                  <button @click="addNewContact" class="add-button">Add Contact</button>
                </div>
              </div>
              <div class="contacts-list">
                <div v-for="(contact, index) in selectedLead.participants" :key="index" class="contact-item">
                  <span class="contact-email">{{ contact }}</span>
                  <button @click="removeExistingContact(contact)" class="remove-button">
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div v-if="activeContent === 'email'" class="email-content">
            <h4>Email Schedule</h4>
            <div class="info-card">
              <div class="info-row">
                <label>Next Email:</label>
                <span>{{ formatDate(selectedLead.email_date) }}</span>
              </div>
              <!-- Add more email-related information here -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchLeads, fetchAssessment, generateProposal, updateAnswers, updateLeadStep, addContact, removeContact, toggleLeadActive, uploadTranscript, sendProposalEmail } from '@/services/api';
import { mapActions } from 'vuex';

export default {
  name: 'AdminDashboardContent',
  data() {
    return {
      leads: [],
      loading: true,
      error: null,
      selectedLead: null,
      activeContent: null,
      proposalGenerated: false,
      assessmentData: null,
      assessmentLoading: false,
      assessmentError: null,
      proposalUrl: null,
      editingField: null,
      editingValue: '',
      activeSection: 'basic',
      newContactEmail: '',
      transcriptText: '',
    };
  },
  methods: {
    ...mapActions(['logout']),
    async fetchLeads() {
      try {
        this.loading = true;
        this.leads = await fetchLeads();
        // console.log('Fetched leads:', this.leads);
      } catch (error) {
        console.error('Error fetching leads:', error);
        this.error = 'Failed to fetch leads';
      } finally {
        this.loading = false;
      }
    },
    getStepDescription(step) {
      const stepDescriptions = {
        0: 'Initial Assessment Taken',
        1: 'Discovery Call and Proposal Completed',
        2: 'Plan Chosen',
        3: 'Introduction Call Completed',
        4: 'Stakeholders Finalized',
        5: 'Stakeholder Interviews Completed',
        6: 'Reports and Plan Created'
      };
      return stepDescriptions[step] || 'Unknown Step';
    },
    showLeadDetails(lead) {
      // Create a reactive copy of the lead to ensure all properties are reactive
      this.selectedLead = JSON.parse(JSON.stringify(lead));
      this.activeContent = 'discovery';
      
      // console.log('Opening lead details:', this.selectedLead);  
      // console.log('Existing proposal URL:', this.selectedLead?.proposal_url);
      
      // Check for proposal URL
      if (this.selectedLead && this.selectedLead.proposal_url) {
        // console.log('Found existing proposal URL:', this.selectedLead.proposal_url);
        this.proposalUrl = this.selectedLead.proposal_url;
        this.proposalGenerated = true;
      } else {
        // console.log('No existing proposal URL found');
        this.proposalUrl = null;
        this.proposalGenerated = false;
      }
    },
    formatDate(dateString) {
      if (!dateString) return 'N/A';
      const date = new Date(dateString);
      return date.toLocaleString();
    },
    async handleAssessment() {
      if (this.activeContent === 'assessment') {
        return;
      }

      this.activeContent = 'assessment';
      
      if (this.selectedLead && this.selectedLead.assessment_id) {
        try {
          this.assessmentLoading = true;
          this.assessmentError = null;
          
          // Fetch assessment using assessment_id from the lead
          this.assessmentData = await fetchAssessment(this.selectedLead.assessment_id);
          
          if (!this.assessmentData) {
            this.assessmentError = "Assessment data not found";
          }
          
        } catch (error) {
          console.error('Error fetching assessment:', error);
          this.assessmentError = "Failed to fetch assessment data";
        } finally {
          this.assessmentLoading = false;
        }
      } else {
        this.assessmentError = "No assessment ID available";
      }
    },
    setActiveContent(content) {
      if (content === this.activeContent) {
        return;
      }

      // console.log('Setting active content to:', content);
      // console.log('Current proposal URL:', this.proposalUrl);
      // console.log('Selected lead proposal URL:', this.selectedLead?.proposal_url);
      // console.log('Full selected lead:', this.selectedLead);

      this.activeContent = content;
      if (content === 'assessment') {
        this.handleAssessment();
      }
    },
    closeModal() {
      this.selectedLead = null;
      this.activeContent = null;
      // Reset proposal states when closing modal
      this.proposalUrl = null;
      this.proposalGenerated = false;
    },
    formatProposalKey(key) {
      return key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    },
    async generateProposal() {
      try {
        // console.log('Generate Proposal clicked');
        const response = await generateProposal(this.selectedLead.id);
        // console.log('Proposal generation response:', response);
        this.proposalGenerated = true;
        this.proposalUrl = response.proposal_url;
        
        // Update the selectedLead with the new proposal URL
        if (this.selectedLead) {
          this.selectedLead.proposal_url = response.proposal_url;
        }
      } catch (error) {
        console.error('Error generating proposal:', error);
        // You might want to add error handling UI here
      }
    },
    viewProposal() {
      const url = this.proposalUrl || this.selectedLead.proposal_url;
      // console.log('View proposal clicked. URL:', url);  
      if (url) {
        window.open(url, '_blank');
      }
    },
    formatDiscoveryKey(key) {
      return key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    },
    startEdit(type, field, value) {
      this.editingField = `${type}.${field}`;
      this.editingValue = value;
      this.$nextTick(() => {
        // Handle the case where editTextarea is an array
        const textarea = Array.isArray(this.$refs.editTextarea) 
          ? this.$refs.editTextarea[0] 
          : this.$refs.editTextarea;
          
        if (textarea) {
          textarea.focus();
        }
      });
    },
    async saveEdit(type, field) {
      try {
        if (this.editingValue === this.selectedLead[`${type}_answers`][field]) {
          this.cancelEdit();
          return;
        }

        await updateAnswers(
          this.selectedLead.id,
          type,
          field,
          this.editingValue
        );

        // Update local state
        this.selectedLead[`${type}_answers`][field] = this.editingValue;
        this.editingField = null;
        this.editingValue = '';
      } catch (error) {
        console.error('Error saving edit:', error);
        // You might want to show an error message to the user
      }
    },
    cancelEdit() {
      this.editingField = null;
      this.editingValue = '';
    },
    async incrementStep() {
      if (this.selectedLead.step < 6) {
        try {
          const newStep = this.selectedLead.step + 1;
          await this.updateLeadStep(newStep);
        } catch (error) {
          console.error('Error incrementing step:', error);
        }
      }
    },
    async decrementStep() {
      if (this.selectedLead.step > 0) {
        try {
          const newStep = this.selectedLead.step - 1;
          await this.updateLeadStep(newStep);
        } catch (error) {
          console.error('Error decrementing step:', error);
        }
      }
    },
    async updateLeadStep(newStep) {
      try {
        // Use the imported updateLeadStep function instead of api directly
        await updateLeadStep(this.selectedLead.id, newStep);

        // Update local state
        this.selectedLead.step = newStep;
        
        // Update the lead in the leads array
        const leadIndex = this.leads.findIndex(lead => lead.id === this.selectedLead.id);
        if (leadIndex !== -1) {
          this.leads[leadIndex].step = newStep;
        }
      } catch (error) {
        console.error('Error updating lead step:', error);
        throw error;
      }
    },
    toggleSection(section) {
      this.activeSection = this.activeSection === section ? null : section;
    },
    getNextSteps(step) {
      const nextSteps = {
        0: "Schedule a discovery call with the client to understand their needs and requirements.",
        1: "Follow up with the client to discuss the proposal and answer any questions.",
        2: "Schedule an introduction call to kick off the project and meet the team.",
        3: "Work with the client to identify and finalize key stakeholders for the project.",
        4: "Begin scheduling stakeholder interviews to gather detailed requirements.",
        5: "Compile interview findings and prepare final reports and implementation plan.",
        6: "Project implementation complete. Schedule follow-up for feedback and next steps."
      };
      return nextSteps[step] || "Unknown step";
    },
    async addNewContact() {
      if (!this.newContactEmail) return;
      
      try {
        const response = await addContact(this.selectedLead.id, this.newContactEmail);
        this.selectedLead.participants = response.participants;
        this.newContactEmail = ''; // Clear the input
      } catch (error) {
        console.error('Error adding contact:', error);
        // You might want to show an error message to the user
      }
    },
    async removeExistingContact(email) {
      try {
        const response = await removeContact(this.selectedLead.id, email);
        this.selectedLead.participants = response.participants;
      } catch (error) {
        console.error('Error removing contact:', error);
        // You might want to show an error message to the user
      }
    },
    async toggleActive() {
      try {
        const newStatus = !this.selectedLead.active;
        const response = await toggleLeadActive(this.selectedLead.id, newStatus);
        
        // Update local state
        this.selectedLead.active = newStatus;
        
        // Update the lead in the leads array
        const leadIndex = this.leads.findIndex(lead => lead.id === this.selectedLead.id);
        if (leadIndex !== -1) {
          this.leads[leadIndex].active = newStatus;
        }
      } catch (error) {
        console.error('Error toggling active status:', error);
        // You might want to show an error message to the user
      }
    },
    handleTranscriptPaste(event) {
      // Optional: You can add any preprocessing of pasted content here
      this.transcriptText = event.clipboardData?.getData('text') || '';
    },
    async uploadTranscript() {
      try {
        // Pass just the lead's email - the API service will add hello@victoryai.io
        const data = await uploadTranscript(this.transcriptText, this.selectedLead.email);
        // console.log('Transcription job created:', data);

        // Show success message
        alert('Transcript uploaded successfully. Processing has begun.');
        
        // Clear the input
        this.transcriptText = '';
        
        // Note: The transcript will be processed asynchronously, so we may need to 
        // poll for updates or refresh the data after a delay
        setTimeout(async () => {
          await this.fetchLeads();
        }, 5000); // Wait 5 seconds before refreshing

      } catch (error) {
        console.error('Error uploading transcript:', error);
        alert('Failed to upload transcript. Please try again.');
      }
    },
    async sendProposal() {
      try {
        // First, refresh the lead data to ensure we have the latest
        await this.fetchLeads();
        const updatedLead = this.leads.find(lead => lead.id === this.selectedLead.id);
        
        if (!updatedLead?.proposal_url) {
          alert('No proposal URL found. Please generate proposal first.');
          return;
        }
        
        // Send the proposal email
        await sendProposalEmail(this.selectedLead.id);
        
        // Update local state to show email was sent
        this.selectedLead.proposal_email_sent = true;
        
        // Show success message
        alert('Proposal email sent successfully!');
        
        // Refresh leads again to get latest state
        await this.fetchLeads();
      } catch (error) {
        console.error('Error sending proposal email:', error);
        alert('Failed to send proposal email. Please try again.');
      }
    },
    async handleLogout() {
      try {
        await this.logout();
        this.$router.push('/login');
      } catch (error) {
        console.error('Logout error:', error);
      }
    }
  },
  computed: {
    formattedTranscript() {
      if (!this.selectedLead || !this.selectedLead.transcript) return [];
      
      // Split the transcript into lines
      const lines = this.selectedLead.transcript.split('\n');
      
      // Parse each line into a structured format
      return lines.map(line => {
        const match = line.match(/^(\d{2}:\d{2}:\d{2}) (.+?): (.+)$/);
        if (match) {
          return {
            timestamp: match[1],
            speaker: match[2],
            text: match[3]
          };
        } else {
          // If the line doesn't match the expected format, return it as-is
          return {
            timestamp: '',
            speaker: '',
            text: line
          };
        }
      });
    }
  },
  mounted() {
    // Only fetch if we haven't already
    if (this.leads.length === 0) {
      this.fetchLeads();
    }
  }
}
</script>

<style scoped>
.admin-dashboard-content {
  margin-top: 20px;
  height: calc(100vh - 100px);
  overflow: hidden;
  position: relative;
}

table {
  width: 100%;
  border-collapse: collapse;
  background-color: var(--primary-background-cream);
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: var(--primary-background-cream);
  font-weight: bold;
}

tr:hover {
  background-color: var(--accent-sunny-yellow);
  cursor: pointer;
}

/* Modal styles */
.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.modal-content {
  background-color: var(--primary-background-cream);
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 90%;
  max-width: 1200px;
  height: 80vh;
  display: flex;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-main {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 400px;
  height: 100%;
}

.modal-expanded-content {
  flex: 2;
  overflow-y: auto;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

button {
  padding: 5px 10px;
  background-color: var(--accent-victory-green);
  color: white;
  border: none;
  cursor: pointer;
}

button:hover, button.active {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

.discovery-content,
.proposal-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.discovery-item,
.proposal-item {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.discovery-item h5,
.proposal-item h5 {
  margin-top: 0;
  color: var(--accent-victory-green);
}

.proposal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.proposal-buttons {
  display: flex;
  gap: 10px;
  align-items: center;
}

.view-proposal-button,
.generate-proposal-button {
  padding: 10px 20px;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.view-proposal-button {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
  border: none;
}

.view-proposal-button:hover {
  background-color: var(--accent-victory-green);
  color: white;
  transform: translateY(-2px);
}

.generate-proposal-button {
  background-color: var(--accent-victory-green);
  color: white;
  border: none;
}

.generate-proposal-button:hover {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
  transform: translateY(-2px);
}

.transcript-content {
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.transcript-body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.transcript-line {
  /* display: flex; */
  margin-bottom: 15px;
}

.transcript-timestamp {
  flex: 0 0 80px;
  color: #888;
  font-size: 0.9em;
  padding-top: 3px;
}

.transcript-message {
  flex: 1;
}

.transcript-speaker {
  font-weight: bold;
  color: var(--accent-victory-green);
  margin-right: 10px;
}

.transcript-text {
  display: inline;
}

.assessment-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.assessment-details {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.assessment-header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--accent-victory-green);
  color: white;
  padding: 20px;
  border-radius: 8px;
}

.assessment-score {
  text-align: center;
}

.assessment-score h2 {
  font-size: 2.5em;
  margin: 0;
  color: white;  /* Ensure the grade and score are white */
}

.assessment-score p {
  margin: 0;
  font-size: 0.9em;
  color: white;  /* Ensure the labels are also white */
}

.assessment-dates {
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.date-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.date-item p {
  margin: 0;
}

.date-item p:first-child {
  margin-bottom: 5px;
}

.assessment-results {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.assessment-results h5 {
  margin-top: 0;
  color: var(--accent-victory-green);
}

.assessment-results ul {
  list-style-type: none;
  padding-left: 0;
}

.assessment-results li {
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
}

.question-number {
  flex: 0 0 40px;
  font-weight: bold;
  color: var(--accent-victory-green);
}

.question-answer {
  flex: 1;
}

.edit-textarea {
  width: 100%;
  min-height: 100px;
  padding: 10px;
  border: 2px solid var(--accent-victory-green);
  border-radius: 4px;
  font-family: inherit;
  font-size: inherit;
  resize: vertical;
}

.discovery-item p,
.proposal-item p {
  cursor: pointer;
  padding: 10px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.discovery-item p:hover,
.proposal-item p:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.step-progress {
  padding: 15px;
  margin: 0;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.progress-header {
  margin-bottom: 15px;
}

.progress-header h4 {
  color: var(--accent-victory-green);
  margin: 0 0 8px 0;
  font-size: 1.1em;
}

.step-description {
  background-color: #f8f8f8;
  border-radius: 6px;
  padding: 8px;
}

.step-description p {
  margin: 0;
  color: var(--accent-victory-green);
  font-weight: 600;
  font-size: 0.9em;
}

.progress-container {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
}

.progress-bar {
  flex: 1;
  position: relative;
  height: 6px;
  background-color: #e0e0e0;
  border-radius: 10px;
  margin: 15px 0;
}

.step-controls {
  display: flex;
  gap: 8px;
}

.step-button {
  width: 32px;
  height: 32px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--accent-victory-green);
  color: white;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1.2em;
  line-height: 1;
}

.step-button:hover:not(:disabled) {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
  transform: translateY(-2px);
}

.step-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  transform: none;
}

.company-info-section {
  background: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.company-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.company-header h3 {
  margin: 0;
  color: var(--accent-victory-green);
}

.active-status {
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 0.9em;
  font-weight: 500;
  background-color: #ff4444;
  color: white;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.active-status:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.active-status.active {
  background-color: #00C851;
}

.info-grid {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.info-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.info-item label {
  font-weight: 600;
  color: #666;
  font-size: 0.9em;
}

.info-item span {
  color: #333;
}

.nav-buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding: 0;
}

.nav-buttons button {
  padding: 12px;
  border-radius: 8px;
  font-size: 0.95em;
  font-weight: 500;
}

.next-steps-section {
  background: white;
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

.next-steps-section h4 {
  color: var(--accent-victory-green);
  margin: 0 0 10px 0;
}

.next-steps-section p {
  margin: 0;
  line-height: 1.4;
}

.nav-sections {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.nav-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}

.nav-sections button {
  padding: 12px;
  border-radius: 8px;
  font-size: 0.95em;
  font-weight: 500;
  width: 100%;
  transition: all 0.3s ease;
}

.nav-sections button.active {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

.nav-sections button:hover:not(.invisible) {
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nav-sections button.invisible {
  visibility: hidden;
  pointer-events: none;
}

.info-item {
  margin-bottom: 8px;
}

.info-item:last-child {
  margin-bottom: 0;
}

.info-item label {
  display: block;
  font-weight: 600;
  color: #666;
  font-size: 0.9em;
  margin-bottom: 4px;
}

.info-item span {
  color: #333;
}

.info-card {
  background: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.info-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.info-row:last-child {
  margin-bottom: 0;
}

.info-row label {
  font-weight: 600;
  color: var(--accent-victory-green);
  margin-bottom: 5px;
}

.info-row span {
  font-size: 1.1em;
}

.status-active {
  color: #00C851;
  font-weight: 600;
}

.contacts-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contact-item {
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 6px;
  transition: background-color 0.2s;
}

.contact-item:hover {
  background-color: #f0f0f0;
}

.contact-email {
  color: var(--accent-victory-green);
  font-weight: 500;
}

.basic-content,
.contacts-content,
.email-content {
  padding: 20px;
}

.contacts-header {
  margin-bottom: 20px;
}

.add-contact {
  display: flex;
  gap: 10px;
}

.add-contact input {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.9em;
}

.add-button {
  padding: 8px 16px;
  background-color: var(--accent-victory-green);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.add-button:hover {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

.contact-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background-color: #f8f8f8;
  border-radius: 6px;
  margin-bottom: 8px;
}

.remove-button {
  padding: 4px 8px;
  background-color: #ff4444;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.8em;
  transition: all 0.3s ease;
}

.remove-button:hover {
  background-color: #cc0000;
  transform: translateY(-1px);
}

.transcript-upload {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.transcript-input {
  width: 100%;
  min-height: 200px;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: monospace;
  resize: vertical;
}

.transcript-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.upload-button {
  background-color: var(--accent-victory-green);
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.upload-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.upload-button:hover:not(:disabled) {
  background-color: var(--accent-victory-green-dark);
}

.send-proposal-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
  margin-right: 10px;
}

.send-proposal-button:hover {
  background-color: #45a049;
  transform: translateY(-2px);
}

.status-badge {
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 0.9em;
  font-weight: 500;
  background-color: #ff4444;
  color: white;
  display: inline-block;
}

.status-badge.status-active {
  background-color: #00C851;
}

.logout-button {
  padding: 8px 16px;
  background-color: var(--accent-victory-green);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
}

.logout-button:hover {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
  transform: scale(1.05);
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 20px 20px;
  margin-bottom: 20px;
}

.dashboard-header h2 {
  margin: 0;
  color: var(--accent-victory-green);
}
</style>

