<template>
  <div class="login-form">
    <h1>Login</h1>
    <form @submit.prevent="handleLogin">
      <div class="form-group">
        <input type="email" id="email" v-model="email" placeholder="Email" required>
      </div>
      <div class="form-group">
        <input :type="showPassword ? 'text' : 'password'" id="password" v-model="password" placeholder="Password" required>
        <span class="password-toggle" @click="togglePassword">
          {{ showPassword ? '👁️' : '👁️‍🗨️' }}
        </span>
      </div>
      <div class="form-group remember-forgot">
        <label>
          <input type="checkbox" v-model="rememberMe">
          Remember me
        </label>
        <a href="#" class="forgot-password" @click.prevent="switchToForgotPassword">Forgot password?</a>
      </div>
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
      <button type="submit" class="login-button" :disabled="isLoading">
        {{ isLoading ? 'Logging in...' : 'Login' }}
      </button>
    </form>
    <p class="signup-link">Don't have an account? <a href="#" @click.prevent="switchToSignUp">Sign up</a></p>
  </div>
</template>

<script>
import { loginUser } from '@/services/api';
import { mapActions } from 'vuex';

export default {
  name: 'LoginForm',
  data() {
    return {
      email: '',
      password: '',
      rememberMe: false,
      showPassword: false,
      activeDot: 0,
      isLoading: false,
      errorMessage: '',
      // slides: [
      //   {
      //     image: require('@/assets/slide1.png'),
      //     title: 'AI Strategy Assessment',
      //     content: 'Start with our comprehensive AI readiness assessment to understand your business needs.'
      //   },
      //   {
      //     image: require('@/assets/slide2.png'),
      //     title: 'Custom AI Solutions',
      //     content: 'Get personalized AI strategies tailored to your business needs.'
      //   },
      //   {
      //     image: require('@/assets/slide3.png'),
      //     title: 'Expert Support',
      //     content: 'Our team of experts is here to guide you every step of the way.'
      //   }
      // ]
    }
  },
  created() {
    const rememberedEmail = localStorage.getItem('rememberedEmail');
    if (rememberedEmail) {
      this.email = rememberedEmail;
      this.rememberMe = true;
    }
  },
  methods: {
    ...mapActions(['setShowHeader', 'setLoginState']),
    async handleLogin() {
      this.isLoading = true;
      this.errorMessage = '';
      try {
        const response = await loginUser(this.email, this.password);
        
        if (response.assessment) {
          localStorage.setItem('assessmentData', JSON.stringify(response));
          localStorage.setItem('assessment_id', response.assessment.assessment_id);
        }
        
        if (response.user_type) {
          localStorage.setItem('user_type', response.user_type);
        }
        
        this.setLoginState(true);
        
        this.setShowHeader(false);
        this.$router.push('/business-dashboard');
      } catch (error) {
        console.error('Login error:', error);
        this.errorMessage = error.message;
      } finally {
        this.isLoading = false;
      }
    },
    togglePassword() {
      this.showPassword = !this.showPassword
    },
    switchToForgotPassword() {
      this.$emit('switch-component', 'ForgotPasswordForm')
    },
    switchToSignUp() {
      this.$emit('switch-component', 'SignUpForm')
    },
    setActiveDot(index) {
      this.activeDot = index;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$el.querySelector('#email').focus()
    })
  }
}
</script>

<style scoped>
.login-form {
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-form h1 {
  font-size: 24px;
  margin-bottom: 30px;
  color: var(--accent-victory-green);
}

.form-group {
  margin-bottom: 20px;
  position: relative;
}

input[type="email"], input[type="password"], input[type="text"] {
  width: 100%;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 14px;
}

.password-toggle {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 0.3s ease;
}

.password-toggle:hover {
  opacity: 0.7;
  transform: translateY(-50%) scale(1.1);
}

.remember-forgot {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.login-button {
  width: 100%;
  padding: 12px;
  background-color: var(--accent-victory-green);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.5s ease;
}

.login-button:hover {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
  transform: scale(1.05);
}

.login-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.signup-link {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}

.signup-link a, .forgot-password {
  color: var(--accent-victory-green);
  text-decoration: none;
}

.signup-link a:hover, .forgot-password:hover {
  text-decoration: underline;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

@media (max-width: 768px) {
  .login-form {
    padding: 20px;
  }

  .login-form h1 {
    font-size: 20px;
    margin-bottom: 20px;
    text-align: center;
  }

  .form-group {
    margin-bottom: 15px;
  }

  input[type="email"],
  input[type="password"] {
    padding: 10px;
    font-size: 16px;
    width: 95%;
  }

  .remember-forgot {
    font-size: 13px;
  }

  .login-button {
    padding: 12px;
    font-size: 16px;
  }

  .signup-link {
    font-size: 13px;
    margin-top: 15px;
  }
}
</style>
