<template>
  <div class="discovery-call-content">
    <ScheduleView />
  </div>
</template>

<script>
import ScheduleView from '@/views/ScheduleView.vue'

export default {
  name: 'DiscoveryCallContent',
  components: {
    ScheduleView
  },
  props: {
    assessmentData: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.discovery-call-content {
  height: 100%;
  background-color: var(--primary-background-cream);
}
</style>
