import axios from 'axios';
// import firebase from '../firebase';

const API_BASE_URL = 'https://victoryai.pythonanywhere.com';

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

export const loginUser = async (email, password, rememberMe) => {
  console.log('loginUser called with:', { email, rememberMe });
  try {
    console.log('Making API request to:', `${API_BASE_URL}/api/login`);
    const response = await api.post('/api/login', { email, password, rememberMe });
    console.log('Raw API response:', response);
    
    if (response.data.token) {
      console.log('Token received, storing in localStorage');
      localStorage.setItem('authToken', response.data.token);
      localStorage.setItem('userType', response.data.user_type);
      if (rememberMe) {
        localStorage.setItem('rememberedEmail', email);
      } else {
        localStorage.removeItem('rememberedEmail');
      }
    }
    
    // Store assessment data in localStorage
    if (response.data.assessment) {
      console.log('Assessment data received:', response.data.assessment);
      localStorage.setItem('assessmentData', JSON.stringify({ assessment: response.data.assessment }));
    } else {
      console.log('No assessment data in response');
    }
    
    console.log('Returning response data:', response.data);
    return response.data;
  } catch (error) {
    console.error('Login error in api.js:', error);
    if (error.response) {
      console.error('Error response details:', {
        status: error.response.status,
        data: error.response.data,
        headers: error.response.headers
      });
      switch (error.response.status) {
        case 400:
          throw new Error('Invalid email format');
        case 404:
          throw new Error('User not found');
        case 401:
          throw new Error('Authentication failed');
        default:
          throw new Error(error.response.data.error || 'An error occurred during login');
      }
    } else if (error.request) {
      console.error('No response received:', error.request);
      throw new Error('No response received from server');
    } else {
      console.error('Error setting up request:', error.message);
      throw new Error('An error occurred during login');
    }
  }
};

export const signupUser = async (name, email, password, companyName) => {
  try {
    const response = await api.post('/api/signup', { 
      name, 
      email, 
      password,
      company_name: companyName
    });
    
    // Store the token and user type if signup is successful
    if (response.data.token) {
      localStorage.setItem('authToken', response.data.token);
      localStorage.setItem('userType', response.data.user_type);
    }
    
    // Log the assessment data
    if (response.data.assessment) {
      // console.log('Assessment data:', response.data.assessment);
    }
    
    return response.data;
  } catch (error) {
    if (error.response) {
      switch (error.response.status) {
        case 409:
          throw new Error('An account with this email already exists');
        case 400:
          throw new Error('Name, email, and password are required');
        default:
          throw new Error(error.response.data.error || 'An error occurred during signup');
      }
    }
    throw new Error('An error occurred during signup');
  }
};

export const forgotPassword = async (email) => {
  try {
    const response = await api.post('/api/forgot-password', { email });
    return response.data;
  } catch (error) {
    console.error('Error in forgot password:', error);
    throw new Error('Failed to process password reset request');
  }
};

// Add more API functions as needed

export const sendUserInfo = async (firstName, email) => {
  try {
    const response = await api.post('/start-assessment', { firstName, email });
    return response.data;
  } catch (error) {
    console.error('Error sending user info:', error);
    throw error;
  }
};

export const updateAssessmentResults = async (assessmentId, results) => {
  try {
    const response = await api.post('/update-assessment', { assessmentId, results });
    return response.data;
  } catch (error) {
    console.error('Error updating assessment results:', error);
    throw error;
  }
};

export async function completeAssessment(email, assessmentId, userChoices, firstName, isLoggedIn) {
  try {
    const response = await api.post('/api/complete-assessment', {
      email,
      assessment_id: assessmentId,
      user_choices: userChoices,
      firstName: firstName,
      is_logged_in: isLoggedIn
    });
    
    // Log the response data including the report URL
    console.log('Assessment completion response:', response.data);
    if (response.data.report_url) {
      // console.log('Report URL:', response.data.report_url);
    }
    
    return response.data;
  } catch (error) {
    console.error('Error completing assessment:', error);
    throw error;
  }
}

export const fetchLeads = async () => {
  try {
    const response = await api.get('/api/admin/leads');
    return response.data;
  } catch (error) {
    console.error('Error fetching leads:', error);
    throw error;
  }
};

export const fetchAssessment = async (assessmentId) => {
  try {
    const response = await api.get(`/api/assessment/${assessmentId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching assessment:', error);
    throw error;
  }
};

// Add these new functions for calendar operations
api.getAvailableTimes = async (date) => {
  try {
    const response = await api.get('/api/calendar/available-times', {
      params: { date: date.toISOString().split('T')[0] }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching available times:', error);
    throw error;
  }
};

api.bookAppointment = async (bookingData) => {
  try {
    const response = await api.post('/api/calendar/book', bookingData);
    return response.data;
  } catch (error) {
    console.error('Error booking appointment:', error);
    throw error;
  }
};

api.getMonthAvailability = async (year, month) => {
  try {
    const response = await api.get('/api/calendar/month-availability', {
      params: { year, month }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching month availability:', error);
    throw error;
  }
};

export const generateProposal = async (docId) => {
  try {
    const response = await api.post('/api/generate-proposal', { docId });
    return response.data;
  } catch (error) {
    console.error('Error generating proposal:', error);
    throw error;
  }
};

export const updateAnswers = async (docId, type, field, value) => {
  try {
    const response = await api.post('/api/update-answers', {
      docId,
      type,
      field,
      value
    });
    return response.data;
  } catch (error) {
    console.error('Error updating answers:', error);
    throw error;
  }
};

export const updateLeadStep = async (docId, step) => {
  try {
    const response = await api.post('/api/update-lead-step', {
      docId,
      step
    });
    return response.data;
  } catch (error) {
    console.error('Error updating lead step:', error);
    throw error;
  }
};

export const addContact = async (docId, email) => {
  try {
    const response = await api.post('/api/add-contact', {
      docId,
      email
    });
    return response.data;
  } catch (error) {
    console.error('Error adding contact:', error);
    throw error;
  }
};

export const removeContact = async (docId, email) => {
  try {
    const response = await api.post('/api/remove-contact', {
      docId,
      email
    });
    return response.data;
  } catch (error) {
    console.error('Error removing contact:', error);
    throw error;
  }
};

export const toggleLeadActive = async (docId, active) => {
  try {
    const response = await api.post('/api/toggle-lead-active', {
      docId,
      active
    });
    return response.data;
  } catch (error) {
    console.error('Error toggling lead active status:', error);
    throw error;
  }
};

export const uploadTranscript = async (transcriptText, leadEmail) => {
  try {
    const response = await api.post('/api/transcription', {
      meeting_transcription: transcriptText,
      participants: [leadEmail],
    });

    return response.data;
  } catch (error) {
    console.error('Error uploading transcript:', error);
    throw error;
  }
};

export const sendProposalEmail = async (docId) => {
  try {
    const response = await api.post('/api/send-proposal-email', { docId });
    return response.data;
  } catch (error) {
    console.error('Error sending proposal email:', error);
    throw error;
  }
};

export const submitWhitePaperEmail = async (email) => {
  try {
    const response = await api.post('/api/white-paper', { email });
    return response.data;
  } catch (error) {
    console.error('Error submitting white paper email:', error);
    throw error;
  }
};

export const getCompletedAssessment = async (assessmentId) => {
  try {
    console.log('Making API request to get completed assessment:', assessmentId);
    const response = await api.get(`/api/get-completed-assessment/${assessmentId}`);
    console.log('API response for completed assessment:', response.data);
    
    // If the request is successful, update the assessment data in localStorage
    if (response.data.assessment) {
      console.log('Updating localStorage with new assessment data');
      localStorage.setItem('assessmentData', JSON.stringify({ 
        assessment: response.data.assessment 
      }));
    }
    
    return response.data;
  } catch (error) {
    console.error('Error fetching completed assessment:', error);
    if (error.response && error.response.status === 404) {
      throw new Error('Assessment not found');
    }
    throw error;
  }
};

export default api;
