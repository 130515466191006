<template>
  <footer class="footer">
    <div class="footer-content">
      <div class="footer-section">
        <h3>Contact Us</h3>
        <ul>
          <li>Email: hello@victoryai.io</li>
          <li>Phone: +1 (614) 758-6778</li>
          <li><router-link to="/contact">More Contact Info</router-link></li>
        </ul>
      </div>
      <div class="footer-section">
        <h3>Quick Links</h3>
        <ul>
          <li><router-link to="/faq">FAQ</router-link></li>
          <li><router-link to="/cookie-preferences">Cookie Preferences</router-link></li>
          <li><router-link to="/money-back-guarantee">100% Money Back Guarantee</router-link></li>
        </ul>
      </div>
      <div class="footer-section">
        <h3>Legal</h3>
        <ul>
          <li><router-link to="/terms-of-service">Terms of Service</router-link></li>
          <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
          <li><router-link to="/accessibility">Accessibility</router-link></li>
        </ul>
      </div>
    </div>
    <div class="footer-bottom">
      <p>&copy; 2024 Victory AI. All rights reserved.</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent'
}
</script>

<style scoped>
.footer {
  background-color: var(--accent-victory-green);
  color: var(--primary-background-cream);
  font-size: 0.7rem;
  height: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0.5rem 2rem;
  height: calc(100% - 25px);
  overflow-y: auto;
}

.footer-section {
  flex: 0 1 auto;
  margin: 0;
  min-width: 150px;
  display: flex;
  flex-direction: column;
}

h3 {
  font-size: 0.8rem;
  margin-bottom: 0.2rem;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  margin-bottom: 0.1rem;
}

a {
  color: var(--primary-background-cream);
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: var(--accent-sunny-yellow);
}

.footer-bottom {
  text-align: center;
  padding: 0.2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .footer-section {
    margin-bottom: 0.3rem;
    text-align: left;
  }
}
</style>
