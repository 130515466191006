<template>
  <section class="waste">
    <div class="cream-section top"></div>
    <div class="centered-text">
      <!-- Desktop version -->
      <div v-if="!isMobile" class="text-container">
        <h2 v-for="(line, lineIndex) in desktopText" :key="lineIndex" class="slide-text">
          {{ line }}
        </h2>
      </div>
      <!-- Mobile version -->
      <div v-else class="text-container">
        <h2 class="slide-text">{{ mobileText[0] }}</h2>
        <h2 class="slide-text space-after">{{ mobileText[1] }}</h2>
        <img src="https://res.cloudinary.com/dhkjot9fq/image/upload/v1731683476/fire_vzz4p6.png" alt="Fire" class="fire-icon" />
        <h2 class="slide-text space-before">{{ mobileText[2] }}</h2>
      </div>
    </div>
    <div class="cream-section bottom"></div>
  </section>
</template>

<script>
export default {
  name: 'Waste',
  data() {
    return {
      desktopText: ["Stop wasting money", "on AI projects that don't deliver results"],
      mobileText: ["Stop wasting money", "On AI projects", "that don't deliver results"],
      isMobile: false
    }
  },
  mounted() {
    this.checkMobile()
    window.addEventListener('resize', this.checkMobile)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile)
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 768
    }
  }
}
</script>

<style scoped>
.waste {
  scroll-snap-align: start;
  height: 100vh;
  background: #093927;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.centered-text {
  text-align: center;
  max-width: 80%;
}

.slide-text {
  font-size: 3.5rem;
  color: var(--primary-background-cream);
  margin: 0.5rem 0;
  white-space: normal;
}

@media (max-width: 768px) {
  .waste {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .cream-section {
    background: var(--primary-background-cream);
    height: 8vh;
    width: 100%;
  }

  .cream-section.top {
    position: absolute;
    top: 0;
  }

  .cream-section.bottom {
    position: absolute;
    bottom: 0;
  }

  .centered-text {
    max-width: 90%;
    padding: 15vh 20px;
    z-index: 1;
  }

  .slide-text {
    font-size: 2.2rem;
    line-height: 1.3;
    margin: 0.5rem 0;
  }

  .space-after {
    margin-bottom: 1.5rem;
  }

  .space-before {
    margin-top: 1.5rem;
  }

  .fire-icon {
    width: 80px;
    height: auto;
    margin: 1.5rem 0;
  }
}
</style>
