<template>
  <div class="contact">
    <h1>Contact Us</h1>
    <p>
      We're here to help! If you have any questions about our services or need assistance, please don't hesitate to reach out to us.
    </p>
    <h2>Get in Touch</h2>
    <ul>
      <li><strong>Email:</strong> <a href="mailto:hello@victoryai.io">hello@victoryai.io</a></li>
      <li><strong>Phone:</strong> +1 (614) 845-0622</li>
    </ul>
    <h2>Business Hours</h2>
    <p>
      Monday - Friday: 9:00 AM - 4:00 PM (EST)<br>
      Saturday - Sunday: Closed
    </p>
    <p>
      We strive to respond to all inquiries within 24 business hours. Thank you for your interest in Victory AI!
    </p>
  </div>
</template>

<script>
export default {
  name: 'ContactView'
}
</script>

<style scoped>
.contact {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h1, h2 {
  color: var(--accent-victory-green);
}

p, ul {
  margin-bottom: 20px;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

li {
  margin-bottom: 10px;
}
</style>
