<template>
  <section class="challenge-two">
    <div class="challenge-content">
      <!-- Desktop version -->
      <div v-if="!isMobile" class="slide-container">
        <div class="slide" :class="{ 'initial-load': shouldAnimate }">
          <div class="top-text">
            <h2 v-for="(line, lineIndex) in slides[0].text" :key="lineIndex" class="slide-text">
              {{ line }}
            </h2>
          </div>
          <div class="columns-container">
            <div v-for="(column, colIndex) in slides[0].columns" :key="colIndex" class="column" :class="{ 'active': columnsLoaded }">
              <div class="column-card">
                <div class="image-container">
                  <img :src="column.image" :alt="column.title">
                </div>
                <div class="column-text">
                  <h3>{{ column.title }}</h3>
                  <p>{{ column.text }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <!-- Mobile version -->
      <div v-else class="mobile-slide-container">
        <div class="top-text">
          <h2 class="slide-text">We dive deep into your</h2>
          <h2 class="slide-text">business</h2>
          <h3 class="slide-text">to identify key areas</h3>
          <h3 class="slide-text">where AI can</h3>
        </div>
        
        <div class="mobile-carousel" 
             ref="carousel"
             @touchstart="handleTouchStart"
             @touchmove="handleTouchMove"
             @touchend="handleTouchEnd">
          <div class="mobile-columns-container" 
               :style="{ transform: `translateX(-${currentIndex * 100}%)` }">
            <div v-for="(column, colIndex) in slides[0].columns" 
                 :key="colIndex" 
                 class="mobile-column">
              <div class="column-card">
                <div class="image-container">
                  <img :src="column.image" :alt="column.title">
                </div>
                <div class="column-text">
                  <h3>{{ column.title }}</h3>
                  <p>{{ column.text }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <!-- Dots indicator -->
        <div class="carousel-dots">
          <span v-for="(_, index) in slides[0].columns" 
                :key="index" 
                :class="['dot', { active: currentIndex === index }]"
                @click="setCurrentIndex(index)">
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ChallengeTwoSection',
  data() {
    return {
      slides: [
        {
          text: ["We dive deep into your", "business", "to identify key areas", "where AI can"],
          columns: [
            { 
              image: 'https://res.cloudinary.com/dhkjot9fq/image/upload/v1731684118/image1_gace9u.png', 
              title: 'Make the Biggest Impact', 
              text: 'In some cases, AI can add a massive advantage or completely eliminate the need for certain tasks. Think of how long it would take a human to go through thousands of reports to find a discrepancy. AI can do so in seconds.' 
            },
            { 
              image: 'https://res.cloudinary.com/dhkjot9fq/image/upload/v1731685748/image2_k2evij.png', 
              title: 'Deliver quick results', 
              text: 'Not every automation has to take months to implement and tens of thousands of dollars. Some can be done in a matter of days at a low cost. We focus on identifying these quick wins to massively improve your operations' 
            },
            { 
              image: 'https://res.cloudinary.com/dhkjot9fq/image/upload/v1731684119/image3_ois3z8.png', 
              title: 'Be Avoided', 
              text: 'Sometimes a person adds a human touch that should not be automated away. We help you identify the areas where AI can be most impactful and avoid the areas where it may not be the best fit.' 
            }
          ]
        }
      ],
      isActive: false,
      shouldAnimate: true,
      columnsLoaded: false,
      scrollPosition: 0,
      maxScroll: 0,
      isMobile: false,
      currentIndex: 0,
      touchStartX: 0,
      touchEndX: 0,
      touchStartY: 0,
      touchEndY: 0
    }
  },
  methods: {
    loadColumns() {
      if (!this.columnsLoaded) {
        this.columnsLoaded = true;
      }
    },
    setActive(active) {
      this.isActive = active;
      if (active) {
        this.resetSlide();
        this.$nextTick(() => {
          this.maxScroll = this.$el.scrollHeight - this.$el.clientHeight;
        });
        if (this.shouldAnimate) {
          this.playAnimation();
        }
      }
    },
    handleScroll(event) {
      if (!this.isActive) return false;

      const direction = event.deltaY > 0 ? 1 : -1;
      this.scrollPosition += direction * 30; // Adjust scroll speed as needed

      if (this.scrollPosition < 0) {
        this.scrollPosition = 0;
        this.$emit('challenge-two-scroll-top');
        return false;
      } else if (this.scrollPosition > this.maxScroll) {
        this.scrollPosition = this.maxScroll;
        this.$emit('challenge-two-completed');
        return false;
      }

      this.$el.scrollTop = this.scrollPosition;
      return true;
    },
    resetSlide() {
      this.scrollPosition = 0;
      this.$el.scrollTop = 0;
      this.loadColumns();
    },
    playAnimation() {
      // Play the animation
      this.shouldAnimate = true;
      // Set a flag in localStorage to indicate the animation has been played
      localStorage.setItem('challengeTwoAnimationPlayed', 'true');
      // Remove the animation class after it has played
      setTimeout(() => {
        this.shouldAnimate = false;
      }, 1000); // Adjust this timeout to match your animation duration
    },
    handleBeforeUnload() {
      // Clear the animation played flag when the page is about to be unloaded (refreshed)
      localStorage.removeItem('challengeTwoAnimationPlayed');
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 768
      if (this.isMobile) {
        this.setupTouchEvents()
      } else {
        this.removeTouchEvents()
      }
    },
    setupTouchEvents() {
      const carousel = this.$refs.carousel
      if (carousel) {
        carousel.addEventListener('touchstart', this.handleTouchStart, { passive: false })
        carousel.addEventListener('touchmove', this.handleTouchMove, { passive: false })
        carousel.addEventListener('touchend', this.handleTouchEnd, { passive: false })
      }
    },
    removeTouchEvents() {
      const carousel = this.$refs.carousel
      if (carousel) {
        carousel.removeEventListener('touchstart', this.handleTouchStart)
        carousel.removeEventListener('touchend', this.handleTouchEnd)
      }
    },
    handleTouchStart(e) {
      e.preventDefault();
      this.touchStartX = e.touches[0].clientX
      this.touchStartY = e.touches[0].pageY
    },
    handleTouchMove(e) {
      if (!this.touchStartX || !this.touchStartY) return;

      const touchX = e.touches[0].clientX
      const touchY = e.touches[0].pageY
      const deltaX = Math.abs(this.touchStartX - touchX)
      const deltaY = Math.abs(this.touchStartY - touchY)

      // If horizontal swipe is greater than vertical movement, prevent scrolling
      if (deltaX > deltaY) {
        e.preventDefault()
        e.stopPropagation()
      }
    },
    handleTouchEnd(e) {
      if (!this.touchStartX || !this.touchStartY) return;

      const touchEndX = e.changedTouches[0].clientX
      const touchEndY = e.changedTouches[0].pageY
      
      const deltaX = Math.abs(touchEndX - this.touchStartX)
      const deltaY = Math.abs(touchEndY - this.touchStartY)
      const swipeDistanceX = touchEndX - this.touchStartX
      const swipeDistanceY = touchEndY - this.touchStartY
      
      // Only handle as horizontal swipe if horizontal movement is greater than vertical
      if (deltaX > deltaY && Math.abs(swipeDistanceX) > 50) {
        e.preventDefault()
        e.stopPropagation()
        
        if (swipeDistanceX > 0 && this.currentIndex > 0) {
          // Swipe right
          this.currentIndex--
        } else if (swipeDistanceX < 0 && this.currentIndex < this.slides[0].columns.length - 1) {
          // Swipe left
          this.currentIndex++
        }
      } else if (deltaY > deltaX && Math.abs(swipeDistanceY) > 50) {
        // Handle vertical swipe only if it's a significant vertical movement
        if (swipeDistanceY < 0) {
          // Swiping up
          this.$emit('challenge-two-completed')
        } else {
          // Swiping down
          this.$emit('challenge-two-scroll-top')
        }
      }

      this.touchStartX = null
      this.touchStartY = null
    },
    handleSwipe() {
      const swipeDistance = this.touchEndX - this.touchStartX
      const minSwipeDistance = 50
      
      if (Math.abs(swipeDistance) > minSwipeDistance) {
        if (swipeDistance > 0 && this.currentIndex > 0) {
          // Swipe right
          this.currentIndex--
        } else if (swipeDistance < 0 && this.currentIndex < this.slides[0].columns.length - 1) {
          // Swipe left
          this.currentIndex++
        }
      }
    },
    setCurrentIndex(index) {
      if (index >= 0 && index < this.slides[0].columns.length) {
        this.currentIndex = index
      }
    }
  },
  mounted() {
    this.loadColumns();
    // Check if the animation has been played before
    const animationPlayed = localStorage.getItem('challengeTwoAnimationPlayed');
    if (animationPlayed === 'true') {
      this.shouldAnimate = false;
    }
    // Add an event listener for the beforeunload event
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    this.checkMobile()
    window.addEventListener('resize', this.checkMobile)
    if (this.isMobile) {
      this.setupTouchEvents()
    }
  },
  beforeUnmount() {
    // Remove the event listener when the component is unmounted
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
    window.removeEventListener('resize', this.checkMobile)
    if (this.isMobile) {
      this.removeTouchEvents()
    }
  }
}
</script>

<style scoped>
.challenge-two {
  scroll-snap-align: start;
  height: 100vh;
  background: linear-gradient(90deg, #fcf8f2, #79cce1);
  overflow-y: auto; /* Change to auto to enable scrolling */
  overflow-x: hidden;
}

.challenge-content {
  display: flex;
  height: 100%;
  position: relative;
}

.slide-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.slide-wrapper {
  height: 100%;
  position: relative;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 2rem; */
}

.slide-top {
  padding-top: 1rem;
}

.centered-text {
  text-align: center;
  max-width: 80%;
}

.slide-text {
  color: var(--accent-victory-green);
  margin: 0.5rem 0;
  white-space: normal;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.centered-text .slide-text {
  font-size: 3.5rem; /* Larger font size for the first slide */
}

.top-text .slide-text {
  font-size: 2.5rem; /* Smaller font size for the second slide */
}

.top-text {
  position: absolute;
  top: 2.5rem;
  left: 0;
  width: 100%;
  text-align: center;
  transition: transform 1s ease, opacity 1s ease;
}

.initial-load .top-text {
  transform: translateY(calc(50vh - 5rem));
  animation: slideFromBottom 1s ease-out forwards;
}

@keyframes slideFromBottom {
  0% {
    transform: translateY(100vh);
    opacity: 0;
  }
  100% {
    transform: translateY(calc(50vh - 5rem));
    opacity: 1;
  }
}

.columns-container {
  display: flex;
  justify-content: center;
  align-items: stretch;
  height: 60%;
  width: 90%;
  margin-top: 14rem;
  gap: 1.5rem;
}

.column {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transform: translateY(50px);
  max-width: 450px;
}

.column.active {
  opacity: 1;
  transform: translateY(0);
}

.column-card {
  background-color: rgba(255, 255, 255, 0.9); /* Slightly transparent white background */
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.image-container {
  width: 100%;
  height: 180px;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.column-text {
  padding: 1.5rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.column-text h3 {
  font-size: 1.5rem;
  color: var(--accent-victory-green);
  margin-bottom: 0.5rem;
}

.column-text p {
  font-size: 1rem;
  color: var(--main-text-color);
  line-height: 1.5;
}

.indicator-bar {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.indicator {
  width: 4px;
  height: 40px;
  background-color: rgba(9, 57, 39, 0.3);
  border-radius: 2px;
  transition: background-color 0.3s ease;
}

.indicator.active {
  background-color: var(--accent-victory-green);
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: opacity 3s ease, transform 3s ease;
}

.slide-fade-enter-from, .slide-fade-leave-to {
  opacity: 0;
}

.slide-fade-leave-to .centered-text {
  transform: translateY(-200%);
  transition: transform 3s ease, opacity 3s ease;
}

.slide-fade-enter-from .top-text,
.slide-fade-enter-from .columns-container {
  transform: translateY(100px);
  opacity: 0;
  transition: transform 3s ease, opacity 3s ease;
}

.centered-text, .top-text, .columns-container {
  transition: transform 3s ease, opacity 3s ease;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

/* New class for initial animation */
.initial-load .centered-text {
  animation: slideFromBottom 0.8s ease-out forwards;
}

@keyframes slideFromBottom {
  0% {
    transform: translateY(100vh);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .challenge-two {
    height: 100vh;
    padding: 0;
    overflow: hidden;
  }

  .challenge-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .mobile-slide-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: flex-start;
  }

  .top-text {
    position: relative;
    text-align: left;
    padding: 20px;
    margin-top: 0px;
    max-width: 90%;
    margin-left: 20px;
    margin-right: auto;
  }

  .top-text .slide-text {
    font-size: 1.6rem;
    line-height: 1.2;
    margin: 0;
    color: var(--accent-victory-green);
    font-weight: 600;
  }

  .top-text .slide-text:nth-child(2) {
    margin-bottom: 8px;
  }

  .top-text .slide-text:nth-child(3) {
    font-size: 1rem;
    margin-bottom: 4px;
  }

  .top-text .slide-text:nth-child(4) {
    font-size: 1rem;
    color: var(--accent-victory-green);
    opacity: 0.8;
  }

  .mobile-carousel {
    width: 100%;
    overflow: hidden;
    padding: 0;
    margin-top: 40px;
    flex: 0 0 auto;
    height: auto;
  }

  .mobile-columns-container {
    display: flex;
    transition: transform 0.3s ease;
    width: 100%;
  }

  .mobile-column {
    min-width: 100%;
    flex: 0 0 100%;
    display: flex;
    justify-content: center;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .column-card {
    background: white;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
    min-height: 400px;
    display: flex;
    flex-direction: column;
  }

  .image-container {
    height: 160px;
    width: 100%;
    flex-shrink: 0;
  }

  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .column-text {
    padding: 16px;
    flex-grow: 1;
    overflow-y: auto;
  }

  .column-text h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: var(--accent-victory-green);
  }

  .column-text p {
    font-size: 0.9rem;
    line-height: 1.4;
    color: var(--main-text-color);
    margin: 0;
  }

  .carousel-dots {
    display: flex;
    justify-content: center;
    gap: 8px;
    padding: 20px;
    margin-top: 20px;
  }

  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: rgba(9, 57, 39, 0.3);
    cursor: pointer;
  }

  .dot.active {
    background: var(--accent-victory-green);
    transform: scale(1.2);
  }
}
</style>
