<template>
  <section class="cta">
    <div class="cta-content">
      <img src="@/assets/logo.png" alt="Victory AI Logo" class="cta-logo" @click="navigateToHero">
      <div class="hero-text">
        <h1>Build a Guaranteed Winning </h1>
        <h3>AI Strategy into Your Business</h3>
      </div>
      <div class="button-container">
        <button 
          class="action-button"
          @click="navigateToAssessment"
        >
          Take assessment
        </button>
        <button @click="navigateToSchedule" class="action-button schedule-call-btn">Schedule call</button>
      </div>
    </div>
    <FooterComponent class="cta-footer" />
  </section>
</template>

<script>
import FooterComponent from './FooterComponent.vue'

export default {
  name: 'CTASection',
  components: {
    FooterComponent
  },
  methods: {
    navigateToHero() {
      if (this.$route.name !== 'home') {
        this.$router.push({ name: 'home' })
      } else {
        // Call the parent's scrollToTop method
        this.$parent.scrollToTop()
      }
    },
    navigateToAssessment() {
      this.$router.push('/assessment')
    },
    navigateToSchedule() {
      this.$router.push('/schedule')
    }
  }
}
</script>

<style scoped>
.cta {
  scroll-snap-align: start;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cta-content {
  flex: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 5%;
}

.cta-logo {
  width: 350px;
  margin-bottom: 2rem;
  cursor: pointer;
}

.hero-text {
  margin-bottom: 2rem;
}

h1, h3 {
  margin: 0.5rem 0;
}

h1 {
  font-size: 3rem;
  color: var(--accent-victory-green);
}

h3 {
  font-size: 2rem;
  color: var(--main-text-color);
}

.button-container {
  display: flex;
  gap: 20px;
  margin-top: 30px;
}

.action-button {
  background-color: var(--accent-victory-green);
  color: var(--primary-background-cream);
  border: none;
  padding: 12px 24px;
  font-size: 1rem;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  text-transform: none;
  letter-spacing: normal;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: inherit;
}

.action-button:hover {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
  transform: scale(1.05);
}

.schedule-call-btn {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

.schedule-call-btn:hover {
  background-color: var(--accent-victory-green);
  color: var(--primary-background-cream);
}

.cta-footer {
  flex: 1;
}

@media (max-width: 768px) {
  .cta {
    min-height: 100vh;
    padding: 0;
    justify-content: space-between;
  }

  .cta-content {
    flex: 1;
    padding: 2rem 1rem;
    justify-content: center;
    gap: 1.5rem;
  }

  .cta-logo {
    width: 180px;
    margin-bottom: 1rem;
  }

  .hero-text {
    margin-bottom: 1rem;
  }

  h1 {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
  }

  h3 {
    font-size: 1rem;
  }

  .button-container {
    flex-direction: column;
    gap: 0.8rem;
    width: 100%;
    max-width: 280px;
    margin-top: 1rem;
  }

  .action-button {
    width: 100%;
    padding: 0.8rem;
    font-size: 0.9rem;
    border-radius: 2rem;
  }

  .cta-footer {
    flex: none;
    font-size: 0.5rem;
    margin-top: auto;
    width: 100%;
  }

  /* Update the deep selectors for footer */
  :deep(.footer-content) {
    padding: 0.5rem;
    padding-right: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }

  :deep(.footer-links) {
    gap: 0.5rem;
    font-size: 0.1rem;
    padding: 0 1rem;
    justify-content: space-between;
    max-width: 95%;
  }

  :deep(.footer-section) {
    /* padding: 0.01rem 0.5rem; */
    padding-left: 0.01rem;
    padding-right: 0.01rem;
    max-width: 10%;
  }

  :deep(.footer-section h3) {
    font-size: 0.8rem;
    margin-bottom: 0.3rem;
  }

  :deep(.footer-section a) {
    font-size: 0.5rem;
    margin: 0.2rem 0;
  }

  :deep(.copyright) {
    font-size: 0.5rem;
    padding: 0.5rem;
  }
}
</style>
