<template>
  <div class="schedule-container">
    <div class="schedule-content">
      <div class="schedule-column info-column">
        <ScheduleInfo />
      </div>
      <div class="vertical-line"></div>
      <div class="schedule-column calendar-column">
        <h2 class="mobile-header">Schedule a Call</h2>
        <CustomCalendar @datetime-selected="handleDateTimeSelected" />
      </div>
    </div>
  </div>
</template>

<script>
import CustomCalendar from '@/components/CustomCalendar.vue'
import ScheduleInfo from '@/components/ScheduleInfo.vue'

export default {
  name: 'ScheduleView',
  components: {
    CustomCalendar,
    ScheduleInfo
  },
  methods: {
    handleDateTimeSelected({ date, time }) {
      // console.log('Selected date and time:', date, time);
      // Here you can implement the logic to handle the selected date and time
      // For example, you might want to show a confirmation dialog or navigate to a booking page
    }
  }
}
</script>

<style scoped>
.schedule-container {
  margin: 0 auto;
  max-width: 1800px; 
  padding: 20px;
}

h1 {
  color: var(--accent-victory-green);
  margin-bottom: 30px;
  text-align: center;
  font-size: 32px;
}

.schedule-content {
  display: flex;
  background-color: #FCF8F2;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  min-height: 750px;
}

.schedule-column {
  padding: 30px;
}

.info-column {
  flex: 0 0 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar-column {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-line {
  position: absolute;
  top: 5%;
  left: 40%;
  height: 90%;
  width: 1px;
  background-color: #e0e0e0;
}

.mobile-header {
  display: none;
  margin-right: 20px;
}

@media (max-width: 768px) {
  .schedule-container {
    padding: 0;
    margin: 0;
    height: 100vh;
    overflow-y: auto;
    max-width: 100%;
    width: 100%;
  }

  .schedule-content {
    flex-direction: column;
    min-height: auto;
    box-shadow: none;
    background-color: transparent;
    width: 100%;
  }

  .schedule-column {
    padding: 15px;
    width: 100%;
  }

  .info-column {
    display: none;
  }

  .calendar-column {
    background-color: #FCF8F2;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    min-height: 90vh;
    margin: 0;
    padding: 15px;
    width: 100%;
    max-width: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .vertical-line {
    display: none;
  }

  .mobile-header {
    display: block;
    color: var(--accent-victory-green);
    font-size: 1.8rem;
    margin-bottom: 20px;
    padding: 0 10px;
  }
}
</style>
