<template>
  <div class="sitemap-container">
    <h1>Site Map</h1>
    <div class="sitemap-content">
      <div class="sitemap-section">
        <h2>Main Pages</h2>
        <ul>
          <li><router-link to="/">Home</router-link></li>
          <li><router-link to="/why-victory">Why Victory AI</router-link></li>
          <li><router-link to="/how-it-works">How It Works</router-link></li>
          <li><router-link to="/pricing">Pricing</router-link></li>
        </ul>
      </div>

      <div class="sitemap-section">
        <h2>Get Started</h2>
        <ul>
          <li><router-link to="/assessment">AI Assessment</router-link></li>
          <li><router-link to="/schedule">Schedule Call</router-link></li>
        </ul>
      </div>

      <div class="sitemap-section">
        <h2>Legal & Info</h2>
        <ul>
          <li><router-link to="/privacy">Privacy Policy</router-link></li>
          <li><router-link to="/terms">Terms of Service</router-link></li>
          <li><router-link to="/sitemap">Sitemap</router-link></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SitemapView'
}
</script>

<style scoped>
.sitemap-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

h1 {
  color: var(--accent-victory-green);
  margin-bottom: 40px;
  font-size: 2.5rem;
}

.sitemap-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 40px;
}

.sitemap-section {
  margin-bottom: 30px;
}

h2 {
  color: var(--accent-victory-green);
  font-size: 1.5rem;
  margin-bottom: 20px;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 15px;
}

a {
  color: var(--text-color);
  text-decoration: none;
  font-size: 1.1rem;
  transition: color 0.3s ease;
}

a:hover {
  color: var(--accent-victory-green);
}

@media (max-width: 768px) {
  .sitemap-container {
    padding: 20px;
  }

  h1 {
    font-size: 2rem;
    margin-bottom: 30px;
  }

  .sitemap-content {
    grid-template-columns: 1fr;
    gap: 30px;
  }
}
</style> 