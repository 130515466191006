<template>
  <div class="faq">
    <h1>Frequently Asked Questions</h1>
    <div v-for="(qa, index) in faqs" :key="index" class="faq-item">
      <h2>{{ qa.question }}</h2>
      <p>{{ qa.answer }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FAQView',
  data() {
    return {
      faqs: [
        {
          question: "What is Victory AI?",
          answer: "Victory AI is a cutting-edge artificial intelligence consulting firm that helps businesses integrate AI solutions to improve efficiency and drive growth."
        },
        {
          question: "How can AI benefit my business?",
          answer: "AI can benefit your business in numerous ways, including automating repetitive tasks, providing data-driven insights, enhancing customer experiences, and optimizing operations."
        },
        {
          question: "Do you offer customized AI solutions?",
          answer: "Yes, we specialize in developing customized AI solutions tailored to each client's unique needs and challenges."
        },
        {
          question: "How long does it take to implement an AI solution?",
          answer: "The implementation timeline varies depending on the complexity of the solution and your business needs. We work closely with you to establish a realistic timeline for your project."
        },
        {
          question: "Do you provide training for using AI tools?",
          answer: "Absolutely! We offer comprehensive training to ensure your team can effectively use and maintain the AI solutions we implement."
        }
      ]
    }
  }
}
</script>

<style scoped>
.faq {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  color: var(--accent-victory-green);
  margin-bottom: 30px;
}

.faq-item {
  margin-bottom: 30px;
}

h2 {
  color: var(--accent-victory-green);
  font-size: 1.2em;
  margin-bottom: 10px;
}

p {
  margin-bottom: 20px;
}
</style>
