<template>
  <div class="pricing-cards-container">
    <div class="background-texture-container">
      <img src="@/assets/texture.png" alt="Background texture" class="background-texture">
    </div>
    
    <h1>No long term contract</h1>
    <h1 class="pricing-subtitle">100% Money Back Guarantee</h1>

    <!-- Desktop cards -->
    <div v-if="!isMobile" class="pricing-cards">
      <div class="pricing-card" v-for="(plan, index) in plans" :key="index">
        <h3 class="plan-title">{{ plan.title }}</h3>
        <p class="plan-subtitle">{{ plan.subtitle }}</p>
        <div class="price-circle">
          <span class="price">{{ plan.price }}</span>
        </div>
        <ul class="plan-features">
          <li v-for="(feature, fIndex) in plan.features" :key="fIndex">{{ feature }}</li>
        </ul>
        <button 
          class="reserve-button" 
          :class="{ 'active': index === 1 }"
          @click="reservePlan(plan.title)"
        >
          Reserve Spot
        </button>
      </div>
    </div>

    <!-- Mobile carousel -->
    <div v-else class="mobile-carousel">
      <!-- Left Arrow -->
      <div class="carousel-arrow left" 
           v-if="currentIndex > 0"
           @click="setCurrentIndex(currentIndex - 1)">
        &lt;
      </div>

      <!-- Cards Container -->
      <div class="mobile-cards-container" 
           :style="{ transform: `translateX(-${currentIndex * 100}%)` }"
           @touchstart="handleTouchStart"
           @touchmove="handleTouchMove"
           @touchend="handleTouchEnd">
        <div v-for="(plan, index) in plans" 
             :key="index" 
             class="pricing-card">
          <h3 class="plan-title">{{ plan.title }}</h3>
          <p class="plan-subtitle">{{ plan.subtitle }}</p>
          <div class="price-circle">
            <span class="price">{{ plan.price }}</span>
          </div>
          <ul class="plan-features">
            <li v-for="(feature, fIndex) in plan.features" :key="fIndex">{{ feature }}</li>
          </ul>
          <button 
            class="reserve-button" 
            :class="{ 'active': index === 1 }"
            @click="reservePlan(plan.title)"
          >
            Reserve Spot
          </button>
        </div>
      </div>

      <!-- Right Arrow -->
      <div class="carousel-arrow right" 
           v-if="currentIndex < plans.length - 1"
           @click="setCurrentIndex(currentIndex + 1)">
        &gt;
      </div>

      <!-- Dots indicator -->
      <div class="carousel-dots">
        <span v-for="(plan, index) in plans" 
              :key="index"
              :class="['dot', { active: index === currentIndex }]"
              @click="setCurrentIndex(index)">
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PricingCards',
  data() {
    return {
      plans: [
        {
          title: 'Single',
          subtitle: 'Project',
          price: '$3k',
          features: [
            '1-2 weeks (up to 20 hours)',
            'Full Project Plan',
            'Minimal Disruption',
            'Complete Estimate for Project',
            'Best for organizations exploring AI Automation solutions with limited risk'
          ]
        },
        {
          title: 'Full',
          subtitle: 'Department',
          price: '$6k',
          features: [
            '2-4 weeks (up to 50 hours)',
            'Full Dept Plan',
            'Deep Dive into Dept',
            'Estimates for All Potential Projects',
            'Best for those looking to optimize a single department before scaling AI across the organization'
          ]
        },
        {
          title: 'Complete',
          subtitle: 'Organization',
          price: '$12k',
          features: [
            '3-6 weeks (up to 120 hours)',
            'Full Org Plan',
            'Deep Dive into Org',
            'Estimates for all Potential Projects',
            'Best for companies who want to future proof their entire organization with AI Automation'

          ]
        }
      ],
      currentIndex: 0,
      touchStartX: null,
      touchStartY: null,
      isMobile: false
    }
  },
  mounted() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile);
  },
  methods: {
    reservePlan(planTitle) {
      if (planTitle === 'Single') {
        this.$emit('reserve-project');
      } else if (planTitle === 'Full') {
        this.$emit('reserve-department');
      } else if (planTitle === 'Complete') {
        this.$emit('reserve-organization');
      } else {
        // console.log(`Reserving ${planTitle} plan`);
      }
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    handleTouchStart(e) {
      this.touchStartX = e.touches[0].clientX;
      this.touchStartY = e.touches[0].clientY;
    },
    handleTouchMove(e) {
      if (!this.touchStartX) return;
      e.preventDefault();
    },
    handleTouchEnd(e) {
      if (!this.touchStartX) return;
      
      const touchEndX = e.changedTouches[0].clientX;
      const touchEndY = e.changedTouches[0].clientY;
      
      const deltaX = this.touchStartX - touchEndX;
      const deltaY = Math.abs(touchEndY - this.touchStartY);
      
      // Only handle as swipe if horizontal movement is greater than vertical
      if (Math.abs(deltaX) > deltaY && Math.abs(deltaX) > 50) {
        if (deltaX > 0 && this.currentIndex < this.plans.length - 1) {
          // Swipe left - next card
          this.currentIndex++;
        } else if (deltaX < 0 && this.currentIndex > 0) {
          // Swipe right - previous card
          this.currentIndex--;
        }
      }
      
      this.touchStartX = null;
      this.touchStartY = null;
    },
    setCurrentIndex(index) {
      this.currentIndex = index;
    }
  }
}
</script>

<style scoped>
.background-texture-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 10vh;
  z-index: 0;
  pointer-events: none;
  width: 100vw;
}

.background-texture {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.05;
}

.pricing-cards-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  scroll-snap-align: start;
  padding: 20px;
  position: relative;
  overflow: hidden;
}

.pricing-title {
  color: var(--accent-victory-green);
  font-size: 3rem;
  margin-bottom: 10px;
  font-weight: bold;
}

.pricing-subtitle {
  color: var(--accent-victory-green);
  font-size: 1.5rem;
  margin: 5px 0;
  font-weight: normal;
}

.pricing-cards {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
}

.pricing-card {
  background-color: var(--primary-background-cream);
  border-radius: 10px;
  padding: 20px;
  width: 300px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.plan-title {
  color: var(--accent-victory-green);
  font-size: 1.8rem;
  margin-bottom: 5px;
}

.plan-subtitle {
  color: var(--accent-victory-green);
  font-size: 1.2rem;
  margin-top: 0;
}

.price-circle {
  background-color: var(--accent-victory-green);
  color: white;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
}

.price {
  font-size: 1.5rem;
  font-weight: bold;
}

.plan-features {
  list-style-type: none;
  padding: 0;
  text-align: left;
}

.plan-features li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 25px;
}

.plan-features li::before {
  content: '•';
  color: var(--accent-victory-green);
  position: absolute;
  left: 0;
  font-size: 1.5rem;
}

.reserve-button {
  background-color: var(--primary-background-cream);
  color: var(--accent-victory-green);
  border: 2px solid var(--accent-victory-green);
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 20px;
}

.reserve-button.active,
.reserve-button:hover {
  background-color: var(--accent-victory-green);
  color: white;
}

@media (max-width: 768px) {
  .pricing-cards-container {
    width: 100%;
    padding: 10px;
    height: auto;
    margin-top: 100px;
  }

  .mobile-carousel {
    width: 100%;
    overflow: hidden;
    position: relative;
    padding: 0 40px;
  }

  .mobile-cards-container {
    display: flex;
    transition: transform 0.3s ease;
    touch-action: pan-y pinch-zoom;
  }

  .pricing-card {
    min-width: 100%;
    flex: 0 0 100%;
    box-sizing: border-box;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    transform: translateX(0);
  }

  .plan-title {
    font-size: 1.4rem;
    margin: 0;
    text-align: center;
  }

  .plan-subtitle {
    font-size: 1rem;
    margin: 0;
    text-align: center;
  }

  .price-circle {
    width: 65px;
    height: 65px;
    margin: 5px auto;
  }

  .price {
    font-size: 1.2rem;
  }

  .plan-features {
    margin: 5px auto;
    text-align: left;
    padding: 0;
    width: fit-content;
    max-width: 80%;
  }

  .plan-features li {
    margin-bottom: 12px;
    font-size: 0.9rem;
    padding-left: 25px;
    position: relative;
    display: block;
    line-height: 1.3;
  }

  .plan-features li:last-child {
    margin-bottom: 0;
  }

  .plan-features li::before {
    content: '•';
    color: var(--accent-victory-green);
    position: absolute;
    left: 0;
    font-size: 1.2rem;
    top: -1px;
  }

  .reserve-button {
    padding: 10px 24px;
    font-size: 0.95rem;
    margin: 10px 0 0 0;
    width: auto;
    min-width: 140px;
  }

  h1 {
    font-size: 1.4rem;
    margin: 0 0 5px 0;
    text-align: center;
  }

  .pricing-subtitle {
    font-size: 1.1rem;
    margin: 0 0 20px 0;
    text-align: center;
  }

  .carousel-dots {
    margin-top: 20px;
    gap: 8px;
  }

  .dot {
    width: 6px;
    height: 6px;
  }

  .carousel-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--accent-victory-green);
    color: white;
    border-radius: 50%;
    font-size: 1.2rem;
    cursor: pointer;
    z-index: 2;
    opacity: 0.8;
    transition: opacity 0.3s ease;
  }

  .carousel-arrow:hover {
    opacity: 1;
  }

  .carousel-arrow.left {
    left: 12%;
  }

  .carousel-arrow.right {
    right: 12%;
  }

  .background-texture-container {
    height: 25vh;
  }
}
</style>
