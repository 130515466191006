<template>
  <div class="stakeholders-content">
    <h1>Stakeholders</h1>
    <div class="content-container">
      <!-- Show stakeholders list if participants exist -->
      <div v-if="assessmentData && assessmentData.participants && assessmentData.participants.length > 0" class="stakeholders-list">
        <h2>Project Stakeholders</h2>
        <div class="add-stakeholder">
          <input 
            v-model="newStakeholder.email" 
            placeholder="Email"
            type="email"
          />
          <button @click="addStakeholder" class="add-button">Add Stakeholder</button>
        </div>
        <div class="stakeholders-table">
          <div v-for="(stakeholder, index) in assessmentData.participants" :key="index" class="stakeholder-row">
            <div class="stakeholder-info">
              <h3>{{ stakeholder }}</h3>
            </div>
            <button @click="removeStakeholder(stakeholder)" class="remove-button">Remove</button>
          </div>
        </div>
      </div>
      <!-- Show default content if no stakeholders -->
      <div v-else class="default-content">
        <div class="info-section">
          <h2>Identify Your Stakeholders</h2>
          <p>Add key stakeholders who will be involved in the AI implementation process.</p>
          <div class="stakeholder-types">
            <div class="stakeholder-type">
              <h3>Decision Makers</h3>
              <p>Leaders who will approve and oversee the implementation</p>
            </div>
            <div class="stakeholder-type">
              <h3>End Users</h3>
              <p>Team members who will use the AI solutions daily</p>
            </div>
            <div class="stakeholder-type">
              <h3>Technical Team</h3>
              <p>IT staff who will support the integration</p>
            </div>
          </div>
          <div class="add-first-stakeholder">
            <input 
              v-model="newStakeholder.email" 
              placeholder="Enter stakeholder email"
              type="email"
            />
            <button @click="addStakeholder" class="add-button">Add First Stakeholder</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addContact, removeContact } from '@/services/api';

export default {
  name: 'StakeholdersContent',
  props: {
    assessmentData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      newStakeholder: {
        email: ''
      }
    }
  },
  methods: {
    async addStakeholder() {
      if (this.newStakeholder.email) {
        try {
          await addContact(this.assessmentData.id, this.newStakeholder.email);
          this.newStakeholder.email = '';
        } catch (error) {
          console.error('Error adding stakeholder:', error);
        }
      }
    },
    async removeStakeholder(email) {
      try {
        await removeContact(this.assessmentData.id, email);
      } catch (error) {
        console.error('Error removing stakeholder:', error);
      }
    }
  }
}
</script>

<style scoped>
.stakeholders-content {
  padding: 2rem;
}

.content-container {
  max-width: 800px;
  margin: 2rem auto;
}

.stakeholders-list {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.add-stakeholder {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
}

.add-stakeholder input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  flex: 1;
}

.add-button {
  background-color: var(--accent-victory-green);
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.stakeholder-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #eee;
}

.stakeholder-info h3 {
  margin: 0;
  color: var(--accent-victory-green);
}

.stakeholder-info p {
  margin: 0.5rem 0 0;
  color: #666;
}

.remove-button {
  background-color: #ff4444;
  color: white;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.default-content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.stakeholder-types {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin: 30px 0;
}

.stakeholder-type {
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.stakeholder-type:hover {
  transform: translateY(-5px);
}

.stakeholder-type h3 {
  color: var(--accent-victory-green);
  margin-bottom: 10px;
}

.stakeholder-type p {
  color: #666;
  margin: 0;
}

.add-first-stakeholder {
  display: flex;
  gap: 10px;
  margin-top: 30px;
}

.info-section ul {
  margin: 20px 0;
  padding-left: 20px;
}

.info-section li {
  margin-bottom: 10px;
  color: #666;
}
</style>
