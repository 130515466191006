<template>
  <div class="proposal-content">
    <h1>Proposal</h1>
    <div class="content-container">
      <div class="proposal-section">
        <h2>Your Custom Proposal</h2>
        <div v-if="assessmentData.proposal_url" class="proposal-preview">
          <p>Your proposal has been generated and is ready for review.</p>
          <button @click="viewProposal" class="view-button">View Proposal</button>
        </div>
        <div v-else class="proposal-status">
          <p>Your proposal will be generated after the discovery call.</p>
          <button 
            @click="generateProposal" 
            class="generate-button" 
            :disabled="!assessmentData.discovery_answers"
          >
            Generate Proposal
          </button>
        </div>
      </div>
      
      <!-- Show proposal answers if they exist -->
      <div v-if="assessmentData.proposal_answers" class="proposal-answers">
        <h3>Proposal Details</h3>
        <div v-for="(value, key) in assessmentData.proposal_answers" :key="key" class="proposal-item">
          <h4>{{ formatKey(key) }}</h4>
          <p>{{ value }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { generateProposal } from '@/services/api';

export default {
  name: 'ProposalContent',
  props: {
    assessmentData: {
      type: Object,
      required: true
    }
  },
  methods: {
    async generateProposal() {
      try {
        await generateProposal(this.assessmentData.id);
      } catch (error) {
        console.error('Error generating proposal:', error);
      }
    },
    viewProposal() {
      if (this.assessmentData.proposal_url) {
        window.open(this.assessmentData.proposal_url, '_blank');
      }
    },
    formatKey(key) {
      return key.split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }
  }
}
</script>

<style scoped>
.proposal-content {
  padding: 2rem;
}

.content-container {
  max-width: 800px;
  margin: 2rem auto;
}

.proposal-section {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.proposal-preview, .proposal-status {
  margin-top: 2rem;
  text-align: center;
}

.view-button, .generate-button {
  background-color: var(--accent-victory-green);
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1rem;
  transition: all 0.3s ease;
}

.view-button:hover, .generate-button:hover:not(:disabled) {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

.generate-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.proposal-answers {
  margin-top: 2rem;
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.proposal-item {
  margin-bottom: 1.5rem;
}

.proposal-item h4 {
  color: var(--accent-victory-green);
  margin-bottom: 0.5rem;
}

.proposal-item p {
  margin: 0;
  line-height: 1.6;
}
</style>
