<template>
  <div class="cookie-preferences">
    <h1>Cookie Preferences</h1>
    <p>
      At Victory AI, we respect your privacy and believe in transparent data practices. We want to inform you about our stance on cookies and online tracking:
    </p>
    <h2>No Cookies Policy</h2>
    <p>
      We do not use any cookies on our website. This means we do not collect or store any information about your browsing behavior on our site.
    </p>
    <h2>No Retargeting Ads</h2>
    <p>
      We do not believe in targeting people who visit our website with retargeting ads. Your visit to our site remains private, and we won't use your information to show you ads on other websites.
    </p>
    <h2>Your Privacy</h2>
    <p>
      We are committed to providing you with information about our services without compromising your privacy. You can browse our website freely without worrying about being tracked or targeted.
    </p>
    <h2>Questions?</h2>
    <p>
      If you have any questions about our cookie preferences or privacy practices, please don't hesitate to <router-link to="/contact">contact us</router-link>.
    </p>
  </div>
</template>

<script>
export default {
  name: 'CookiePreferencesView'
}
</script>

<style scoped>
.cookie-preferences {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h1, h2 {
  color: var(--accent-victory-green);
}

p {
  margin-bottom: 20px;
}
</style>
