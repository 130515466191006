<template>
  <div class="dashboard-content">
    <h1>Dashboard</h1>
    <div class="stats-grid">
      <div class="stat-card">
        <h3>Earnings (Monthly)</h3>
        <p class="stat-value">$40,000</p>
      </div>
      <div class="stat-card">
        <h3>Earnings (Annual)</h3>
        <p class="stat-value">$215,000</p>
      </div>
      <div class="stat-card">
        <h3>Tasks</h3>
        <p class="stat-value">50%</p>
      </div>
      <div class="stat-card">
        <h3>Pending Requests</h3>
        <p class="stat-value">18</p>
      </div>
    </div>
    <div class="charts">
      <div class="chart earnings-overview">
        <h2>Earnings Overview</h2>
        <div class="chart-placeholder">Earnings Chart</div>
      </div>
      <div class="chart revenue-sources">
        <h2>Revenue Sources</h2>
        <div class="chart-placeholder">Revenue Pie Chart</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardContent'
}
</script>

<style scoped>
.dashboard-content {
  padding: 20px;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.stat-card {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.stat-card h3 {
  font-size: 14px;
  color: #4e73df;
  margin-bottom: 10px;
}

.stat-value {
  font-size: 20px;
  font-weight: bold;
}

.charts {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
}

.chart {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.chart h2 {
  font-size: 18px;
  margin-bottom: 15px;
}

.chart-placeholder {
  background-color: #f8f9fc;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #d1d3e2;
  border-radius: 5px;
}
</style>
