import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import './firebase' // Import the Firebase initialization

const app = createApp(App)

// Custom error handler
app.config.errorHandler = (err, vm, info) => {
  // Check if the error is the one we want to ignore
  if (err.message.includes("Cannot read properties of null (reading 'postMessage')") &&
      err.stack.includes('handleScroll')) {
    // Ignore this specific error
    // console.log('Ignored ScoreApp error:', err.message);
    return;
  }
  // Log other errors as usual
  console.error('Global error:', err, info)
}

// Intercept and modify the global error event
window.addEventListener('error', (event) => {
  if (event.error && 
      event.error.message.includes("Cannot read properties of null (reading 'postMessage')") &&
      event.error.stack.includes('handleScroll')) {
    // Prevent the error from being reported to the console
    event.preventDefault();
    // console.log('Prevented ScoreApp error:', event.error.message);
  }
}, true);

// Global cleanup function
window.cleanupScoreApp = () => {
  if (window.handleSAMessage) {
    window.removeEventListener('message', window.handleSAMessage);
  }
  if (window.handleScroll) {
    window.removeEventListener('scroll', window.handleScroll);
  }
  if (window.saInterval) {
    clearInterval(window.saInterval);
  }
  delete window.initSAInline;
  delete window.handleSAMessage;
  delete window.handleScroll;
  delete window.saInterval;
}

// Call cleanup on route change
router.beforeEach((to, from, next) => {
  if (window.cleanupScoreApp) {
    window.cleanupScoreApp();
  }
  next();
})

app.use(store).use(router).mount('#app')
