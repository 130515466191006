<template>
  <div class="money-back-guarantee">
    <h1>100% Money Back Guarantee</h1>
    <div class="guarantee-content">
      <p>At Victory AI, we stand behind the quality of our work and your satisfaction is our top priority. That's why we offer a 100% Money Back Guarantee on our AI strategy audits.</p>
      
      <h2>Our Promise</h2>
      <p>If you are not completely satisfied with our services for any reason, you are eligible for a full refund within 7 days of us releasing the final reports and concluding our audit.</p>
      
      <h2>How It Works</h2>
      <ol>
        <li>We complete your AI strategy audit and provide you with our comprehensive reports and recommendations.</li>
        <li>You have 7 days from the delivery of the final reports to review our work.</li>
        <li>If you're not satisfied, simply reach out to us within this 7-day period to request a refund.</li>
        <li>We will process your refund promptly, returning 100% of your payment minus any Stripe and bank fees.</li>
      </ol>
      
      <h2>Our Commitment</h2>
      <p>This guarantee reflects our confidence in the value we provide and our commitment to your success. We believe that our AI strategy audits will provide significant insights and value to your business, but we want you to feel completely secure in your investment.</p>
      
      <p>If you have any questions about our Money Back Guarantee or any of our services, please don't hesitate to contact us.</p>

      <p>hello@victoryai.io</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MoneyBackGuaranteeView'
}
</script>

<style scoped>
.money-back-guarantee {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

h1, h2 {
  color: var(--accent-victory-green);
}

.guarantee-content {
  margin-top: 2rem;
}

ol {
  margin-left: 1rem;
}

li {
  margin-bottom: 1rem;
}
</style>
