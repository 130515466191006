<template>
  <div class="assessment-content">
    <!-- Show assessment results if they exist -->
    <div v-if="assessmentData && assessmentData.ai_report" class="assessment-details">
      <iframe 
        :src="assessmentData.report_url + '#view=FitH'" 
        class="pdf-viewer"
        type="application/pdf"
      >
      </iframe>
    </div>

    <!-- Show AssessmentEmbed if no assessment exists or no report_url -->
    <div v-else class="assessment-embed-wrapper">
      <AssessmentEmbed 
        :initialFirstName="assessmentData.name"
        :initialEmail="assessmentData.email"
        @navigate="$emit('navigate', $event)"
      />
    </div>
  </div>
</template>

<script>
import AssessmentEmbed from '@/components/AssessmentEmbed.vue'

export default {
  name: 'ExistingBusinessAssessmentResults',
  components: {
    AssessmentEmbed
  },
  props: {
    assessmentData: {
      type: Object,
      required: true
    }
  },
  watch: {
    assessmentData: {
      handler(newData) {
        console.log('AssessmentData updated in ExistingBusinessAssessmentResults:', newData);
        console.log('Has AI Report:', !!newData.ai_report);
        if (newData.ai_report) {
          console.log('Report URL:', newData.report_url);
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.assessment-content {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  height: calc(100vh - 4rem); /* Full height minus padding */
}

.assessment-details {
  height: 100%;
  width: 100%;
}

.pdf-viewer {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.assessment-embed-wrapper {
  justify-content: left;
  align-items: left;
  width: 100%;
  background: var(--primary-background-cream);
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* You can add additional positioning classes as needed */
.assessment-embed-wrapper :deep(.assessment-embed) {
  width: 100%;
  height: 100%;
  margin-right: 500px;
}
</style>
