<template>
  <div class="project-progress">
    <div class="image-container">
      <transition-group name="slide">
        <img 
          v-for="(slide, index) in slides" 
          :key="index"
          :src="slide.image" 
          :alt="slide.title"
          v-show="index === activeDot"
          class="carousel-image"
        >
      </transition-group>
    </div>
    <h2>{{ slides[activeDot].title }}</h2>
    <p>{{ slides[activeDot].content }}</p>
    <div class="progress-dots">
      <span v-for="(dot, index) in slides.length" 
            :key="index" 
            :class="['dot', { active: index === activeDot }]"
            @click="setActiveDot(index)"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthCarousel',
  data() {
    return {
      activeDot: 0,
      intervalId: null,
      slides: [
        {
          image: 'https://res.cloudinary.com/dhkjot9fq/image/upload/v1731683478/slide1_oazx5s.png',
          title: '100% Gaurenteed Satisfaction',
          content: 'If you are unhappy with the discovery process for any reason, we will refund your investment. No questions asked.'
        },
        {
          image: 'https://res.cloudinary.com/dhkjot9fq/image/upload/v1731683478/slide2_wg3eu7.png',
          title: 'Future Proofed Results',
          content: 'Let us dive deep into your business and get it right the first time.'
        },
        {
          image: 'https://res.cloudinary.com/dhkjot9fq/image/upload/v1731683478/slide3_fgvtlm.png',
          title: 'Freedom to Choose',
          content: 'Use the plan internally or let us implement it for you.'
        }
      ]
    }
  },
  methods: {
    setActiveDot(index) {
      this.stopAutoSlide();
      this.activeDot = index;
      this.intervalId = setInterval(() => {
        this.activeDot = (this.activeDot + 1) % this.slides.length;
        this.stopAutoSlide();
        this.startAutoSlide();
      }, 10000);
    },
    startAutoSlide() {
      this.intervalId = setInterval(() => {
        this.activeDot = (this.activeDot + 1) % this.slides.length;
      }, 5000);
    },
    stopAutoSlide() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    }
  },
  mounted() {
    this.startAutoSlide();
  },
  beforeUnmount() {
    this.stopAutoSlide();
  }
}
</script>

<style scoped>
.project-progress {
  background-color: #F5F7FF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* padding: 40px; */
  padding-right: 40px;
  padding-left: 40px;
  height: 100%;
}

.image-container {
  width: 100%;
  height: 250px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.carousel-image {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
}

.project-progress h2 {
  font-size: 20px;
  margin-bottom: 10px;
  color: var(--accent-victory-green);
}

.project-progress p {
  font-size: 14px;
  color: var(--main-text-color);
  margin-bottom: 20px;
}

.progress-dots {
  display: flex;
  justify-content: center;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #D0D0D0;
  margin: 0 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.dot:hover {
  transform: scale(1.2);
}

.dot.active {
  background-color: var(--accent-victory-green);
  transform: scale(1.2);
}

/* Slide transition effects */
.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s ease-in-out;
  position: absolute;
  width: 100%;
  height: 100%;
}

.slide-enter-from {
  transform: translateX(100%);
  opacity: 0;
}

.slide-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}

.slide-enter-to,
.slide-leave-from {
  transform: translateX(0);
  opacity: 1;
}

@media (max-width: 768px) {
  .project-progress {
    padding: 10px;
    padding-left: 0;
    padding-right: 0;
    height: auto;
    min-height: 150px;
  }

  .image-container {
    height: 100px;
    margin-bottom: 10px;
    padding: 0;
  }

  .carousel-image {
    max-height: 100px;
    object-fit: contain;
    padding: 0;
  }

  .project-progress h2 {
    font-size: 14px;
    margin-bottom: 5px;
    padding: 0 10px;
  }

  .project-progress p {
    font-size: 12px;
    margin-bottom: 10px;
    padding: 0 10px;
    line-height: 1.3;
  }

  .progress-dots {
    margin-bottom: 5px;
  }

  .dot {
    width: 5px;
    height: 5px;
    margin: 0 3px;
  }

  /* Adjust slide transitions for mobile */
  .slide-enter-active,
  .slide-leave-active {
    transition: all 0.4s ease-in-out;
  }

  .slide-enter-from {
    transform: translateX(50%);
    opacity: 0;
  }

  .slide-leave-to {
    transform: translateX(-50%);
    opacity: 0;
  }
}
</style> 