<template>
  <section class="plan">
    <div class="plan-content">
      <div class="left-column">
        <h2 class="headline">A Winning Strategy</h2>
        <p class="description">
          After fully understanding your business we'll create a plan that includes:
        </p>
        <ul class="plan-list">
          <li>A list of all proposed projects</li>
          <li>Why we recommend each project</li>
          <li>General estimates for each project</li>
        </ul>
        <p class="guarantee">
          If you're unhappy with the plan for any reason you'll receive a 100% money back guarantee with no questions asked.
        </p>
      </div>
      <div class="right-column">
        <img src="https://res.cloudinary.com/dhkjot9fq/image/upload/v1731684120/plan-image_zmg5xe.jpg" alt="Winning Strategy" class="plan-image">
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Plan',
  methods: {
    setActive(active) {
      // You can add any specific behavior here if needed
    }
  }
}
</script>

<style scoped>
.plan {
  scroll-snap-align: start;
  height: 100vh;
  overflow: hidden;
}

.plan-content {
  display: flex;
  height: 100%;
}

.left-column {
  flex: 0 50%;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--primary-background-cream);
}

.right-column {
  flex: 0 0 50%;
  overflow: hidden;
}

.headline {
  font-size: 3.5rem;
  color: var(--accent-victory-green);
  margin-bottom: 2rem;
  font-weight: bold;
}

.description {
  font-size: 1.3rem;
  color: var(--main-text-color);
  margin-bottom: 2rem;
  line-height: 1.6;
}

.plan-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 2.5rem;
}

.plan-list li {
  font-size: 1.2rem;
  color: var(--main-text-color);
  margin-bottom: 1rem;
  padding-left: 2rem;
  position: relative;
  line-height: 1.5;
}

.plan-list li::before {
  content: '✓';
  color: var(--accent-victory-green);
  position: absolute;
  left: 0;
  top: 0;
  font-weight: bold;
  font-size: 1.4rem;
}

.guarantee {
  font-size: 1.2rem;
  color: var(--accent-victory-green);
  font-style: italic;
  font-weight: 500;
  line-height: 1.6;
  border-top: 2px solid var(--accent-sunny-yellow);
  padding-top: 1.5rem;
}

.plan-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .plan {
    min-height: 100vh;
    display: flex;
    align-items: flex-start;
    padding-top: 100px;
  }

  .plan-content {
    flex-direction: column;
    height: auto;
    padding: 0;
  }

  .left-column {
    flex: none;
    padding: 1rem;
    order: 2;
  }

  .right-column {
    flex: none;
    height: 20vh;
    order: 1;
  }

  .headline {
    font-size: 2rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  .description {
    font-size: 0.9rem;
    text-align: center;
    margin-bottom: 1rem;
  }

  .plan-list {
    margin: 1rem 0;
  }

  .plan-list li {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
    padding-left: 1.5rem;
  }

  .plan-list li::before {
    font-size: 1.1rem;
  }

  .guarantee {
    font-size: 0.9rem;
    margin-top: 1rem;
    padding-top: 1rem;
  }
}
</style>
