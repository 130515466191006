<template>
  <div class="accessibility-statement">
    <h1>Accessibility Statement</h1>
    <p>
      Victory AI is committed to ensuring digital accessibility for people with disabilities. We are continually improving the user experience for everyone, and applying the relevant accessibility standards.
    </p>
    <h2>Measures to support accessibility</h2>
    <p>
      Victory AI takes the following measures to ensure accessibility of our website:
    </p>
    <ul>
      <li>Include accessibility as part of our mission statement.</li>
      <li>Integrate accessibility into our procurement practices.</li>
      <li>Provide continual accessibility training for our staff.</li>
      <li>Assign clear accessibility targets and responsibilities.</li>
      <li>Employ formal accessibility quality assurance methods.</li>
    </ul>
    <h2>Conformance status</h2>
    <p>
      The Web Content Accessibility Guidelines (WCAG) defines requirements for designers and developers to improve accessibility for people with disabilities. It defines three levels of conformance: Level A, Level AA, and Level AAA. Victory AI is partially conformant with WCAG 2.1 level AA. Partially conformant means that some parts of the content do not fully conform to the accessibility standard.
    </p>
    <h2>Feedback</h2>
    <p>
      We welcome your feedback on the accessibility of Victory AI. Please let us know if you encounter accessibility barriers on Victory AI:
    </p>
    <ul>
      <li>E-mail: hello@victoryai.io</li>
    </ul>
    <p>
      We try to respond to feedback within 5 business days.
    </p>
  </div>
</template>

<script>
export default {
  name: 'AccessibilityStatementView'
}
</script>

<style scoped>
.accessibility-statement {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h1, h2 {
  color: var(--accent-victory-green);
}

ul {
  margin-bottom: 20px;
}
</style>
