<template>
  <div class="privacy-policy">
    <h1>Privacy Policy</h1>
    <p>
      At Victory AI, we are committed to protecting your privacy. This Privacy Policy explains our practices regarding the collection, use, and disclosure of your information.
    </p>
    <h2>Data Collection and Usage</h2>
    <p>
      We do not collect, store, or track any personal data from visitors to our website. Our website is designed to provide information about our services without requiring any personal information from you.
    </p>
    <h2>Cookies and Tracking</h2>
    <p>
      We do not use cookies or any other tracking technologies on our website.
    </p>
    <h2>Third-Party Services</h2>
    <p>
      We do not integrate any third-party services that might collect data on our behalf.
    </p>
    <h2>Changes to This Policy</h2>
    <p>
      We may update this privacy policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
    </p>
    <h2>Contact Us</h2>
    <p>
      If you have any questions about this Privacy Policy, please contact us at hello@victoryai.io.
    </p>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicyView'
}
</script>

<style scoped>
.privacy-policy {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h1, h2 {
  color: var(--accent-victory-green);
}

p {
  margin-bottom: 20px;
}
</style>
