<template>
  <div class="dashboard">
    <div class="sidebar">
      <div class="logo">
        <h1>Victory AI</h1>
      </div>
      <nav>
        <a href="#" @click.prevent="currentView = 'DashboardContent'" :class="{ active: currentView === 'DashboardContent' }">Dashboard</a>
        <a href="#" @click.prevent="currentView = 'LeadsContent'" :class="{ active: currentView === 'LeadsContent' }">Leads</a>
        <a href="#" @click.prevent="currentView = 'ContactContent'" :class="{ active: currentView === 'ContactContent' }">Contact</a>
      </nav>
      <div class="sidebar-footer">
        <h3 class="api-endpoint-title">API Endpoint</h3>
        <div class="text-box-container">
          <input type="text" readonly :value="apiEndpoint" class="text-box">
          <button @click="copyText" class="copy-button">
            <i class="fas fa-copy"></i>
          </button>
        </div>
        <button @click="logout" class="logout-button">Logout</button>
      </div>
    </div>
    <div class="main-content">
      <header>
        <div class="header-content">
          <div class="search-bar">
            <input type="text" placeholder="Search..." />
            <button><i class="fas fa-search"></i></button>
          </div>
          <div class="user-info">
            <span class="notifications"><i class="fas fa-bell"></i></span>
            <img src="@/assets/avatar.png" alt="User Avatar" class="avatar" />
            <span class="user-name">{{ userName }}</span>
          </div>
        </div>
      </header>
      <div class="dashboard-content">
        <component :is="currentView"></component>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import DashboardContent from '@/components/DashboardContent.vue'
import LeadsContent from '@/components/LeadsContent.vue'
import ContactContent from '@/components/ContactContent.vue'

export default {
  name: 'DashboardView',
  components: {
    DashboardContent,
    LeadsContent,
    ContactContent
  },
  data() {
    return {
      currentView: 'DashboardContent',
      apiEndpoint: 'https://placeholder.com/test'
    }
  },
  computed: {
    ...mapState({
      userName: state => state.user.name
    })
  },
  methods: {
    ...mapActions(['clearUserData']),
    copyText() {
      const textBox = this.$el.querySelector('.text-box');
      textBox.select();
      document.execCommand('copy');
      alert('API Endpoint copied to clipboard!');
    },
    async logout() {
      try {
        // Clear user data from the store
        await this.clearUserData();
        
        // Redirect to the homepage
        this.$router.push('/');
      } catch (error) {
        console.error('Logout failed:', error);
        // Handle any logout errors here (e.g., show an error message to the user)
      }
    }
  }
}
</script>

<style scoped>
.dashboard {
  display: flex;
  height: 100vh;
}

.sidebar {
  width: 250px;
  background-color: #4e73df;
  color: white;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo h1 {
  font-size: 24px;
  margin-bottom: 60px;
  color: white;
  text-align: center;
}

.sidebar nav {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  margin-top: 60px;
}

.sidebar nav a {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  text-align: left;
}

.sidebar nav a.active,
.sidebar nav a:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}

.main-content {
  flex-grow: 1;
  margin-left: 250px;
  background-color: #f8f9fc;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

header {
  background-color: white;
  padding: 15px 30px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.search-bar {
  display: flex;
  width: 400px;
  background-color: #f8f9fc;
  border-radius: 20px;
  margin-left: 20px;
  overflow: hidden;
  border: 1px solid #e3e6f0;
}

.search-bar input {
  flex-grow: 1;
  padding: 8px 15px;
  border: none;
  background-color: transparent;
  font-size: 14px;
}

.search-bar button {
  background-color: #4e73df;
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 0 20px 20px 0;
}

.user-info {
  display: flex;
  align-items: center;
}

.notifications {
  margin-right: 15px;
  font-size: 18px;
  color: #d1d3e2;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-name {
  color: #5a5c69;
  font-weight: 500;
}

.dashboard-content {
  padding: 30px;
  flex-grow: 1;
  overflow-y: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.dashboard-content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.dashboard-content {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.sidebar-footer {
  margin-top: auto;
  width: 100%;
}

.api-endpoint-title {
  color: white;
  font-size: 16px;
  margin-bottom: 10px;
  text-align: left;
}

.text-box-container {
  display: flex;
  margin-bottom: 10px;
}

.text-box {
  flex-grow: 1;
  padding: 8px;
  border: none;
  border-radius: 4px 0 0 4px;
  font-size: 12px;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}

.copy-button {
  padding: 8px 12px;
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  color: white;
}

.logout-button {
  width: 100%;
  padding: 10px;
  background-color: #e74a3b;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

.logout-button:hover {
  background-color: #d52a1a;
}
</style>
