<template>
  <div class="mobile-login-placeholder">
    <div class="message">
      <p>Mobile Support is Coming</p>
      <p>Please Login on Desktop in the Meantime</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileLoginPlaceholder'
}
</script>

<style scoped>
.mobile-login-placeholder {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: var(--primary-background-cream);
  padding: 20px;
  padding-top: 30vh;
}

.message {
  text-align: center;
}

.message p {
  margin: 10px 0;
  color: var(--accent-victory-green);
  font-size: 1.2rem;
  font-weight: 500;
}

.message p:first-child {
  font-size: 1.4rem;
  font-weight: 600;
}
</style> 