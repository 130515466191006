<template>
  <section class="authority">
    <div class="component-container">
      <div class="floating-arrow" @click="handleArrowClick">›</div>
      <div class="folder-container">
        <transition-group :name="transitionName">
          <div v-for="index in activeColumnIndex + 1" :key="index"
               class="folder-content"
               :style="{ zIndex: index, left: `${(index - 1) * 2.5}%` }">
            <div class="color-column" 
                 :style="{ backgroundColor: getFolderColor(index - 1).bg }"
                 @click="handleColumnClick(index - 1)">
              <span class="sideways-text">{{ folderData[index - 1].columnText }}</span>
            </div>
            <div class="content-column" :style="{ backgroundColor: getFolderColor(index - 1).bg }">
              <div class="left-subcolumn">
                <div class="image-container">
                  <img :src="folderData[index - 1].image" :alt="`${folderData[index - 1].title} Image`" class="dynamic-image">
                </div>
                <div class="dots-container">
                  <span v-for="dotIndex in folderColors.length" :key="dotIndex"
                        class="dot"
                        :style="{ 
                          backgroundColor: dotIndex <= activeColumnIndex + 1 ? getFolderColor(index - 1).dot : 'transparent',
                          border: `2px solid ${getFolderColor(index - 1).dot}`
                        }"
                        :class="{ 'active': dotIndex <= activeColumnIndex + 1 }"
                        @click="handleDotClick(dotIndex - 1)">
                  </span>
                </div>
              </div>
              <div class="right-subcolumn">
                <h2 :style="{ color: getTextColor(index - 1) }">{{ folderData[index - 1].title }}</h2>
                <p :style="{ color: getTextColor(index - 1) }">{{ folderData[index - 1].content }}</p>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'AuthoritySection',
  data() {
    return {
      isForward: true,
      isAnimating: false,
      folderColors: [
        {
          bg: 'var(--accent-victory-green)',
          text: 'var(--primary-background-cream)',
          dot: 'var(--accent-sunny-yellow)'
        },
        {
          bg: 'var(--accent-sunny-yellow)',
          text: 'var(--accent-victory-green)',
          dot: 'var(--secondary-background-sky-blue)'
        },
        {
          bg: 'var(--secondary-background-sky-blue)',
          text: 'var(--accent-victory-green)',
          dot: 'var(--accent-sunny-yellow)'
        },
        {
          bg: 'var(--primary-background-cream)',
          text: 'var(--main-text-color)',
          dot: 'var(--secondary-background-sky-blue)'
        }
      ],
      folderData: [
        {
          columnText: 'Better',
          title: 'Better',
          content: 'Our AI solutions are designed to make your business better in every way. We focus on improving efficiency, accuracy, and overall performance to give you a competitive edge.',
          image: 'https://res.cloudinary.com/dhkjot9fq/image/upload/v1731683476/better_djzyqi.jpg'
        },
        {
          columnText: 'Faster',
          title: 'Faster',
          content: 'Speed is crucial in today\'s fast-paced business environment. Our AI implementations accelerate your processes, decision-making, and time-to-market, helping you stay ahead of the competition.',
          image: 'https://res.cloudinary.com/dhkjot9fq/image/upload/v1731683476/faster_hxwna0.png'
        },
        {
          columnText: 'Safer',
          title: 'Safer',
          content: 'Security is paramount in the digital age. Our AI solutions incorporate advanced safety measures to protect your data, systems, and reputation, ensuring peace of mind for you and your customers.',
          image: 'https://res.cloudinary.com/dhkjot9fq/image/upload/v1731683477/safer_k4acgl.png'
        },
        {
          columnText: 'Cheaper',
          title: 'Cheaper',
          content: 'Implementing AI doesn\'t have to break the bank. Our cost-effective solutions provide excellent value for money, reducing operational costs and improving your bottom line in the long run.',
          image: 'https://res.cloudinary.com/dhkjot9fq/image/upload/v1731683476/cheaper_xcgnju.png'
        }
      ],
      isScrolling: false,
      scrollTimeout: null,
      scrollThreshold: 50,
      accumulatedScroll: 0,
      totalSlides: 4, // Set this to the total number of slides
      isActive: false,
      isCompleted: false,
      isOnFinalSlide: false,
      lastViewedIndex: 0,
      isOnFirstSlide: false,
      touchStartX: 0,
      touchEndX: 0,
      isSwiping: false,
    }
  },
  computed: {
    ...mapState(['activeColumnIndex']),
    isLastColumn() {
      return this.activeColumnIndex === this.folderColors.length - 1
    },
    transitionName() {
      return this.isForward ? 'folder-forward' : 'folder-backward'
    }
  },
  mounted() {
    this.$el.addEventListener('wheel', this.handleWheel, { passive: false })
    
    // Add touch event listeners for mobile
    if (window.innerWidth <= 768) {
      this.$el.addEventListener('touchstart', this.handleTouchStart, { passive: true })
      this.$el.addEventListener('touchmove', this.handleTouchMove, { passive: true })
      this.$el.addEventListener('touchend', this.handleTouchEnd, { passive: true })
    }
  },
  beforeUnmount() {
    this.$el.removeEventListener('wheel', this.handleWheel)
    
    // Clean up touch event listeners
    if (window.innerWidth <= 768) {
      this.$el.removeEventListener('touchstart', this.handleTouchStart)
      this.$el.removeEventListener('touchmove', this.handleTouchMove)
      this.$el.removeEventListener('touchend', this.handleTouchEnd)
    }
  },
  methods: {
    ...mapActions(['nextColumn', 'setActiveColumn']),
    handleArrowClick() {
      if (!this.isLastColumn && !this.isAnimating) {
        this.navigateForward(this.activeColumnIndex + 1)
      } else if (this.isLastColumn) {
        this.$emit('navigate-next')
      }
    },
    handleColumnClick(index) {
      if (!this.isAnimating) {
        if (index > this.activeColumnIndex) {
          this.navigateForward(index)
        } else if (index < this.activeColumnIndex) {
          this.navigateBackward(index)
        }
      }
    },
    handleDotClick(index) {
      if (index !== this.activeColumnIndex && !this.isAnimating) {
        if (index > this.activeColumnIndex) {
          this.navigateForward(index)
        } else {
          this.navigateBackward(index)
        }
      }
    },
    navigateForward(targetIndex) {
      this.isAnimating = true
      this.isForward = true
      const addFolder = () => {
        if (this.activeColumnIndex < targetIndex && this.activeColumnIndex < this.folderColors.length - 1) {
          this.nextColumn().then(() => {
            setTimeout(() => {
              addFolder()
            }, 500) // Adjust this value to match your transition duration
          })
        } else {
          this.isAnimating = false
        }
      }
      addFolder()
    },
    navigateBackward(targetIndex) {
      this.isAnimating = true
      this.isForward = false
      const removeFolder = () => {
        if (this.activeColumnIndex > targetIndex) {
          this.setActiveColumn(this.activeColumnIndex - 1).then(() => {
            setTimeout(() => {
              removeFolder()
            }, 500) // Adjust this value to match your transition duration
          })
        } else {
          this.isAnimating = false
        }
      }
      removeFolder()
    },
    getFolderColor(index) {
      return this.folderColors[index % this.folderColors.length]
    },
    getTextColor(index) {
      const color = this.getFolderColor(index)
      return index === this.folderColors.length - 1 ? 'var(--main-text-color)' : color.text
    },

    handleWheel(event) {
      event.preventDefault();
      
      this.accumulatedScroll += event.deltaY;

      if (Math.abs(this.accumulatedScroll) >= this.scrollThreshold) {
        const direction = this.accumulatedScroll > 0 ? 1 : -1;
        
        if (
          (this.activeColumnIndex === 0 && direction < 0) ||
          (this.activeColumnIndex === this.folderColors.length - 1 && direction > 0)
        ) {
          // Allow scrolling to the previous/next section only at the edges
          this.$emit('section-scroll', direction);
        } else {
          // Otherwise, scroll within the folder
          this.scrollFolder(direction);
        }
        
        this.accumulatedScroll = 0;
      }

      clearTimeout(this.scrollTimeout);
      this.scrollTimeout = setTimeout(() => {
        this.accumulatedScroll = 0;
      }, 200);
    },

    scrollFolder(direction) {
      if (this.isScrolling) return;

      const targetIndex = this.activeColumnIndex + direction;

      if (targetIndex >= 0 && targetIndex < this.folderColors.length) {
        this.isScrolling = true;
        if (direction > 0) {
          this.navigateForward(targetIndex);
        } else {
          this.navigateBackward(targetIndex);
        }
        setTimeout(() => {
          this.isScrolling = false;
        }, 500);
      }
    },

    setActive(active) {
      this.isActive = active;
      if (active) {
        if (this.lastViewedIndex === this.folderColors.length - 1) {
          this.isOnFinalSlide = true;
        } else if (this.lastViewedIndex === 0) {
          this.isOnFirstSlide = false;
        }
        this.setActiveColumn(this.lastViewedIndex);
      } else {
        this.lastViewedIndex = this.activeColumnIndex;
      }
    },

    handleScroll(event) {
      if (!this.isActive) return false;

      const direction = event.deltaY > 0 ? 1 : -1;
      
      if (direction > 0) {
        if (this.activeColumnIndex < this.folderColors.length - 1) {
          this.scrollFolder(direction);
          this.isOnFirstSlide = false;
          return true;
        } else if (!this.isOnFinalSlide) {
          this.isOnFinalSlide = true;
          return true;
        } else {
          this.$emit('authority-completed');
          return false;
        }
      } else if (direction < 0) {
        if (this.isOnFinalSlide) {
          this.isOnFinalSlide = false;
          return true;
        } else if (this.activeColumnIndex > 0) {
          this.scrollFolder(direction);
          return true;
        } else if (!this.isOnFirstSlide) {
          this.isOnFirstSlide = true;
          return true;
        } else {
          this.$emit('authority-scroll-top');
          return false;
        }
      }

      return true;
    },

    handleTouchStart(event) {
      if (window.innerWidth <= 768) {
        this.touchStartX = event.touches[0].clientX;
        this.touchStartY = event.touches[0].pageY;
        this.isSwiping = true;

        // Calculate horizontal and vertical movement
        const deltaX = Math.abs(this.touchEndX - this.touchStartX);
        const deltaY = Math.abs(this.touchEndY - this.touchStartY);

        // If it's more horizontal than vertical, prevent default
        if (deltaX > deltaY) {
          event.preventDefault();
          event.stopPropagation();
        }
      }
    },

    handleTouchMove(event) {
      if (this.isSwiping) {
        this.touchEndX = event.touches[0].clientX;
        this.touchEndY = event.touches[0].pageY;

        // Calculate horizontal and vertical movement
        const deltaX = Math.abs(this.touchEndX - this.touchStartX);
        const deltaY = Math.abs(this.touchEndY - this.touchStartY);

        // If it's more horizontal than vertical, prevent default
        if (deltaX > deltaY) {
          event.preventDefault();
          event.stopPropagation();
        }
      }
    },

    handleTouchEnd(event) {
      if (!this.isSwiping) return;

      const swipeDistance = this.touchStartX - this.touchEndX;
      const minSwipeDistance = 50;

      // Calculate horizontal and vertical movement
      const deltaX = Math.abs(this.touchEndX - this.touchStartX);
      const deltaY = Math.abs(this.touchEndY - this.touchStartY);

      // Only handle as swipe if horizontal movement is greater than vertical
      if (deltaX > deltaY && Math.abs(swipeDistance) > minSwipeDistance) {
        event.preventDefault();
        event.stopPropagation();
        
        if (swipeDistance > 0) {
          // Swiped left - go forward
          if (!this.isLastColumn) {
            this.navigateForward(this.activeColumnIndex + 1);
          }
        } else {
          // Swiped right - go backward
          if (this.activeColumnIndex > 0) {
            this.navigateBackward(this.activeColumnIndex - 1);
          }
        }
      }

      this.isSwiping = false;
      this.touchStartX = 0;
      this.touchEndX = 0;
      this.touchStartY = 0;
      this.touchEndY = 0;
    }
  }
}
</script>

<style scoped>
.authority {
  scroll-snap-align: start;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-background-cream);
  padding: 40px;
  box-sizing: border-box;
}

.component-container {
  width: 95%;
  height: 80vh;
  background-color: white;
  border-radius: 0 30px 30px 0;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15), 0 10px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  margin-top: 100px;
}

.folder-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.folder-content {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  transition: all 0.5s ease;
}

.color-column {
  width: 2.5%;
  cursor: pointer;
  transition: opacity 0.3s ease;
  position: relative;
  overflow: hidden;
}

.color-column:hover {
  opacity: 0.8;
}

.content-column {
  flex-grow: 1;
  display: flex;
  padding: 30px;
  align-items: center;
  border-radius: 0 20px 20px 0;
  overflow: hidden;
}

.left-subcolumn {
  flex: 0 0 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
}

.image-container {
  width: 100%;
  max-width: 100%;
  height: 0;
  padding-bottom: 75%; /* This maintains a 4:3 aspect ratio */
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  background-color: white; /* Add a background color to see the padding */
}

.dynamic-image {
  position: absolute;
  top: 5%; /* Add 5% padding to top and bottom */
  left: 5%; /* Add 5% padding to left and right */
  width: 90%; /* Reduce width to 90% to account for padding */
  height: 90%; /* Reduce height to 90% to account for padding */
  object-fit: contain; /* Change from 'cover' to 'contain' to ensure the entire image is visible */
  border-radius: 10px; /* Add a slight border radius to the image itself */
}

.dots-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 0 8px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.dot:hover {
  transform: scale(1.1);
}

.dot.active {
  transform: scale(1.2);
}

.right-subcolumn {
  flex: 0 0 35%;
  padding: 20px 20px 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

p {
  font-size: 1.2rem;
  line-height: 1.6;
  max-height: 50vh;
  overflow-y: auto;
}

.floating-arrow {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 3rem;
  color: var(--accent-victory-green);
  cursor: pointer;
  transition: all 0.3s ease;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  line-height: 1;
  z-index: 1000;
}

.floating-arrow:hover {
  transform: scale(1.1);
  background-color: var(--accent-victory-green);
  color: white;
}

.folder-forward-enter-active,
.folder-forward-leave-active,
.folder-backward-enter-active,
.folder-backward-leave-active {
  transition: all 0.5s ease;
}

.folder-forward-enter-from {
  transform: translateX(100%);
}

.folder-forward-leave-to {
  transform: translateX(-100%);
}

.folder-backward-enter-from {
  transform: translateX(-100%);
}

.folder-backward-leave-to {
  transform: translateX(100%);
}

.folder-forward-enter-to,
.folder-forward-leave-from,
.folder-backward-enter-to,
.folder-backward-leave-from {
  transform: translateX(0);
}

.folder-forward-move,
.folder-backward-move {
  transition: transform 0.5s ease;
}

.sideways-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  white-space: nowrap;
  color: var(--primary-background-cream);
  font-weight: bold;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

@media (max-width: 768px) {
  .authority {
    padding: 1rem;
  }

  .component-container {
    width: 100%;
    height: 90vh;
    margin-top: 0;
    border-radius: 15px;
  }

  .content-column {
    flex-direction: column;
    padding: 1rem;
  }

  .left-subcolumn {
    flex: none;
    width: 100%;
    padding: 0.5rem;
    gap: 10px;
  }

  .right-subcolumn {
    flex: none;
    width: 100%;
    padding: 1rem 0.5rem;
    text-align: center;
  }

  .image-container {
    padding-bottom: 60%; /* Adjust aspect ratio for mobile */
  }

  .dynamic-image {
    top: 2.5%;
    left: 2.5%;
    width: 95%;
    height: 95%;
  }

  h2 {
    font-size: 1.8rem;
    margin-bottom: 0.8rem;
    text-align: center;
  }

  p {
    font-size: 1rem;
    line-height: 1.4;
    text-align: center;
  }

  .dots-container {
    margin: 0.5rem 0;
  }

  .dot {
    width: 10px;
    height: 10px;
    margin: 0 6px;
  }

  .floating-arrow {
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    font-size: 2rem;
  }

  .color-column {
    width: 5%; /* Make the tabs slightly wider on mobile */
  }

  .sideways-text {
    font-size: 0.8rem;
  }

  .folder-content {
    touch-action: none; /* Disable all default touch actions */
  }

  .content-column {
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
  }
}
</style>
