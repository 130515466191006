<template>
  <div class="assessment-container">
    <AssessmentEmbed />
  </div>
</template>

<script>
import AssessmentEmbed from '@/components/AssessmentEmbed.vue'

export default {
  name: 'AssessmentView',
  components: {
    AssessmentEmbed
  }
}
</script>

<style scoped>
.assessment-container {
  height: calc(100vh - 60px); /* Subtract header height if necessary */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}
</style>
