<template>
  <div class="custom-calendar" :class="{ 'date-selected': selectedDate, 'time-selected': selectedTime }">
    <div v-if="isLoading" class="modal-overlay">
      <div class="modal loading-modal">
        <h3>Loading Calendar...</h3>
        <div class="loading-spinner"></div>
      </div>
    </div>
    <div v-if="!selectedTime" class="calendar-container">
      <div class="calendar-header">
        <h2>Select a Date & Time</h2>
      </div>
      <div class="month-selector">
        <button @click="previousMonth">&lt;</button>
        <span>{{ currentMonthYear }}</span>
        <button @click="nextMonth">&gt;</button>
      </div>
      <div class="calendar-grid">
        <div v-for="day in daysOfWeek" :key="day" class="calendar-day-header">{{ day }}</div>
        <div v-for="(day, index) in calendarDays" :key="index" 
             :class="['calendar-day', { 
               'current-month': day.currentMonth, 
               'selectable': day.selectable,
               'today': isToday(day.fullDate),
               'selected': isSelected(day.fullDate)
             }]"
             @click="selectDate(day)">
          {{ day.date }}
        </div>
      </div>
      <div class="time-zone">
        <span>Time zone</span>
        <select v-model="selectedTimeZone">
          <option value="ET">Eastern Time - US & Canada (1:51am)</option>
          <!-- Add more time zone options as needed -->
        </select>
      </div>
    </div>
    <div v-if="selectedDate && !selectedTime" class="time-slots">
      <h3>{{ formatDate(selectedDate) }}</h3>
      <div class="time-slots-container">
        <button v-for="time in timeSlots" :key="time" class="time-slot" @click="selectTimeSlot(time)">
          {{ time }}
        </button>
      </div>
    </div>
    <div v-if="selectedTime" class="booking-section">
      <div class="back-arrow" @click="goBackToCalendar">
        ← Back to Calendar
      </div>
      <div class="booking-content">
        <div class="booking-summary">
          <h3>Your Selected Appointment</h3>
          <p><strong>Date:</strong> {{ formatDate(selectedDate) }}</p>
          <p><strong>Time:</strong> {{ selectedTime }}</p>
        </div>
        <div class="booking-form">
          <h3>Complete Your Booking</h3>
          <form @submit.prevent="submitBooking">
            <div class="form-group">
              <label for="firstName">First Name</label>
              <input type="text" id="firstName" v-model="formData.firstName" required>
            </div>
            <div class="form-group">
              <label for="email">Email</label>
              <input type="email" id="email" v-model="formData.email" required>
            </div>
            <div class="form-group">
              <label>Reason for Call</label>
              <div class="radio-group">
                <label>
                  <input type="radio" v-model="formData.reason" value="Discovery Call" required>
                  Discovery Call
                </label>
                <label>
                  <input type="radio" v-model="formData.reason" value="Partnership" required>
                  Partnership
                </label>
                <label>
                  <input type="radio" v-model="formData.reason" value="Introduction Call" required>
                  Introduction Call
                </label>
                <label>
                  <input type="radio" v-model="formData.reason" value="Other" required>
                  Other
                </label>
              </div>
            </div>
            <div v-if="formData.reason === 'Other'" class="form-group">
              <label for="otherReason">Please specify</label>
              <textarea id="otherReason" v-model="formData.otherReason" required></textarea>
            </div>
            <button type="submit" class="submit-button">Book Appointment</button>
          </form>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div v-if="showModal" class="modal-overlay">
      <div class="modal">
        <h3>{{ modalTitle }}</h3>
        <div v-if="isLoading" class="loading-spinner"></div>
        <p>{{ modalMessage }}</p>
        <div v-if="bookingConfirmed" class="confirmation-actions">
          <p>Login to follow your roadmap:</p>
          <router-link to="/login" class="modal-button">Login</router-link>
        </div>
        <button v-if="!isLoading && !bookingConfirmed" @click="closeModal" class="modal-button">{{ modalButtonText }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import api from '@/services/api'

export default {
  name: 'CustomCalendar',
  data() {
    return {
      currentDate: new Date(),
      selectedDate: null,
      selectedTimeZone: 'ET',
      daysOfWeek: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
      timeSlots: ['1:30pm', '2:00pm', '2:30pm', '3:00pm', '3:30pm', '4:00pm', '4:30pm'],
      selectedTime: null,
      formData: {
        firstName: '',
        email: '',
        reason: 'Discovery Call', // Set default to Discovery Call
        otherReason: ''
      },
      showModal: false,
      modalTitle: '',
      modalMessage: '',
      modalButtonText: 'Close',
      isLoading: false,
      bookingConfirmed: false,
      monthAvailability: {},
    }
  },
  computed: {
    currentMonthYear() {
      return this.currentDate.toLocaleString('default', { month: 'long', year: 'numeric' });
    },
    calendarDays() {
      const year = this.currentDate.getFullYear();
      const month = this.currentDate.getMonth();
      const firstDay = new Date(year, month, 1);
      const lastDay = new Date(year, month + 1, 0);
      const daysInMonth = lastDay.getDate();
      const startingDayOfWeek = firstDay.getDay();

      let days = [];

      // Add days from previous month
      for (let i = startingDayOfWeek - 1; i >= 0; i--) {
        const day = new Date(year, month, -i);
        days.push({
          date: day.getDate(),
          currentMonth: false,
          selectable: false,
          fullDate: day
        });
      }

      // Add days of current month
      for (let i = 1; i <= daysInMonth; i++) {
        const day = new Date(year, month, i);
        days.push({
          date: i,
          currentMonth: true,
          selectable: this.isDateSelectable(day),
          fullDate: day
        });
      }

      // Add days from next month
      const remainingDays = 42 - days.length; // 6 rows * 7 days = 42
      for (let i = 1; i <= remainingDays; i++) {
        const day = new Date(year, month + 1, i);
        days.push({
          date: i,
          currentMonth: false,
          selectable: false,
          fullDate: day
        });
      }

    //   console.log('Generated calendar days:', days);

      // Update the selectable property based on monthAvailability
      const updatedDays = days.map(day => {
        const isSelectable = this.isDateSelectable(day.fullDate);
        // console.log(`Day ${day.fullDate.toISOString().split('T')[0]} selectable:`, isSelectable, 'Full day object:', JSON.stringify(day));
        return {
          ...day,
          selectable: isSelectable
        };
      });

    //   console.log('Final updated calendar days:', JSON.stringify(updatedDays));

      return updatedDays;
    },
    ...mapState(['firstName', 'email'])
  },
  methods: {
    previousMonth() {
      this.currentDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() - 1, 1);
      this.fetchMonthAvailability(this.currentDate);
    },
    nextMonth() {
      this.currentDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 1);
      this.fetchMonthAvailability(this.currentDate);
    },
    selectDate(day) {
      if (day.selectable) {
        this.selectedDate = day.fullDate;
        this.fetchAvailableTimes(day.fullDate);
        this.$emit('date-selected', this.selectedDate);
      }
    },
    selectTimeSlot(time) {
      this.selectedTime = time;
      this.formData.firstName = this.firstName;
      this.formData.email = this.email;
    },
    isDateSelectable(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const isWeekday = date.getDay() !== 0 && date.getDay() !== 6;
      const isFutureOrToday = date >= today;
      const isAvailable = this.isDateAvailable(date);
      return isWeekday && isFutureOrToday && isAvailable;
    },
    isToday(date) {
      const today = new Date();
      return date.getDate() === today.getDate() &&
             date.getMonth() === today.getMonth() &&
             date.getFullYear() === today.getFullYear();
    },
    isSelected(date) {
      return this.selectedDate && 
             date.getDate() === this.selectedDate.getDate() &&
             date.getMonth() === this.selectedDate.getMonth() &&
             date.getFullYear() === this.selectedDate.getFullYear();
    },
    formatDate(date) {
      return date.toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' });
    },
    async fetchAvailableTimes(date) {
      try {
        this.timeSlots = await api.getAvailableTimes(date);
      } catch (error) {
        console.error('Error fetching available times:', error);
        // Handle error (e.g., show error message to user)
      }
    },
    async submitBooking() {
      this.showModal = true;
      this.isLoading = true;
      this.modalTitle = 'Booking Your Discovery Call';
      this.modalMessage = 'Please wait while we process your booking...';

      try {
        const bookingData = {
          date: this.selectedDate.toISOString().split('T')[0],
          time: this.selectedTime,
          firstName: this.formData.firstName,
          email: this.formData.email,
          reason: this.formData.reason,
          otherReason: this.formData.otherReason
        };
        const response = await api.bookAppointment(bookingData);
        
        // Save email to localStorage using the same key as the login form
        localStorage.setItem('rememberedEmail', this.formData.email);
        
        this.isLoading = false;
        this.bookingConfirmed = true;
        this.modalTitle = 'Booking Confirmed';
        this.modalMessage = `Your appointment has been booked successfully. You will receive a confirmation email with the meeting link.`;
        this.$emit('booking-submitted', response);
      } catch (error) {
        this.isLoading = false;
        if (error.response && error.response.status === 409) {
          this.modalTitle = 'Booking Error';
          this.modalMessage = 'Sorry, this time was booked while you were choosing. Please select a new time.';
          this.modalButtonText = 'Choose New Time';
          this.resetBookingForm();
          await this.fetchAvailableTimes(this.selectedDate);
        } else {
          this.modalTitle = 'Booking Error';
          this.modalMessage = 'An error occurred while booking your appointment. Please try again.';
          this.modalButtonText = 'Close';
        }
      }
    },
    closeModal() {
      this.showModal = false;
      this.bookingConfirmed = false;
      if (this.modalButtonText === 'Choose New Time') {
        this.selectedTime = null;
      }
    },
    resetBookingForm() {
      this.selectedTime = null;
      this.formData = {
        firstName: this.firstName,
        email: this.email,
        reason: 'Discovery Call',
        otherReason: ''
      };
    },
    async fetchMonthAvailability(date) {
      const year = date.getFullYear();
      const month = date.getMonth();

      try {
        const response = await api.getMonthAvailability(year, month);
        
        // Convert the response to the format we expect
        const processedAvailability = {};
        for (const [dateString, isAvailable] of Object.entries(response)) {
          processedAvailability[dateString.split('T')[0]] = isAvailable;
        }
        
        // Merge the new availability data with existing data
        this.monthAvailability = { ...this.monthAvailability, ...processedAvailability };
      } catch (error) {
        console.error(`Error fetching availability for ${year}-${month}:`, error);
        // You might want to show an error message to the user here
      }
    },
    async fetchThreeMonthsAvailability() {
      this.isLoading = true;
      const currentDate = new Date(this.currentDate);
      for (let i = 0; i < 3; i++) {
        await this.fetchMonthAvailability(currentDate);
        currentDate.setMonth(currentDate.getMonth() + 1);
      }
      this.isLoading = false;
    },
    isDateAvailable(date) {
      const dateString = date.toISOString().split('T')[0];
      return this.monthAvailability[dateString] || false;
    },
    goBackToCalendar() {
      this.selectedTime = null;
      this.resetBookingForm();
    },
    async fetchInitialAvailability() {
      this.isLoading = true;
      const currentDate = new Date(this.currentDate);
      
      try {
        // Fetch current month
        await this.fetchMonthAvailability(currentDate);
        
        // Fetch next month
        const nextMonth = new Date(currentDate);
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        await this.fetchMonthAvailability(nextMonth);
      } catch (error) {
        console.error('Error fetching initial availability:', error);
      }
      
      this.isLoading = false;
    },
  },
  mounted() {
    this.fetchInitialAvailability();
  },
  watch: {
    currentDate() {
      // When changing months, only fetch the next month if we don't already have its data
      const nextMonth = new Date(this.currentDate);
      nextMonth.setMonth(nextMonth.getMonth() + 1);
      const nextMonthKey = nextMonth.toISOString().split('T')[0].slice(0, 7); // Get YYYY-MM format
      
      // Check if we already have data for this month
      const hasNextMonthData = Object.keys(this.monthAvailability).some(date => 
        date.startsWith(nextMonthKey)
      );
      
      if (!hasNextMonthData) {
        this.fetchMonthAvailability(nextMonth);
      }
    },
    monthAvailability: {
      handler() {
        this.$forceUpdate();
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.custom-calendar {
  font-family: 'Poppins', sans-serif;
  width: 100%;
  max-width: 600px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 27px;
  display: flex;
  transition: max-width 0.3s ease;
}

.custom-calendar.date-selected {
  max-width: 900px;
}

.custom-calendar.time-selected {
  max-width: 900px;
}

.calendar-container {
  width: 100%;
  transition: width 0.3s ease;
}

.custom-calendar.date-selected .calendar-container {
  width: 60%;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
}

.calendar-header h2 {
  font-size: 22px;
  color: #1a1a1a;
  margin: 0;
  font-weight: 600;
}

.month-selector {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
}

.month-selector button {
  background: none;
  border: none;
  font-size: 25px;
  color: #006bff;
  cursor: pointer;
  padding: 0 13px;
  font-family: 'Poppins', sans-serif;
}

.month-selector span {
  font-size: 18px;
  font-weight: 600;
  color: #1a1a1a;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.calendar-day-header {
  text-align: center;
  font-size: 13px;
  color: #888;
  padding: 5px 0;
  font-weight: 500;
}

.calendar-day {
  text-align: center;
  width: 36px;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  color: #1a1a1a;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.calendar-day:not(.current-month) {
  color: #ccc;
}

.calendar-day.selectable {
  background-color: #e6f2ff;
  color: var(--accent-victory-green);
  cursor: pointer;
}

.calendar-day.selectable:hover {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

.calendar-day:not(.selectable) {
  cursor: default;
}

.calendar-day.today {
  background-color: var(--accent-victory-green);
  color: #fff;
}

.calendar-day.selected {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

.calendar-day.today.selected {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

.time-zone {
  margin-top: 27px;
  font-size: 14px;
  color: #1a1a1a;
}

.time-zone select {
  margin-left: 10px;
  padding: 7px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
}

.time-slots {
  width: 35%;
  padding-left: 20px;
  border-left: 1px solid #e0e0e0;
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  flex-direction: column;
}

.custom-calendar.date-selected .time-slots {
  opacity: 1;
}

.time-slots h3 {
  font-size: 18px;
  margin-bottom: 15px;
  color: #1a1a1a;
}

.time-slots-container {
  max-height: 450px; /* Adjust this value as needed */
  overflow-y: auto;
  padding-right: 10px; /* Add some padding for the scrollbar */
}

.time-slot {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #e6f2ff;
  color: var(--accent-victory-green);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  text-align: center;
}

.time-slot:hover {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

/* Add styles for the scrollbar */
.time-slots-container::-webkit-scrollbar {
  width: 6px;
}

.time-slots-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.time-slots-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.time-slots-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.booking-section {
  width: 100%;
  padding: 20px;
  background-color: #fff;
}

.back-arrow {
  cursor: pointer;
  color: var(--accent-victory-green);
  font-weight: bold;
  margin-bottom: 20px;
  transition: color 0.3s ease;
}

.back-arrow:hover {
  color: var(--accent-sunny-yellow);
}

.booking-content {
  display: flex;
  gap: 20px;
}

.booking-summary {
  flex: 1;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.booking-summary h3 {
  color: var(--accent-victory-green);
  margin-bottom: 15px;
}

.booking-summary p {
  margin-bottom: 10px;
}

.booking-form {
  flex: 2;
  padding: 20px;
}

.booking-form h3 {
  color: var(--accent-victory-green);
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: var(--main-text-color);
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.radio-group {
  display: flex;
  flex-direction: column;
}

.radio-group label {
  margin-bottom: 5px;
}

.submit-button {
  background-color: var(--accent-victory-green);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
}

.submit-button:hover {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

@media (max-width: 768px) {
  .booking-content {
    flex-direction: column;
  }

  .booking-summary,
  .booking-form {
    width: 100%;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.modal h3 {
  color: var(--accent-victory-green);
  margin-bottom: 10px;
}

.modal p {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.5;
}

.modal-button {
  background-color: var(--accent-victory-green);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.modal-button:hover {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid var(--accent-victory-green);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.confirmation-actions {
  margin-top: 20px;
}

.confirmation-actions p {
  margin-bottom: 15px;
}

.modal-button {
  background-color: var(--accent-victory-green);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-decoration: none;
  display: inline-block;
}

.modal-button:hover {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

.loading-modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  min-width: 200px;
}

.loading-modal h3 {
  color: var(--accent-victory-green);
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .custom-calendar {
    /* padding: 10px; */
    padding-right: 10px;
    padding-left: 10px;
    max-width: 100%;
    margin: 0;
    box-shadow: none;
    transform: translateX(-10px); /* Move calendar slightly left */
  }

  .calendar-container {
    width: 100%;
    padding: 0;
  }

  .calendar-header {
    margin-bottom: 10px;
    padding: 0 5px;
  }

  .calendar-header h2 {
    font-size: 18px;
  }

  .month-selector {
    margin: 10px 0;
    padding: 0 5px;
  }

  .month-selector span {
    font-size: 16px;
  }

  .calendar-grid {
    gap: 2px;
    margin: 0;
    width: 100%;
    padding: 0 5px;
    justify-content: center;
  }

  .calendar-day {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 13px;
    margin: 0;
  }

  .calendar-day-header {
    font-size: 11px;
    padding: 5px 0;
  }

  .time-zone {
    margin-top: 15px;
    padding: 0 5px;
  }

  .time-zone select {
    width: 100%;
    margin-left: 0;
    margin-top: 5px;
    font-size: 13px;
  }

  /* Adjust time slots for mobile */
  .time-slots {
    width: 100%;
    padding: 15px 20px; /* Increase left/right padding */
    border-left: none;
    border-top: 1px solid #e0e0e0;
    margin-top: 15px;
  }

  .time-slots h3 {
    font-size: 16px;
    padding: 0 10px;
    margin-bottom: 15px;
  }

  .time-slots-container {
    max-height: 300px;
    padding: 0 10px;
    margin-left: 10px; /* Add left margin */
  }

  .time-slot {
    padding: 10px;
    font-size: 13px;
    margin-bottom: 10px;
    width: 95%; /* Slightly less than full width */
    margin-left: auto; /* Center the time slots */
    margin-right: auto;
  }

  /* Modal adjustments for mobile */
  .modal {
    width: 90%;
    margin: 10px;
    padding: 15px;
  }

  .modal h3 {
    font-size: 16px;
  }

  .modal p {
    font-size: 14px;
  }

  /* Booking section adjustments */
  .booking-section {
    padding: 10px 5px;
  }

  .booking-content {
    flex-direction: column;
    padding: 0 5px;
  }

  .booking-summary,
  .booking-form {
    width: 100%;
    padding: 10px 5px;
  }

  .form-group {
    margin-bottom: 10px;
  }

  .form-group label {
    font-size: 14px;
  }

  .form-group input[type="text"],
  .form-group input[type="email"],
  .form-group textarea {
    font-size: 13px;
    padding: 6px;
  }
}

@media (max-width: 768px) {
  /* Booking form specific mobile adjustments */
  .booking-section {
    padding: 10px;
    width: 100%;
    transform: translateX(-10px);
    height: 90vh; /* Limit height to viewport */
    display: flex;
    flex-direction: column;
  }

  .back-arrow {
    font-size: 14px;
    padding: 5px;
    margin-left: 5px;
  }

  .booking-content {
    flex-direction: column;
    gap: 10px;
    padding: 0 5px;
    width: 95%;
    margin: 0 auto;
    height: 100%; /* Take full height */
    display: flex;
  }

  .booking-summary {
    padding: 10px;
    margin: 0 5px;
    border-radius: 6px;
    width: calc(100% - 10px);
    flex: 0 0 auto; /* Don't grow or shrink */
  }

  .booking-summary h3 {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .booking-summary p {
    font-size: 13px;
    margin-bottom: 3px;
  }

  .booking-form {
    padding: 0 5px;
    width: calc(100% - 10px);
    flex: 1; /* Take remaining space */
    display: flex;
    flex-direction: column;
  }

  .booking-form h3 {
    font-size: 14px;
    margin-bottom: 8px;
  }

  .form-group {
    margin-bottom: 8px;
    width: 100%;
  }

  .form-group label {
    font-size: 13px;
    margin-bottom: 2px;
  }

  .form-group input[type="text"],
  .form-group input[type="email"] {
    width: calc(100% - 10px);
    margin: 0 5px;
    padding: 8px;
    font-size: 13px;
  }

  .radio-group {
    padding: 0 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .radio-group label {
    font-size: 13px;
  }

  .submit-button {
    width: calc(100% - 10px);
    margin: 10px 5px 0;
    padding: 10px;
    font-size: 14px;
  }
}
</style>
