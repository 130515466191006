<template>
  <div class="assessment-embed">
    <div class="loading-modal" v-if="isSubmitting">
      <div class="loading-content">
        <div class="loading-spinner"></div>
        <p>Submitting your assessment...</p>
      </div>
    </div>

    <div class="content-wrapper">
      <div class="card-container">
        <transition name="fade" mode="out-in">
          <div class="card" :key="currentCardIndex">
            <template v-if="currentCardIndex === 0">
              <div class="card-content first-card">
                <h2>How Vulnerable is Your Business to AI Disruption?</h2>
                <p>
                  Take our comprehensive AI readiness assessment to better understand your business's
                  current position and potential for AI integration:
                </p>
                <ul>
                  <li>Evaluate your current AI capabilities</li>
                  <li>Identify key areas for AI implementation</li>
                  <li>Receive personalized recommendations</li>
                  <!-- <li>Understand your AI readiness score</li> -->
                </ul>
                <p><i>This assessment will help us tailor our approach to your specific needs and challenges.</i></p>
                <div class="form-group">
                  <label for="firstName">First Name</label>
                  <input type="text" id="firstName" ref="firstNameInput" v-model="firstName" required @keyup.enter="startAssessment">
                  <p v-if="firstNameError" class="error-message">{{ firstNameError }}</p>
                </div>
                <div class="form-group">
                  <label for="email">Email</label>
                  <input type="email" id="email" v-model="email" required @blur="validateEmail" @keyup.enter="startAssessment">
                  <p v-if="emailError" class="error-message">{{ emailError }}</p>
                </div>
                <div class="button-row">
                  <button @click="startAssessment" class="btn-primary">Start Assessment</button>
                </div>
              </div>
            </template>
            <template v-else-if="currentCardIndex === cards.length - 1">
              <div class="card-content results-card">
                <h2>Assessment Submitted Successfully!</h2>
                <div class="completion-message">
                  <p>Thank you for completing the AI Readiness Assessment. We're analyzing your responses and preparing your detailed report.</p>
                  
                  <div class="next-steps">
                    <h3>What's Next?</h3>
                    <ul v-if="!isLoggedIn">
                      <li>We'll email your assessment results shortly</li>
                      <li>Please check your spam folder if you don't receive the email within a few minutes</li>
                      <li>Create an account to access your full report and additional insights</li>
                    </ul>
                    <ul v-else>
                      <li>We'll email your assessment results shortly</li>
                      <li>Please check your spam folder if you don't receive the email within a few minutes</li>
                      <li>Schedule a discovery call to discuss your results in detail</li>
                    </ul>
                  </div>

                  <div class="action-buttons">
                    <template v-if="!isLoggedIn">
                      <router-link to="/login" class="btn-primary">
                        Create Account
                      </router-link>
                      <router-link to="/schedule" class="btn-secondary">
                        Schedule Discovery Call
                      </router-link>
                    </template>
                    <template v-else>
                      <button @click="scheduleDiscoveryCall" class="btn-primary">
                        Schedule Discovery Call
                      </button>
                    </template>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="card-content">
                <div class="card-header">
                  <h2>{{ currentCard.headline }}</h2>
                  <div class="navigation-controls">
                    <button @click="previousCard" class="nav-btn" v-if="currentCardIndex > 1">Back</button>
                    <button @click="skipQuestion" class="nav-btn" v-if="currentCardIndex > 1">Skip</button>
                  </div>
                </div>
                <p class="question">{{ currentCard.question }}</p>
                <div class="choices">
                  <label v-for="(choice, index) in currentCard.choices" 
                         :key="index" 
                         class="choice"
                         :class="{ 'selected': currentChoice === index }">
                    <input 
                      type="radio" 
                      :id="'choice' + index" 
                      :name="'question' + currentCardIndex"
                      :value="index"
                      :checked="currentChoice === index"
                      @change="() => currentChoice = index"
                    >
                    <span class="choice-text">{{ choice }}</span>
                  </label>
                </div>
                <div class="button-row">
                  <button @click="nextCard" class="btn-primary btn-wide" :disabled="currentChoice === null">Next</button>
                </div>
              </div>
            </template>
          </div>
        </transition>
      </div>
      <div class="progress-bar-container">
        <div class="progress-bar">
          <div 
            v-for="(card, index) in cards" 
            :key="index" 
            class="progress-node"
            :class="{ 'active': index <= currentCardIndex }"
            :style="{ top: `${(index / (cards.length - 1)) * 100}%` }"
          >
            <div class="node-content">{{ card.headline }}</div>
          </div>
          <div class="progress" :style="{ height: `${progress}%` }"></div>
        </div>
      </div>

      <!-- Add mobile progress bar -->
      <div class="mobile-progress" v-if="isMobile">
        <div class="mobile-progress-text">
          <span class="current-section">{{ currentCardIndex + 1 }}/{{ cards.length }}</span>
          <h3 class="current-title">{{ currentCard.headline }}</h3>
        </div>
        <div class="mobile-progress-bar-container">
          <div class="mobile-progress-bar" :style="{ width: progress + '%' }"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { sendUserInfo, updateAssessmentResults, completeAssessment } from '@/services/api'

export default {
  name: 'AssessmentEmbed',
  props: {
    initialFirstName: {
      type: String,
      default: ''
    },
    initialEmail: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentCardIndex: 0,
      firstName: this.initialFirstName,  // Initialize with prop
      email: this.initialEmail,         // Initialize with prop
      selectedChoice: null,
      firstNameError: '',
      emailError: '',
      cards: [
        { headline: 'Start' },
        { 
          headline: 'Executive Management', 
          question: 'How is your company using AI for decision-making?', 
          choices: [
            'AI agents plan and adapt to company data in real time and provide on demand consulting.',
            'AI is used for historical data analysis and some forecasting but lacks real-time scenario simulation.',
            'Decisions are based on traditional analytics with minimal AI input.',
            'Decisions are made purely on intuition and manual data review.'
          ]
        },
        { 
          headline: 'HR', 
          question: 'How does your HR department use AI for talent acquisition?', 
          choices: [
            'AI automates the entire recruitment process, from job description creation to candidate screening and matching.',
            'AI assists with candidate screening and skill matching, but other aspects remain manual.',
            'Recruitment is handled manually with basic software tools (e.g., applicant tracking systems).',
            'The recruitment process is entirely manual, with no digital tools used.'
          ]
        },
        { 
          headline: 'Finance', 
          question: 'How does AI assist with financial reporting?', 
          choices: [
            'AI generates real-time financial reports and automatically performs audits, with predictive analytics for future trends.',
            'AI aids in financial forecasting and generates some reports, but audits and final reviews remain manual.',
            'Financial reporting is done using traditional accounting software, with minimal AI assistance.',
            'Financial reporting is done manually, relying on spreadsheets.'
          ]
        },
        { 
          headline: 'Operations', 
          question: 'How does your company use AI to optimize costs and financial efficiency?', 
          choices: [
            'AI continuously analyzes spending patterns, automates cost-saving decisions, and provides real-time recommendations for budget optimization across all departments.',
            'AI helps identify cost-saving opportunities and monitors spending trends, but cost management decisions still require significant manual oversight.',
            'Cost management is handled manually with basic digital tools for expense tracking and reporting.',
            'Cost management relies on outdated methods with no systematic analysis or digital tools.'
          ]
        },
        { 
          headline: 'Marketing', 
          question: 'How is AI used to personalize marketing campaigns?', 
          choices: [
            'AI generates highly personalized content for each customer based on real-time data and predicts customer needs.',
            'AI segments customers and personalizes content, but lacks real-time updates and full automation.',
            'Marketing is segmented manually, with basic software tools for campaign management.',
            'All marketing campaigns are manually created and sent, with no automation or personalization.'
          ]
        },
        { 
          headline: 'Sales', 
          question: 'How does AI improve sales conversion rates through interaction analysis?', 
          choices: [
            'AI continuously analyzes successful sales interactions, automatically adapts messaging and tactics, and provides real-time guidance to sales teams to maximize conversion rates.',
            'AI identifies patterns in successful sales conversations and suggests improvements, but implementation of learnings requires manual intervention.',
            'Sales approach is refined through basic analysis of historical performance data with minimal AI assistance.',
            'No systematic analysis of sales interactions or structured approach to improving conversion rates.'
          ]
        },
        { 
          headline: 'IT', 
          question: 'How does your company leverage AI for IT service desk and support?', 
          choices: [
            'AI handles most support requests autonomously, learns from past solutions, and proactively prevents common issues.',
            'AI assists with ticket routing and provides solution suggestions, but most resolutions require human intervention.',
            'Basic automated ticketing system with minimal AI capabilities.',
            'Manual help desk process with no automation.'
          ]
        },
        { 
          headline: 'R&D', 
          question: 'How does AI assist in your product development process?', 
          choices: [
            'AI generates multiple product design variations and automates testing, significantly speeding up prototyping and innovation.',
            'AI helps in some design and testing phases but doesn\'t fully automate the process.',
            'Product development is mostly manual with minimal digital assistance.',
            'No AI or digital tools are used in the product development process.'
          ]
        },
        { 
          headline: 'Customer Service', 
          question: 'How is AI improving customer support?', 
          choices: [
            'AI-powered chatbots and voice agents handle the majority of customer inquiries, with real-time sentiment analysis for escalations.',
            'AI chatbots assist with routine inquiries, but complex issues still require human intervention.',
            'Customer service is handled by human agents, with minimal AI support for ticketing systems.',
            'All customer support is handled manually, with no automation.'
          ]
        },
        { 
          headline: 'Legal', 
          question: 'How does AI support contract management?', 
          choices: [
            'AI automatically generates, reviews, and manages contracts, with real-time updates to ensure compliance.',
            'AI assists in contract generation and review but doesn\'t fully automate the process.',
            'Legal contracts are reviewed manually, with basic document management tools in place.',
            'Contract management is entirely manual, with no digital tools used.'
          ]
        },
        { 
          headline: 'Procurement', 
          question: 'How does AI assist in supplier management and procurement processes?', 
          choices: [
            'AI predicts supplier risks, automates purchase orders, and forecasts price trends, optimizing the entire procurement process.',
            'AI helps with some supplier analysis and purchase order automation, but manual intervention is needed for critical tasks.',
            'Procurement is managed with traditional software tools but with minimal AI involvement.',
            'Procurement is handled manually, with no digital or AI tools in place.'
          ]
        },
        { 
          headline: 'Facilities/Administration', 
          question: 'How does your company use AI to optimize workplace experience and productivity?', 
          choices: [
            'AI personalizes workspace environments, automates administrative tasks like scheduling, and dynamically adjusts resources based on employee needs and usage patterns.',
            'AI helps track workspace utilization and automates some administrative processes, but requires manual oversight.',
            'Basic workplace management tools with minimal automation of administrative tasks.',
            'Manual workplace management with no digital optimization.'
          ]
        },
        { 
          headline: 'Corporate Strategy', 
          question: 'How does AI support long-term strategic planning?', 
          choices: [
            'AI performs real-time SWOT analysis and suggests new opportunities based on market trends.',
            'AI assists with forecasting and competitor analysis, but real-time planning is limited.',
            'Strategy is based on historical data and manual analysis, with little AI assistance.',
            'Strategic planning is purely manual and reactive.'
          ]
        },
        { 
          headline: 'Compliance and Risk Management', 
          question: 'How does AI assist in monitoring compliance and risks?', 
          choices: [
            'AI continuously monitors regulatory changes and internal compliance, alerting teams in real-time to potential risks.',
            'AI helps track compliance but doesn\'t provide real-time updates across all jurisdictions.',
            'Compliance is managed with basic software tools, but AI is not integrated.',
            'Compliance is tracked manually, with no digital tools in place.'
          ]
        },
        { 
          headline: 'Product Management', 
          question: 'How does AI influence product roadmapping?', 
          choices: [
            'AI analyzes customer feedback, market trends, and competitor products to automatically prioritize features and suggest new developments.',
            'AI assists in analyzing feedback and trends, but product roadmapping is still done manually.',
            'Product roadmapping is based on manual customer feedback and historical data.',
            'Product decisions are made based purely on intuition, with no data or AI involvement.'
          ]
        },
        { headline: 'Results', question: 'Assessment Complete', choices: [] }
      ],
      isSubmitting: false,
      reportUrl: null,
      isMobile: false,
    }
  },
  computed: {
    ...mapGetters(['totalScore', 'allAnswers', 'userChoices', 'assessmentId']),
    progress() {
      return (this.currentCardIndex / (this.cards.length - 1)) * 100
    },
    currentCard() {
      return this.cards[this.currentCardIndex]
    },
    scorePercentage() {
      return Math.round((this.totalScore / 45) * 100);
    },
    grade() {
      const score = this.scorePercentage;
      if (score >= 97) return 'A+';
      if (score >= 93) return 'A';
      if (score >= 90) return 'A-';
      if (score >= 87) return 'B+';
      if (score >= 83) return 'B';
      if (score >= 80) return 'B-';
      if (score >= 77) return 'C+';
      if (score >= 73) return 'C';
      if (score >= 70) return 'C-';
      if (score >= 67) return 'D+';
      if (score >= 63) return 'D';
      if (score >= 60) return 'D-';
      return 'F';
    },
    gradeClass() {
      return {
        'grade-a': this.grade.startsWith('A'),
        'grade-b': this.grade.startsWith('B'),
        'grade-c': this.grade.startsWith('C'),
        'grade-d': this.grade.startsWith('D'),
        'grade-f': this.grade === 'F'
      };
    },
    resultMessage() {
      if (this.totalScore >= 40) {
        return {
          title: 'AI Leader',
          description: 'Your company is a leader in leveraging AI, demonstrating advanced capabilities across multiple areas.'
        }
      } else if (this.totalScore >= 30) {
        return {
          title: 'Significant AI Adoption',
          description: 'Your company is making significant use of AI but has room for improvement in some areas.'
        }
      } else if (this.totalScore >= 20) {
        return {
          title: 'Minimal AI Usage',
          description: 'Your company is using AI minimally, relying more on traditional methods. There\'s significant potential for growth.'
        }
      } else {
        return {
          title: 'AI Adoption Laggard',
          description: 'Your company is behind in AI adoption, missing out on significant efficiencies. Immediate action is recommended.'
        }
      }
    },
    recommendations() {
      // Generate recommendations based on answers
      // This is a simplified example; you may want to create more specific recommendations
      const recommendations = []
      this.allAnswers.forEach((answer, index) => {
        if (answer < 2) {
          recommendations.push(`Consider improving AI adoption in ${this.cards[index + 1].headline}`)
        }
      })
      return recommendations
    },
    currentChoice: {
      get() {
        // Convert currentCardIndex to zero-based index and ensure it's a number
        const index = Number(this.currentCardIndex) - 1;
        const savedChoice = this.userChoices.get(index);
        // console.log('Getting choice for index:', index, 'Value:', savedChoice);
        // Only return the saved choice if it exists, otherwise null
        return savedChoice !== undefined ? savedChoice : null;
      },
      set(value) {
        const index = Number(this.currentCardIndex) - 1;
        // console.log('Setting choice for index:', index, 'Value:', value);
        if (value !== null) {  // Only set if a choice was actually made
          this.$store.commit('SET_USER_CHOICE', { 
            index, 
            choice: Number(value)
          });
        }
      }
    },
    isLoggedIn() {
      return !!localStorage.getItem('authToken');
    }
  },
  watch: {
    // Add a watcher for currentCardIndex
    currentCardIndex: {
      immediate: true,
      handler(newIndex) {
        // When the card changes, update the current choice from stored answers
        if (newIndex > 0) {
          const savedChoice = this.userChoices.get(newIndex - 1);
          if (savedChoice !== undefined) {
            // Force a reactivity update
            this.$nextTick(() => {
              this.currentChoice = savedChoice;
            });
          }
        }
      }
    }
  },
  methods: {
    ...mapActions(['addPoints', 'resetScore', 'addAnswer', 'resetAnswers', 'setAssessmentId']),
    validateEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (!this.email) {
        this.emailError = 'Email is required'
      } else if (!emailRegex.test(this.email)) {
        this.emailError = 'Please enter a valid email address'
      } else {
        this.emailError = ''
      }
    },
    async startAssessment() {
      this.firstNameError = ''
      this.emailError = ''

      if (!this.firstName) {
        this.firstNameError = 'First name is required'
      }

      this.validateEmail()

      if (this.firstName && this.email && !this.emailError) {
        try {
          const response = await sendUserInfo(this.firstName, this.email)
          
          this.setAssessmentId(response.document_id)
          // console.log("Assessment ID stored in Vuex:", this.assessmentId)

          if (response.message === "Existing assessment found") {
            // console.log("Existing assessment found. Current results:", response.current_results)
            
            // First reset the store to ensure clean state
            this.resetScore()
            this.resetAnswers()

            // Create a new Map and populate it
            const userChoices = new Map()
            Object.entries(response.current_results).forEach(([key, value]) => {
              if (value !== null) {  // Only add non-null values
                const index = parseInt(key)
                userChoices.set(index, value)
                
                // Add to score if not N/A (4)
                if (value !== 4) {
                  this.addPoints(3 - value)
                }
              }
            })

            // Update Vuex store with the Map
            this.$store.commit('SET_USER_CHOICES', Object.fromEntries([...userChoices]))

            // Find the last answered question
            const answeredQuestions = Array.from(userChoices.keys())
            const lastAnsweredIndex = Math.max(...answeredQuestions)
            
            // Set current index to next unanswered question
            this.currentCardIndex = lastAnsweredIndex + 2

            // console.log("Continuing from question:", this.currentCardIndex)
            // console.log("Populated user choices:", userChoices)
            // console.log("User choices entries:", [...userChoices.entries()])
            // console.log("Current choices in store:", this.userChoices)

            // Force a reactivity update
            this.$nextTick(() => {
              const savedChoice = userChoices.get(this.currentCardIndex - 1)
              if (savedChoice !== undefined) {
                this.currentChoice = savedChoice
              }
            })

          } else {
            // console.log("New assessment created.")
            this.resetScore()
            this.resetAnswers()
            this.currentCardIndex = 1
          }

        } catch (error) {
          console.error('Error starting assessment:', error)
        }
      }
    },
    previousCard() {
      if (this.currentCardIndex > 1) {
        this.currentCardIndex--;
        // console.log('Previous card - index:', this.currentCardIndex - 1);
        
        // Force a reactivity update
        this.$nextTick(() => {
          const index = this.currentCardIndex - 1;
          const savedChoice = this.userChoices.get(index);
          // console.log('Previous card - saved choice for index:', index, 'Value:', savedChoice);
        });
      }
    },
    async updateExistingAnswer() {
      try {
        // Remove the old answer's points from the total score
        const oldChoice = this.userChoices.get(this.currentCardIndex - 1);
        if (oldChoice !== null && oldChoice !== 4) {  // If there was a previous non-N/A choice
          const oldPoints = 3 - oldChoice;
          this.assessmentScore -= oldPoints;
        }

        // Update the choice in the store
        this.$store.commit('SET_USER_CHOICE', { 
          index: this.currentCardIndex - 1, 
          choice: this.currentChoice 
        });

        // Add the new points if it's not N/A
        if (this.currentChoice !== 4) {
          const points = 3 - this.currentChoice;
          this.addPoints(points);
        }
        
        // Update the assessment results in the database
        await updateAssessmentResults(
          this.assessmentId, 
          Object.fromEntries(this.userChoices)
        );
      } catch (error) {
        console.error('Error updating existing answer:', error);
      }
    },
    async nextCard() {
      if (this.currentChoice !== null) {
        try {
          // Show loading state while submitting final answer
          if (this.currentCardIndex === this.cards.length - 2) {
            this.isSubmitting = true;
          }

          // Handle the answer submission
          if (this.userChoices.has(this.currentCardIndex - 1)) {
            await this.updateExistingAnswer();
          } else {
            this.addAnswer({ 
              answer: this.currentChoice === 4 ? 'N/A' : (3 - this.currentChoice), 
              choice: this.currentChoice, 
              index: this.currentCardIndex - 1 
            });

            if (this.currentChoice !== 4) {
              const points = 3 - this.currentChoice;
              this.addPoints(points);
            }
            
            await updateAssessmentResults(this.assessmentId, Object.fromEntries(this.userChoices));
          }
          
          // If this is the final question, submit the assessment and move to results
          if (this.currentCardIndex === this.cards.length - 2) {
            // Fire and forget - don't await the response
            completeAssessment(
              this.email,
              this.assessmentId,
              Object.fromEntries(this.userChoices),
              this.firstName,
              this.isLoggedIn
            );
            
            // Immediately proceed to results page
            this.currentCardIndex++;
            this.currentChoice = null;
            this.isSubmitting = false;
          } else {
            // Normal progression for non-final questions
            this.currentCardIndex++;
            this.currentChoice = null;
          }
        } catch (error) {
          console.error('Error updating assessment results:', error);
          this.isSubmitting = false;
        }
      }
    },
    restartAssessment() {
      this.currentCardIndex = 0
      this.firstName = ''
      this.email = ''
      this.selectedChoice = null
      this.resetScore()
      this.resetAnswers()
      this.$store.commit('CLEAR_STORE')
    },
    skipQuestion() {
      // Use choice index 4 to represent Skip/N/A
      this.currentChoice = 4;
      this.nextCard();
    },
    handleSignUp() {
      this.$router.push('/login');
    },
    scheduleDiscoveryCall() {
      // If we're in the business dashboard, use component navigation
      if (this.$route.path === '/business-dashboard') {
        this.$emit('navigate', 'DiscoveryCallContent');
      } else {
        // If we're on the standalone assessment page, redirect to business dashboard
        this.$router.push('/business-dashboard');
        // Store the intended destination in localStorage to handle the navigation after redirect
        localStorage.setItem('intended_component', 'DiscoveryCallContent');
      }
    },
    downloadReport() {
      // Implement report download functionality
      // console.log('Downloading report...');
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.firstNameInput) {
        this.$refs.firstNameInput.focus();
      }
    });

    // Add mobile detection
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile);
  },
}
</script>

<style scoped>
.assessment-embed {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  height: 100%;
  /* margin-top: 50px; */
  display: flex;
  font-family: 'Poppins', sans-serif;
  background-color: transparent;
  overflow: hidden;
}

.content-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.card-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 40px;
}

.card {
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  background-color: var(--primary-background-cream);
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  padding: 40px; /* Increased padding */
  padding-right: 60px
}

.card-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

h2 {
  color: var(--accent-victory-green);
  margin-bottom: 25px; /* Increased margin */
  font-size: 26px;
  font-weight: 600;
}

.question {
  font-size: 16px;
  margin-bottom: 30px;
  color: #333;
  line-height: 1.5;
}

.choices {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 35px;
}

.choice {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 15px 20px;
  transition: all 0.3s ease;
  cursor: pointer;
  width: 100%;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.choice:hover {
  background-color: #e0e0e0;
}

.choice input[type="radio"] {
  display: none;
}

.choice-text {
  font-size: 14px;
  color: #333;
  padding-left: 50px;
  width: 100%;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.choice-text::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border: 2px solid var(--accent-victory-green);
  border-radius: 4px;
  background-color: white;
  transition: all 0.3s ease;
}

.choice input[type="radio"]:checked + .choice-text::before {
  background-color: var(--accent-victory-green);
}

.choice input[type="radio"]:checked + .choice-text::after {
  content: '✓';
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  font-size: 14px;
}

.selected {
  background-color: #e0e0e0;
}

.btn-primary, .btn-secondary {
  padding: 12px 24px; 
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.btn-primary {
  background-color: var(--accent-victory-green);
  color: white;
}

.btn-primary:hover {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.btn-secondary {
  background-color: #e0e0e0;
  color: #333;
}

.btn-secondary:hover {
  background-color: #d0d0d0;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.button-row {
  display: flex;
  justify-content: center;
  /* margin-top: 30px; */
}

.btn-primary.btn-wide {
  margin: 0 auto;
}

.form-group {
  margin-bottom: 25px; /* Increased margin */
}

.form-group label {
  display: block;
  margin-bottom: 10px; /* Increased margin */
  font-weight: 500;
  color: #333;
}

.form-group input {
  width: 100%;
  padding: 14px; /* Increased padding */
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.form-group input:focus {
  outline: none;
  border-color: var(--accent-victory-green);
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.progress-bar-container {
  width: 200px;
  height: 60%;
  margin-left: 60px;
  position: relative;
}

.progress-bar {
  height: 100%;
  width: 4px;
  background-color: #e0e0e0;
  border-radius: 2px;
  position: relative;
  margin-left: 20px;
}

.progress {
  width: 100%;
  background-color: var(--accent-victory-green);
  transition: height 0.3s ease;
  position: absolute;
  top: 0;
  border-radius: 2px;
}

.progress-node {
  position: absolute;
  left: -8px;
  width: 20px;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #fff;
  transition: background-color 0.3s ease;
}

.progress-node.active {
  background-color: var(--accent-victory-green);
}

.node-content {
  position: absolute;
  left: 28px;
  white-space: nowrap;
  font-size: 14px;
  color: #333;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.first-card h2 {
  font-size: 26px;
  margin-bottom: 15px;
}

.first-card p {
  font-size: 14px;
  margin-bottom: 15px;
}

.first-card ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 20px;
}

.first-card li {
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
  font-size: 14px;
  color: #333;
}

.first-card li::before {
  content: "✓";
  position: absolute;
  left: 0;
  color: var(--accent-victory-green);
  font-weight: bold;
}

.first-card .form-group label {
  font-size: 14px;
  margin-bottom: 5px;
}

.first-card .form-group input {
  font-size: 14px;
  padding: 10px;
}

.first-card .btn-primary {
  font-size: 16px;
  padding: 14px 28px;
  width: 100%;
  max-width: 300px;
  margin-top: 20px;
}

.results-card {
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.results-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.score-container, .grade-container {
  margin: 0 20px;
}

.score, .grade {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 5px;
}

.score-label, .grade-label {
  font-size: 14px;
  color: #666;
}

.result-message {
  margin-bottom: 20px;
}

.result-message h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.result-message p {
  font-size: 16px;
  line-height: 1.4;
}

.next-steps {
  text-align: left;
  margin-bottom: 20px;
}

.next-steps h3 {
  font-size: 20px;
  margin-bottom: 10px;
  color: var(--accent-victory-green);
}

.next-steps p {
  font-size: 14px;
  line-height: 1.4;
}

.action-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.btn-primary, .btn-secondary, .btn-schedule {
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  /* height: 48px; */
}

.btn-secondary {
  background-color: #e0e0e0;
  color: #333;
}

.btn-secondary:hover {
  background-color: #d0d0d0;
}

.btn-primary, .btn-schedule {
  background-color: var(--accent-victory-green);
  color: white;
}

.btn-primary:hover, .btn-schedule:hover {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

.error-message {
  color: #ff3b30;
  font-size: 14px;
  margin-top: 5px;
  font-weight: 500;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  width: 100%;
}

.navigation-controls {
  display: flex;
  gap: 16px;  /* Space between Back and Skip */
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.nav-btn {
  background: none;
  border: none;
  color: var(--accent-victory-green);
  cursor: pointer;
  font-size: 16px;
  padding: 8px 0;
  transition: color 0.3s ease;
}

.nav-btn:hover {
  color: var(--accent-sunny-yellow);
}

.loading-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-content {
  text-align: center;
  padding: 20px;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid var(--accent-victory-green);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto 20px;
}

.loading-content p {
  color: var(--accent-victory-green);
  font-size: 18px;
  margin: 0;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.btn-secondary.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.action-buttons a {
  text-decoration: none;
}

.completion-message {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.completion-message p {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  margin-bottom: 30px;
}

.next-steps {
  text-align: left;
  margin: 30px 0;
}

.next-steps h3 {
  color: var(--accent-victory-green);
  font-size: 20px;
  margin-bottom: 15px;
}

.next-steps ul {
  list-style-type: none;
  padding: 0;
}

.next-steps li {
  font-size: 16px;
  margin-bottom: 12px;
  padding-left: 24px;
  position: relative;
}

.next-steps li::before {
  content: "✓";
  position: absolute;
  left: 0;
  color: var(--accent-victory-green);
}

.action-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
}

.action-buttons a {
  min-width: 200px;
  text-align: center;
  text-decoration: none;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.cta-section {
  margin: 20px 0;
  text-align: center;
}

.cta-button {
  background-color: var(--accent-victory-green);
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  font-size: 1.1em;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cta-button:hover {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

.secondary-button {
  background-color: transparent;
  color: var(--accent-victory-green);
  border: 2px solid var(--accent-victory-green);
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 1em;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 15px;
}

.secondary-button:hover {
  background-color: var(--accent-victory-green);
  color: white;
}

@media (max-width: 768px) {
  .assessment-embed {
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  .content-wrapper {
    flex-direction: column;
    height: 85%;
    padding: 0;
    margin-top: 0;
  }

  .card-container {
    width: 100%;
    padding: 0;
    padding-bottom: 55px;
    margin-top: 0;
  }

  .card {
    width: 100%;
    max-width: none;
    padding: 15px;
    margin: 0;
    height: auto;
    min-height: auto;
    border-radius: 0;
    box-shadow: none;
    margin-top: 0;
  }

  .card-content {
    padding: 10px;
    padding-top: 0;
  }

  /* First card specific adjustments */
  .first-card {
    padding: 15px;
    padding-top: 5px;
  }

  .first-card h2 {
    font-size: 18px;
    margin-bottom: 10px;
    margin-top: 5px;
  }

  .first-card p {
    font-size: 13px;
    margin-bottom: 8px;
    line-height: 1.3;
  }

  .first-card ul {
    margin: 8px 0;
    padding-left: 15px;
  }

  .first-card li {
    font-size: 13px;
    margin-bottom: 6px;
    padding-left: 15px;
  }

  /* Form group adjustments */
  .form-group {
    margin-bottom: 12px;
    width: 95%;
    padding: 0 5px;
    margin-left: auto;
    margin-right: auto;
  }

  .form-group label {
    font-size: 13px;
    margin-bottom: 4px;
  }

  .form-group input {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    margin: 0;
  }

  /* Button adjustments */
  .button-row {
    margin-top: 15px;
    padding: 0 5px;
  }

  .btn-primary {
    width: 100%;
    padding: 12px;
    font-size: 14px;
    margin: 0;
  }

  /* Question card adjustments */
  .card-header {
    margin-bottom: 10px;
  }

  .question {
    font-size: 13px;
    margin: 8px 0;
    padding: 0 5px;
  }

  .choices {
    gap: 6px;
    margin: 10px 0;
    padding: 0 5px;
  }

  .choice {
    padding: 8px 10px;
  }

  .choice-text {
    font-size: 13px;
    padding-left: 25px;
  }

  /* Keep the mobile progress bar styles unchanged */
  .mobile-progress {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.95);
    padding: 8px 15px;
    z-index: 100;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  }

  /* Hide desktop progress bar */
  .progress-bar-container {
    display: none;
  }

  .mobile-progress-text {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 6px;
  }

  .mobile-progress-bar-container {
    width: 100%;
    height: 3px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    margin-bottom: 6px;
  }

  .mobile-progress-bar {
    height: 100%;
    background-color: var(--accent-victory-green);
    border-radius: 2px;
    transition: width 0.3s ease;
  }

  /* Adjust card container padding to account for smaller progress bar */
  .card-container {
    width: 100%;
    padding: 0;
    padding-bottom: 55px;
  }

  .choice-text {
    font-size: 13px;
    padding-left: 50px;
  }

  .choice-text::before {
    width: 18px;
    height: 18px;
    left: 20px;
  }

  /* Results card adjustments */
  .results-card {
    padding: 15px;
  }

  .results-card h2 {
    font-size: 20px;
    margin-bottom: 15px;
    line-height: 1.3;
  }

  .completion-message {
    padding: 10px 5px;
  }

  .completion-message p {
    font-size: 13px;
    line-height: 1.4;
    margin-bottom: 15px;
  }

  .next-steps {
    margin: 20px 0;
  }

  .next-steps h3 {
    font-size: 16px;
    margin-bottom: 12px;
    color: var(--accent-victory-green);
  }

  .next-steps ul {
    padding-left: 15px;
    margin: 0;
  }

  .next-steps li {
    font-size: 13px;
    margin-bottom: 8px;
    line-height: 1.4;
    padding-left: 20px;
  }

  .next-steps li::before {
    content: "✓";
    position: absolute;
    left: 0;
    color: var(--accent-victory-green);
    padding-right: 5px;
  }

  .action-buttons {
    flex-direction: column;
    gap: 12px;
    margin-top: 20px;
  }

  .action-buttons a,
  .action-buttons button {
    width: 100%;
    padding: 12px;
    font-size: 14px;
    text-align: center;
  }

  .btn-secondary {
    margin-top: 0;
  }
}
</style>








