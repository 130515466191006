<template>
  <section class="plan-two">
    <div class="plan-content">
      <div class="left-column">
        <img src="https://res.cloudinary.com/dhkjot9fq/image/upload/v1731684121/plan-image-two_shbafl.jpg" alt="Flexible Plan" class="plan-image">
      </div>
      <div class="right-column">
        <h2 class="headline">A Flexible Plan You Can Trust</h2>
        <p class="description">
          With this plan you'll have a clear understanding of what each project will do for your business and the specific reasons behind our advice.
        </p>
        <p class="sub-description">
          You can use this plan to:
        </p>
        <ul class="plan-list">
          <li>Implement it internally</li>
          <li>Hire a third party</li>
          <li>Or let us execute it for you</li>
        </ul>
        <p class="flexibility">
          We can work with you on a single project or dive into a full department or entire organization
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PlanTwo',
  methods: {
    setActive(active) {
      // You can add any specific behavior here if needed
    }
  }
}
</script>

<style scoped>
.plan-two {
  scroll-snap-align: start;
  height: 100vh;
  overflow: hidden;
}

.plan-content {
  display: flex;
  height: 100%;
}

.left-column {
  flex: 0 0 50%;
  overflow: hidden;
}

.right-column {
  flex: 0 0 50%;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--primary-background-cream);
}

.headline {
  font-size: 3.5rem;
  color: var(--accent-victory-green);
  margin-bottom: 2rem;
  font-weight: bold;
}

.description, .sub-description {
  font-size: 1.3rem;
  color: var(--main-text-color);
  margin-bottom: 2rem;
  line-height: 1.6;
}

.plan-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 2.5rem;
}

.plan-list li {
  font-size: 1.2rem;
  color: var(--main-text-color);
  margin-bottom: 1rem;
  padding-left: 2rem;
  position: relative;
  line-height: 1.5;
}

.plan-list li::before {
  content: '✓';
  color: var(--accent-victory-green);
  position: absolute;
  left: 0;
  top: 0;
  font-weight: bold;
  font-size: 1.4rem;
}

.flexibility {
  font-size: 1.2rem;
  color: var(--accent-victory-green);
  font-style: italic;
  font-weight: 500;
  line-height: 1.6;
  border-top: 2px solid var(--accent-sunny-yellow);
  padding-top: 1.5rem;
}

.plan-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .plan-two {
    min-height: 100vh;
    display: flex;
    align-items: flex-start;
    padding-top: 80px;
  }

  .plan-content {
    flex-direction: column;
    height: auto;
    padding: 0;
  }

  .left-column {
    flex: none;
    height: 15vh;
    order: 1;
  }

  .right-column {
    flex: none;
    padding: 0.8rem;
    order: 2;
    margin-bottom: 0;
  }

  .headline {
    font-size: 1.8rem;
    margin-bottom: 0.8rem;
    text-align: center;
  }

  .description, .sub-description {
    font-size: 0.85rem;
    text-align: center;
    padding: 0 0.5rem;
    margin-bottom: 0.8rem;
  }

  .plan-list {
    margin: 0.4rem 0;
    padding: 0 1.5rem;
  }

  .plan-list li {
    font-size: 0.85rem;
    margin-bottom: 0.4rem;
    padding-left: 1.5rem;
  }

  .plan-list li::before {
    font-size: 1rem;
  }

  .flexibility {
    font-size: 0.85rem;
    margin-top: 0.8rem;
    padding: 0.8rem 0.5rem 0;
  }
}
</style>
