<template>
  <div class="contact-content">
    <h1>Contact</h1>
    <p>This is a placeholder for the Contact content.</p>
  </div>
</template>

<script>
export default {
  name: 'ContactContent'
}
</script>

<style scoped>
.contact-content {
  padding: 20px;
}
</style>
