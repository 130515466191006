<template>
  <div class="leads-content">
    <h1>Leads</h1>
    <p>This is a placeholder for the Leads content.</p>
  </div>
</template>

<script>
export default {
  name: 'LeadsContent'
}
</script>

<style scoped>
.leads-content {
  padding: 20px;
}
</style>
