<template>
  <div class="forgot-password-form">
    <h1>Reset Password</h1>
    <form @submit.prevent="handleForgotPassword">
      <div class="form-group">
        <input 
          type="email" 
          id="email" 
          v-model="email" 
          placeholder="Email" 
          required
        >
      </div>
      <p v-if="message" :class="['message', messageType]">{{ message }}</p>
      <button 
        type="submit" 
        class="reset-button"
        :disabled="loading"
      >
        {{ loading ? 'Sending...' : 'Reset Password' }}
      </button>
    </form>
    <p class="login-link">
      Remember your password? <a href="#" @click.prevent="switchToLogin">Login</a>
    </p>
  </div>
</template>

<script>
import { forgotPassword } from '@/services/api';

export default {
  name: 'ForgotPasswordForm',
  data() {
    return {
      email: '',
      activeDot: 0,
      loading: false,
      message: '',
      messageType: 'success',
      // slides: [
      //   {
      //     image: require('@/assets/slide1.png'),
      //     title: 'AI Strategy Assessment',
      //     content: 'Start with our comprehensive AI readiness assessment to understand your business needs.'
      //   },
      //   {
      //     image: require('@/assets/slide2.png'),
      //     title: 'Custom AI Solutions',
      //     content: 'Get personalized AI strategies tailored to your business needs.'
      //   },
      //   {
      //     image: require('@/assets/slide3.png'),
      //     title: 'Expert Support',
      //     content: 'Our team of experts is here to guide you every step of the way.'
      //   }
      // ]
    }
  },
  created() {
    // Pre-fill email if it exists in localStorage
    const rememberedEmail = localStorage.getItem('rememberedEmail');
    if (rememberedEmail) {
      this.email = rememberedEmail;
    }
  },
  methods: {
    async handleForgotPassword() {
      this.loading = true;
      this.message = '';
      
      try {
        const response = await forgotPassword(this.email);
        this.message = response.message;
        this.messageType = 'success';
      } catch (error) {
        console.error('Password reset error:', error);
        this.message = error.message || 'Failed to process password reset request';
        this.messageType = 'error';
      } finally {
        this.loading = false;
      }
    },
    switchToLogin() {
      this.$emit('switch-component', 'LoginForm')
    },
    setActiveDot(index) {
      this.activeDot = index
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$el.querySelector('#email').focus()
    })
  }
}
</script>

<style scoped>
.forgot-password-form {
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.forgot-password-form h1 {
  font-size: 24px;
  margin-bottom: 30px;
  color: var(--accent-victory-green);
}

.form-group {
  margin-bottom: 20px;
  position: relative;
}

input[type="email"] {
  width: 95%;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 14px;
}

.reset-button {
  width: 100%;
  padding: 12px;
  background-color: var(--accent-victory-green);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.5s ease;
}

.reset-button:hover:not(:disabled) {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
  transform: scale(1.05);
}

.reset-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none;
}

.login-link {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}

.login-link a {
  color: var(--accent-victory-green);
  text-decoration: none;
}

.login-link a:hover {
  text-decoration: underline;
}

.message {
  text-align: center;
  margin: 10px 0;
  padding: 10px;
  border-radius: 4px;
}

.message.success {
  color: #00C851;
  background-color: #e8f5e9;
}

.message.error {
  color: #ff4444;
  background-color: #ffebee;
}

@media (max-width: 768px) {
  .forgot-password-form {
    padding: 20px;
  }

  .forgot-password-form h1 {
    font-size: 20px;
    margin-bottom: 20px;
    text-align: center;
  }

  .form-group {
    margin-bottom: 15px;
  }

  input[type="email"] {
    padding: 10px;
    font-size: 16px;
    width: 95%;
  }

  .reset-button {
    padding: 12px;
    font-size: 16px;
  }

  .login-link {
    font-size: 13px;
    margin-top: 15px;
  }

  .message {
    font-size: 13px;
    padding: 8px;
    margin: 8px 0;
  }
}
</style>
