<template>
  <div class="terms-of-service">
    <h1>Terms of Service</h1>
    <p>
      Welcome to Victory AI. By accessing our website, you agree to these Terms of Service.
    </p>
    <h2>Use of Our Website</h2>
    <p>
      You may use our website for lawful purposes and in accordance with these Terms of Service. You agree not to use our website:
    </p>
    <ul>
      <li>In any way that violates any applicable federal, state, local, or international law or regulation</li>
      <li>To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail," "chain letter," "spam," or any other similar solicitation</li>
      <li>To impersonate or attempt to impersonate Victory AI, a Victory AI employee, another user, or any other person or entity</li>
    </ul>
    <h2>Intellectual Property</h2>
    <p>
      The content on our website, including but not limited to text, graphics, logos, and images, is the property of Victory AI and protected by copyright and other intellectual property laws.
    </p>
    <h2>Disclaimer of Warranties</h2>
    <p>
      Our website is provided on an "as is" and "as available" basis, without any warranties of any kind, either express or implied.
    </p>
    <h2>Limitation of Liability</h2>
    <p>
      Victory AI shall not be liable for any indirect, incidental, special, consequential or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the website.
    </p>
    <h2>Changes to These Terms</h2>
    <p>
      We may revise these Terms of Service from time to time. The most current version will always be on this page.
    </p>
    <h2>Contact Us</h2>
    <p>
      If you have any questions about these Terms of Service, please contact us at hello@victoryai.io.
    </p>
  </div>
</template>

<script>
export default {
  name: 'TermsOfServiceView'
}
</script>

<style scoped>
.terms-of-service {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h1, h2 {
  color: var(--accent-victory-green);
}

p, ul {
  margin-bottom: 20px;
}
</style>
