<template>
  <div class="login-form">
    <h1>Sign Up</h1>
    <form @submit.prevent="handleSubmit">
      <div class="form-group">
        <input type="text" id="name" v-model="name" placeholder="Name" required>
      </div>
      <div class="form-group">
        <input type="text" id="companyName" v-model="companyName" placeholder="Company Name" required>
      </div>
      <div class="form-group">
        <input type="email" id="email" v-model="email" placeholder="Email" required>
      </div>
      <div class="form-group">
        <input 
          :type="showPassword ? 'text' : 'password'" 
          id="password" 
          v-model="password" 
          placeholder="Password" 
          required
        >
        <span class="password-toggle" @click="togglePassword">
          {{ showPassword ? '👁️' : '👁️‍🗨️' }}
        </span>
      </div>
      <div class="form-group">
        <input 
          :type="showPassword ? 'text' : 'password'" 
          id="confirmPassword" 
          v-model="confirmPassword" 
          placeholder="Confirm Password" 
          required
        >
      </div>
      <p v-if="passwordError" class="error-message">{{ passwordError }}</p>
      <p v-if="error" class="error-message">{{ error }}</p>
      <button type="submit" class="login-button" :disabled="loading || !passwordsMatch">
        {{ loading ? 'Creating Account...' : 'Sign Up' }}
      </button>
    </form>
    <p class="signup-link">Already have an account? <a href="#" @click.prevent="switchToLogin">Login</a></p>
  </div>
</template>

<script>
import { signupUser } from '@/services/api';

export default {
  name: 'SignUpForm',
  data() {
    return {
      name: '',
      companyName: '',
      email: '',
      password: '',
      confirmPassword: '',
      showPassword: false,
      activeDot: 0,
      error: null,
      passwordError: null,
      loading: false,
      // slides: [
      //   {
      //     image: require('@/assets/slide1.png'),
      //     title: 'AI Strategy Assessment',
      //     content: 'Start with our comprehensive AI readiness assessment to understand your business needs.'
      //   },
      //   {
      //     image: require('@/assets/slide2.png'),
      //     title: 'Custom AI Solutions',
      //     content: 'Get personalized AI strategies tailored to your business needs.'
      //   },
      //   {
      //     image: require('@/assets/slide3.png'),
      //     title: 'Expert Support',
      //     content: 'Our team of experts is here to guide you every step of the way.'
      //   }
      // ]
    }
  },
  created() {
    // Add this to load remembered email on component creation
    const rememberedEmail = localStorage.getItem('rememberedEmail');
    if (rememberedEmail) {
      this.email = rememberedEmail;
    }
  },
  computed: {
    passwordsMatch() {
      return this.password === this.confirmPassword;
    }
  },
  watch: {
    password() {
      this.validatePasswords();
    },
    confirmPassword() {
      this.validatePasswords();
    }
  },
  methods: {
    validatePasswords() {
      if (this.confirmPassword && !this.passwordsMatch) {
        this.passwordError = 'Passwords do not match';
      } else {
        this.passwordError = null;
      }
    },
    async handleSubmit() {
      if (this.loading) return;

      this.error = null;
      this.loading = true;
      
      try {
        const response = await signupUser(
          this.name,
          this.email,
          this.password,
          this.companyName
        );
        
        // console.log('Signup successful:', response);
        
        // Store assessment data and ID in localStorage
        if (response.assessment) {
          localStorage.setItem('assessmentData', JSON.stringify({ assessment: response.assessment }));
          localStorage.setItem('assessment_id', response.assessment.id);
        }
        
        // Redirect to business dashboard on success
        this.$router.push('/business-dashboard');
        
      } catch (error) {
        console.error('Signup error:', error);
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
    togglePassword() {
      this.showPassword = !this.showPassword
    },
    switchToLogin() {
      this.$emit('switch-component', 'LoginForm')
    },
    setActiveDot(index) {
      this.activeDot = index
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$el.querySelector('#name').focus()
    })
  }
}
</script>

<style scoped>
.login-form {
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-form h1 {
  font-size: 24px;
  margin-bottom: 30px;
  color: var(--accent-victory-green);
}

.form-group {
  margin-bottom: 20px;
  position: relative;
}

input[type="text"], input[type="email"], input[type="password"] {
  width: 100%;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 14px;
}

.password-toggle {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 0.3s ease;
}

.password-toggle:hover {
  opacity: 0.7;
  transform: translateY(-50%) scale(1.1);
}

.login-button {
  width: 100%;
  padding: 12px;
  background-color: var(--accent-victory-green);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.5s ease;
}

.login-button:hover {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
  transform: scale(1.05);
}

.signup-link {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}

.signup-link a {
  color: var(--accent-victory-green);
  text-decoration: none;
}

.signup-link a:hover {
  text-decoration: underline;
}

.login-button,
.signup-link a,
.password-toggle,
.dot {
  transition: all 0.3s ease;
}

@media (max-width: 768px) {
  .login-card {
    flex-direction: column;
  }
}

/* Add to existing styles */
.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.login-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none;
}
</style>
